<template>
    <span v-if="editable">
        <b-card class="shadow mb-5" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right">
                    <b-button variant="outline-secondary" size="sm" @click="cancelEditing()">
                        <font-awesome-icon icon="times" />&nbsp;abbrechen
                    </b-button>
                    <b-button variant="outline-primary" size="sm" @click="saveData()">
                        <font-awesome-icon icon="save" />&nbsp;speichern
                    </b-button>
                </b-button-group>
            </h5>

            <Loading v-if="!loaded" />

            <b-form-checkbox-group
                stacked
                id="projectservices"
                v-model="selected_services"
                :options="possible_services"
                name="projectservices"
            ></b-form-checkbox-group>
        </b-card>
    </span>
    <span v-else>
        <b-card class="card-transparent mb-5 hover-field" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right show-on-hover">
                    <b-button
                        variant="link"
                        size="sm"
                        class="btn-sm-without-padding"
                        @click="startEditing()"
                    >
                        <font-awesome-icon icon="edit" />&nbsp;bearbeiten
                    </b-button>
                </b-button-group>
            </h5>

            <div v-if="projectinfo.services.length > 0">
                <div v-for="service in projectinfo.services" v-bind:key="service.id" class="mb-3">
                    {{ service.name }}
                    <b-badge v-if="!service.active || !service.pivot.active">inaktiv</b-badge>
                    <span class="small text-muted">
                        {{ service.description }}
                    </span>
                </div>
            </div>
            <span v-else class="text-muted small">Keine Leistungen hinterlegt.</span>
        </b-card>
    </span>
</template>
<script>
import { projectService } from "../../_services";

export default {
    props: {
        projectinfo: Object
    },
    components: {},
    data: function() {
        return {
            loaded: true,
            editable: false,
            title: "Leistungen",
            icon: "handshake",
            selected_services: []
        };
    },
    methods: {
        cancelEditing() {
            this.editable = false;
            this.setSelectedServicesVar();
        },
        saveData() {
            var data = {
                services: this.selected_services
            };
            projectService
                .updateOne(this.projectinfo.id, data)
                .then(response => {
                    this.editable = false;
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");
                    this.$root.toast("Das Projekt wurde erfolgreich geändert.", "success");
                })
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht geändert werden.", "danger");
                });
        },
        startEditing() {
            this.editable = true;
            this.setSelectedServicesVar();
        },
        setSelectedServicesVar() {
            var ret = [];
            for (var k = 0; k < this.projectinfo.services.length; k++) {
                ret.push(this.projectinfo.services[k].pivot.service_id);
            }
            this.selected_services = ret;
        }
    },
    created() {
        this.setSelectedServicesVar();
    },
    computed: {
        possible_services() {
            var ret = [];
            var services = this.$store.state.projects.services.items;
            for (var k = 0; k < services.length; k++) {
                ret.push({
                    value: services[k].id,
                    text: services[k].name + ": " + services[k].description
                });
            }
            return ret;
        }
    }
};
</script>
