<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <EmployeeSelector v-model="employee_id"></EmployeeSelector>
            <h1>Meine Übersicht: <EmployeeName :id="employee_id" :dot="false" /></h1>
        </template>

        <template v-slot:page-sub-menu>
            <EmployeeDetailSubMenu active="employee-vacationdays" :employee_id="employee_id" />
        </template>

        <div class="card shadow mb-4">
            <div class="card-body">
                <YearSelector v-model="year" />
            </div>
            <Loading v-if="loading" />
            <VacationdaysTable
                v-else
                :year="year"
                :entries="employeevacationdays.vacationdays"
                :quotas="employeevacationdays.vacationquotas"
                :employee_id="employee_id"
                v-on:vacationquota-changed="setNewVacationquota($event)"
            />
        </div>
    </base-layout>
</template>
<script>
import EmployeeDetailSubMenu from "../../components/SubMenus/EmployeeDetailSubMenu.vue";
import EmployeeSelector from "../../components/EmployeeSelector.vue";
import YearSelector from "../../components/YearSelector.vue";
import { employeeService } from "../../_services";
import VacationdaysTable from "../../components/VacationdaysTable.vue";

export default {
    data() {
        return {
            employee_id: parseInt(this.$route.params.id),
            loading: true,
            year: parseInt(window.moment().format("YYYY")),
            employeevacationdays: {}
        };
    },
    components: {
        EmployeeDetailSubMenu,
        EmployeeSelector,
        YearSelector,
        VacationdaysTable
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            employeeService.getVacationdaysPerEmployee(this.employee_id).then(response => {
                this.loading = false;
                this.employeevacationdays = response.data;
            });
        },
        setNewVacationquota(event) {
            this.fetchData();
            // for (var i = 0; i < this.employeevacationdays.vacationquotas.length; i++) {
            //     if (
            //         this.employeevacationdays.vacationquotas[i].year == event.data.year &&
            //         this.employeevacationdays.vacationquotas[i].employee_id == this.employee_id
            //     ) {
            //         this.employeevacationdays.vacationquotas[i] = event.data;
            //     }
            // }
        }
    },
    watch: {
        employee_id: function(newVal, oldVal) {
            this.$router.push({
                name: "employee.vacationdays",
                params: { id: newVal }
            });
            this.fetchData();
        }
    }
};
</script>
