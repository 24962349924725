<template>
    <base-layout>
        <template v-slot:header-title>
            <h1>
                <span class="font-weight-bold"> Neues Projekt anlegen</span>
            </h1>
        </template>

        <template v-slot:page-sub-menu> </template>

        <b-row>
            <b-col cols="6">
                <b-form @submit="onSubmit">
                    <b-form-group id="input-group-0" label="Projektnummer" label-for="input-0">
                        <b-form-input
                            id="input-0"
                            v-model="form.number"
                            type="text"
                            required
                            size="lg"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-1" label="Projektbezeichnung" label-for="input-1">
                        <b-form-input
                            id="input-1"
                            v-model="form.name"
                            type="text"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-button type="submit" variant="primary">neues Projekt anlegen</b-button>
                </b-form>
            </b-col>
            <b-col cols="6">
                <b-alert show variant="info">
                    Die zuletzt vergebene Projektnummer ist
                    <br />
                    <strong>{{ highestCurrentProjectNumber.number }} </strong>
                    ({{ highestCurrentProjectNumber.name }}) .
                </b-alert>
                <!-- <b-alert show variant="info">
                    <h4>Schema für Projektbezeichnungen</h4>
                    <ul>
                        <li>EFH "Familienname"</li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </b-alert> -->
            </b-col>
        </b-row>
    </base-layout>
</template>
<script>
import { projectService } from "../_services";
import { sortBy, startsWith } from "lodash";

export default {
    components: {},
    data() {
        return {
            form: {
                number: "",
                name: ""
            },

            loading: false
        };
    },
    created() {
        let num = parseInt(this.highestCurrentProjectNumber.number.substring(1, 4)) + 1;
        this.form.number = "G" + num;
    },
    computed: {
        highestCurrentProjectNumber() {
            var filtered = this.$store.state.projects.projects.items.filter(project => {
                return startsWith(project.number, "G");
            });
            var sorted = sortBy(filtered, ["number"]);
            return sorted[sorted.length - 1];
        }
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();
            this.loading = true;
            projectService
                .createOne(this.form)
                .then(response => {
                    this.loading = false;
                    this.$store.commit("projects/integrateOne", {
                        id: 0,
                        data: response.data
                    });
                    this.$root.toast("Das Projekt wurde erfolgreich erstellt. ", "success");
                    this.$router.push({
                        name: "project.detail",
                        params: { id: response.data.id }
                    });
                })
                .catch(error => {
                    this.loading = false;
                    this.$root.toast(
                        "Das Projekt konnte nicht erstellt werden. Möglicherweise wurde die Nummer oder der Name bereits vergeben.",
                        "danger"
                    );
                });
        }
    }
};
</script>
