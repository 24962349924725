<template>
    <table class="table table-without-top-line mb-0">
        <tbody>
            <tr v-if="vacationdays.length > 0">
                <td>
                    <font-awesome-icon icon="suitcase-rolling" fixed-width />&nbsp;Urlaubstage ({{
                        vacationdaysSum | DayFractions
                    }})
                </td>
                <td class="text-right">{{ vacationdaysHourlySum | NumberTwoDigits }}</td>
            </tr>
            <tr v-if="publicholidays.length > 0">
                <td>
                    <font-awesome-icon icon="glass-cheers" fixed-width />&nbsp;Feiertage ({{
                        publicholidaysSum | DayFractions
                    }})
                </td>
                <td class="text-right">{{ publicholidaysHourlySum | NumberTwoDigits }}</td>
            </tr>
            <tr v-if="sickdays.length > 0">
                <td>
                    <font-awesome-icon icon="bed" fixed-width />&nbsp;Krankheitstage ({{
                        sickdaysSum | DayFractions
                    }}
                    )
                </td>
                <td class="text-right">{{ sickdaysHourlySum | NumberTwoDigits }}</td>
            </tr>
            <tr v-for="project in projectsums" v-bind:key="project.id">
                <td>
                    <ProjectName :id="project.id" />
                </td>
                <td class="text-right">{{ project.hourlySum | NumberTwoDigits }}</td>
            </tr>
        </tbody>
        <tfoot style="display: table-row-group">
            <tr>
                <td>insgesamt angefallene Stundenzahl</td>
                <td class="text-right">{{ hourlySum | NumberTwoDigits }}</td>
            </tr>
            <tr>
                <td>Soll-Wert für {{ period | PeriodSmall }}</td>
                <td class="text-right">{{ sollComputed | NumberTwoDigits }}</td>
            </tr>
            <tr v-if="sollCustom != 0 || customSollEditable">
                <td>
                    Stunden-Abzug in {{ period | PeriodSmall }}
                    <div class="small text-regular" v-if="!customSollEditable">
                        {{ description }}
                    </div>
                    <span v-if="customSollEditable">
                        <b-form-input
                            type="text"
                            class="mt-3"
                            v-model="customSollDescriptionUnsaved"
                            placeholder="Beschreibung eingeben"
                        />
                    </span>
                </td>
                <td class="text-right">
                    <button
                        type="button"
                        class="btn btn-link btn-sm d-print-none"
                        :id="'soll_custom_' + period"
                        v-if="userHasManagerRoleForEmployee && !customSollEditable"
                        @click="toCustomSollEditMode()"
                    >
                        <font-awesome-icon icon="edit" /> bearbeiten
                    </button>
                    <span v-if="!customSollEditable">
                        {{ sollCustom | NumberTwoDigits }}
                    </span>
                    <span v-if="customSollEditable">
                        &nbsp;
                        <button
                            type="button"
                            class="btn btn-primary-outline btn-sm d-print-none"
                            :id="'soll_custom_' + period"
                            @click="abortCustomSollEditMode()"
                        >
                            abbrechen
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-sm d-print-none"
                            :id="'soll_custom_' + period"
                            @click="saveCustomSoll()"
                        >
                            speichern
                        </button>
                        <b-form-input
                            type="number"
                            class="mt-1 text-right"
                            v-model="customSollUnsaved"
                        />
                    </span>
                </td>
            </tr>
            <tr
                v-if="sollCustom == 0 && userHasManagerRoleForEmployee && !customSollEditable"
                class="d-print-none"
            >
                <td>Stunden-Abzug in {{ period | PeriodSmall }}</td>
                <td class="text-right">
                    <button
                        type="button"
                        class="btn btn-link btn-sm px-0"
                        :id="'soll_custom_' + period"
                        v-if="userHasManagerRoleForEmployee"
                        @click="toCustomSollEditMode()"
                    >
                        <font-awesome-icon icon="plus" /> eintragen
                    </button>
                </td>
            </tr>
            <tr>
                <td>Stunden-Differenz</td>
                <td class="text-right">{{ (hourlySum - sollvalue) | NumberTwoDigits }}</td>
            </tr>
            <tr v-if="period < CurrentPeriod">
                <td>
                    Gesamt-Überstunden <small>(zum {{ lastDayOfMonth | DateFullShort }})</small>
                </td>
                <td class="text-right">{{ overtimevalue | NumberTwoDigits }}</td>
            </tr>
        </tfoot>
    </table>
</template>
<script>
import { employeeService } from "../_services";

export default {
    props: {
        timesheetdata: Object,
        period: String
    },
    data() {
        return {
            CurrentPeriod: window.moment().format("YYYY-MM"),
            lastDayOfMonth: window.moment(this.period + "-01").endOf("month"),
            customSollEditable: false,
            customSollUnsaved: 99,
            customSollDescriptionUnsaved: ""
        };
    },
    computed: {
        overtimevalue() {
            return this.timesheetdata.overview[0].overtime;
        },
        sollvalue() {
            return this.timesheetdata.overview[0].soll;
        },
        sollComputed() {
            return this.timesheetdata.overview[0].soll_computed;
        },
        sollCustom() {
            return this.timesheetdata.overview[0].soll_custom;
        },
        description() {
            return this.timesheetdata.overview[0].description;
        },
        hourlySum() {
            var ret =
                this.projectsHourlySum +
                this.vacationdaysHourlySum +
                this.sickdaysHourlySum +
                this.publicholidaysHourlySum;
            return ret;
        },
        projectsums() {
            var ret = [];
            for (var i = 0; i < this.records.length; i++) {
                var r = this.records[i];
                var pid = r.project_id;
                var d = this.$root.getDuration(this.records[i].starttime, this.records[i].endtime);

                if (ret[pid] === undefined) {
                    ret[pid] = { id: pid, hourlySum: d };
                } else {
                    ret[pid].hourlySum += d;
                }
            }
            var ret2 = [];
            for (var j = 0; j < ret.length; j++) {
                if (ret[j] !== undefined) {
                    ret2.push(ret[j]);
                }
            }

            return ret2;
        },
        userHasManagerRoleForEmployee() {
            return this.$root.userHasManagerRoleForEmployee(this.timesheetdata.employee.id);
        },
        vacationdays() {
            return this.timesheetdata.vacationdays;
        },
        publicholidays() {
            return this.timesheetdata.publicholidays;
        },
        sickdays() {
            return this.timesheetdata.sickdays;
        },
        records() {
            return this.timesheetdata.records;
        },
        vacationdaysSum() {
            var ret = 0;
            for (var i = 0; i < this.vacationdays.length; i++) {
                ret += this.vacationdays[i].factor;
            }
            return ret;
        },
        publicholidaysSum() {
            var ret = 0;
            for (var i = 0; i < this.publicholidays.length; i++) {
                ret += this.publicholidays[i].fraction;
            }
            return ret;
        },
        sickdaysSum() {
            var ret = 0;
            for (var i = 0; i < this.sickdays.length; i++) {
                ret += this.sickdays[i].factor;
            }
            return ret;
        },
        vacationdaysHourlySum() {
            var ret = 0;
            for (var i = 0; i < this.vacationdays.length; i++) {
                ret += this.vacationdays[i].recordvalue_computed;
            }
            return ret;
        },
        publicholidaysHourlySum() {
            var ret = 0;
            for (var i = 0; i < this.publicholidays.length; i++) {
                ret += this.publicholidays[i].recordvalue_computed;
            }
            return ret;
        },
        sickdaysHourlySum() {
            var ret = 0;
            for (var i = 0; i < this.sickdays.length; i++) {
                ret += this.sickdays[i].recordvalue_computed;
            }
            return ret;
        },
        projectsHourlySum() {
            var ret = 0;
            for (var i = 0; i < this.records.length; i++) {
                var d = this.$root.getDuration(this.records[i].starttime, this.records[i].endtime);
                ret += d;
            }
            return ret;
        }
    },
    methods: {
        toCustomSollEditMode() {
            this.customSollEditable = true;
            this.customSollUnsaved = this.sollCustom;
            this.customSollDescriptionUnsaved = this.description;
        },
        abortCustomSollEditMode() {
            this.customSollEditable = false;
        },
        saveCustomSoll() {
            let value = this.customSollUnsaved == "" ? 0 : this.customSollUnsaved;
            let description = this.customSollUnsaved == 0 ? "" : this.customSollDescriptionUnsaved;
            employeeService
                .updateCustomQuota(this.timesheetdata.employee.id, {
                    period: this.period,
                    value: value,
                    description: description
                })
                .then(response => {
                    this.$emit("customquota-changed", response);
                    this.$root.toast("Der Stunden-Abzug wurde erfolgreich gespeichert.", "success");
                    this.customSollEditable = false;
                })
                .catch(err => {
                    this.$root.toast(
                        "Der Stunden-Abzug konnte nicht gespeichert werden. Bitte überprüfen Sie Ihre Eingaben.",
                        "danger"
                    );
                });
        }
    }
};
</script>
