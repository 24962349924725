<template>
    <div>
        <div v-if="projects.loading">
            <Loading />
        </div>
        <div v-if="projects.items && value > 0">
            <ProjectName :id="value" :showname="showname" />
            <b-button v-b-modal="'modal-' + this.id" variant="link" size="sm">
                <font-awesome-icon icon="edit" />&nbsp;ändern
            </b-button>
        </div>
        <div v-else-if="projects.items && value == 0">
            <b-button v-b-modal="'modal-' + this.id" variant="secondary" size="sm">
                <font-awesome-icon icon="building" />&nbsp;Projekt wählen
            </b-button>
        </div>

        <b-modal
            :id="'modal-' + this.id"
            size="lg"
            title="Projekt wählen"
            @shown="focusSearchTerm"
            hide-footer
        >
            <div class="mb-5">
                <b-form-input
                    ref="projectlistsearchterm"
                    v-model="searchterm"
                    placeholder="nach Projekt suchen"
                ></b-form-input>
                <div class="float-right mt-2" v-if="searchterm != ''">
                    <button class="btn btn-sm btn-link" @click="searchterm = ''">
                        <font-awesome-icon icon="times" />&nbsp;Filter entfernen
                    </button>
                </div>
            </div>

            <div class="mb-3">
                <div
                    v-if="
                        filteredProjectlist_favorite.length + filteredProjectlist_normal.length == 0
                    "
                    class="text-center pt-5 pb-5"
                >
                    <font-awesome-icon icon="exclamation-circle" />&nbsp;Es wurde nichts gefunden.
                </div>
                <b-row v-else>
                    <b-col>
                        <h3>
                            <font-awesome-icon
                                class="text-primary"
                                :icon="['fas', 'star']"
                            />&nbsp;Favoriten
                        </h3>
                        <div
                            class="form-check"
                            v-for="project in filteredProjectlist_favorite"
                            v-bind:key="project.id"
                            v-on:change="onChangeProject"
                        >
                            <input
                                class="form-check-input"
                                type="radio"
                                name="'favp_' + project.id"
                                v-model="selectedProject"
                                :value="project.id"
                                :id="'favp_' + project.id"
                            />
                            <label class="form-check-label" :for="'favp_' + project.id">
                                <strong>{{ project.number }}</strong>
                                {{ project.name }}
                            </label>
                        </div>
                    </b-col>

                    <b-col>
                        <h3>andere Projekte</h3>
                        <div
                            class="form-check"
                            v-for="project in filteredProjectlist_normal"
                            v-bind:key="project.id"
                            v-on:change="onChangeProject"
                        >
                            <input
                                class="form-check-input"
                                type="radio"
                                name="'p_' + project.id"
                                v-model="selectedProject"
                                :value="project.id"
                                :id="'p_' + project.id"
                            />
                            <label class="form-check-label" :for="'p_' + project.id">
                                <strong>{{ project.number }}</strong>
                                {{ project.name }}
                            </label>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { reverse } from "lodash";

export default {
    props: {
        id: {
            type: Number,
            required: false,
            default: 0
        },
        value: {
            type: Number,
            required: true,
            default: 0
        },
        showname: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            selectedProject: this.value,
            searchterm: ""
        };
    },
    computed: {
        projects() {
            return this.$store.state.projects.projects;
        },
        cleanedSearchterm: {
            get: function() {
                let st = this.searchterm;
                return st.toLowerCase();
            }
        },
        filteredProjectlist_normal() {
            if (this.projects.items === undefined) {
                return;
            }

            let filtered = [];
            if (this.searchterm == "") {
                filtered = this.projects.items.filter(project => project.isfavorite == 0);
                reverse(filtered);
                return filtered;
            }
            this.projects.items.forEach(project => {
                if (
                    (project.number.toLowerCase().indexOf(this.cleanedSearchterm) >= 0 ||
                        project.name.toLowerCase().indexOf(this.cleanedSearchterm) >= 0 ||
                        project.locationcity.toLowerCase().indexOf(this.cleanedSearchterm) >= 0 ||
                        project.client.toLowerCase().indexOf(this.cleanedSearchterm) >= 0) &&
                    !project.isfavorite
                ) {
                    filtered.push(project);
                }
            });
            reverse(filtered);
            return filtered;
        },
        filteredProjectlist_favorite() {
            if (this.projects.items === undefined) {
                return;
            }

            let filtered = [];
            if (this.searchterm == "") {
                filtered = this.projects.items.filter(project => project.isfavorite == 1);

                return filtered;
            }
            this.projects.items.forEach(project => {
                if (
                    (project.number.toLowerCase().indexOf(this.cleanedSearchterm) >= 0 ||
                        project.name.toLowerCase().indexOf(this.cleanedSearchterm) >= 0 ||
                        project.locationcity.toLowerCase().indexOf(this.cleanedSearchterm) >= 0 ||
                        project.client.toLowerCase().indexOf(this.cleanedSearchterm) >= 0) &&
                    project.isfavorite
                ) {
                    filtered.push(project);
                }
            });
            return filtered;
        }
    },
    methods: {
        focusSearchTerm(e) {
            this.$refs.projectlistsearchterm.$el.focus();
        },
        onChangeProject(event) {
            this.$emit("input", this.selectedProject);
            this.$bvModal.hide("modal-" + this.id);
        }
    }
};
</script>
