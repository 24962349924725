import Vue from "vue";
import Vuex from "vuex";

import { authentication } from "./authentication.module";

import { projects } from "./projects.module";
import { employees } from "./employees.module";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    getters: {},
    actions: {},
    modules: {
        authentication,
        projects,
        employees
    }
});
