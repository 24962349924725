<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <router-link
                class="btn btn-secondary btn-sm float-right"
                :to="{ name: 'projects.create' }"
            >
                <font-awesome-icon icon="plus" />&nbsp;neues Projekt anlegen</router-link
            >
            <h1>Projektliste</h1>
        </template>

        <div>
            <ProjectListSettings :cols="cols" />

            <div class="mb-5">
                <b-form-input
                    ref="projectlistsearchterm"
                    v-model="searchterm"
                    placeholder="nach Projekt suchen"
                ></b-form-input>
                <div class="float-right mt-2" v-if="searchterm != ''">
                    <button class="btn btn-sm btn-link" @click="searchterm = ''">
                        <font-awesome-icon icon="times" />&nbsp;Filter entfernen
                    </button>
                </div>
            </div>

            <b-form-group>
                <template v-slot:label>
                    Status:
                    <b-button size="sm" variant="link" @click="selectAllStatuses()">
                        alles auswählen
                    </b-button>
                    <b-button size="sm" variant="link" @click="selectNoStatuses()">
                        nichts auswählen
                    </b-button>
                </template>
                <b-form-checkbox-group
                    id="projectstatuses"
                    v-model="selected_statuses"
                    :options="options_statuses"
                    name="projectstatuses"
                ></b-form-checkbox-group>
            </b-form-group>
        </div>

        <div class="card">
            <div class="table-responsive">
                <ProjectListTable :cols="cols" :projects="filteredprojects" />
            </div>
        </div>
    </base-layout>
</template>
<script>
import ProjectListSettings from "../components/ProjectListSettings.vue";
import ProjectListTable from "../components/ProjectListTable.vue";

export default {
    components: {
        ProjectListSettings,
        ProjectListTable
    },
    data() {
        return {
            searchterm: "",
            selected_statuses: [2, 7],
            cols: [
                { name: "actions", title: "Aktionen", active: true, hidetitle: true },
                { name: "favorite", title: "Favorit", active: true, hidetitle: true },
                { name: "number", title: "Nummer", active: true },
                { name: "name", title: "Name", active: true },
                { name: "location", title: "Projektstandort", active: true },
                { name: "client", title: "Bauherr", active: true },
                { name: "status", title: "Status", active: true },
                { name: "documentlocation", title: "Unterlagenstandort", active: true },
                { name: "notes", title: "Notizen", active: false },
                {
                    name: "bsn",
                    title: "BSN",
                    active: false,
                    description: "Brandschutznachweis"
                },
                // { name: "inwarranty", title: "in Gewährleistung", active: false },
                // { name: "startdate", title: "Startdatum", active: true },
                // { name: "enddate", title: "Enddatum", active: true }
                // { name: "asana", title: "Asana", active: false, description: "Link Asana" },
                // { name: "avanova", title: "Avanova", active: false, description: "Link Avanova" },
                // { name: "btb", title: "BTB", active: false, description: "Link Bautagebuch" }
                {
                    name: "links",
                    title: "Links",
                    active: false,
                    description: "Weiterführende Links"
                }
            ]
        };
    },
    created() {},
    computed: {
        cleanedSearchterm: {
            get: function() {
                let st = this.searchterm;
                return st.toLowerCase();
            }
        },
        options_statuses() {
            var ret = [];
            for (var i = 0; i < this.statuses.items.length; i++) {
                ret.push({
                    value: this.statuses.items[i].id,
                    text: this.statuses.items[i].name,
                    id: this.statuses.items[i].id
                });
            }
            return ret;
        },
        projects() {
            return this.$store.state.projects.projects;
        },
        statuses() {
            return this.$store.state.projects.statuses;
        },
        filteredprojects() {
            let fp = [];
            for (let i = 0; i < this.projects.items.length; i++) {
                if (this.selected_statuses.includes(this.projects.items[i].status_id)) {
                    if (this.cleanedSearchterm.length > 0) {
                        if (
                            this.projects.items[i].number
                                .toLowerCase()
                                .indexOf(this.cleanedSearchterm) >= 0 ||
                            this.projects.items[i].name
                                .toLowerCase()
                                .indexOf(this.cleanedSearchterm) >= 0 ||
                            this.projects.items[i].locationcity
                                .toLowerCase()
                                .indexOf(this.cleanedSearchterm) >= 0 ||
                            this.projects.items[i].client
                                .toLowerCase()
                                .indexOf(this.cleanedSearchterm) >= 0
                        ) {
                            fp.push(this.projects.items[i]);
                        }
                    } else {
                        fp.push(this.projects.items[i]);
                    }
                }
            }
            return fp;
        }
    },
    methods: {
        selectAllStatuses() {
            var ret = [];
            for (var i = 0; i < this.statuses.items.length; i++) {
                ret.push(this.statuses.items[i].id);
            }
            this.selected_statuses = ret;
        },
        selectNoStatuses() {
            this.selected_statuses = [];
        }
    },
    watch: {
        searchterm() {
            if (this.searchterm.length > 0 && this.filteredprojects.length == 0) {
                this.selectAllStatuses();
            }
        }
    }
};
</script>
