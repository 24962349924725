import { authHeader } from "../_helpers";
import axios from "axios";

export const userService = {
    login,
    logout,
    changePassword,
    register,
    getAll,
    findOne,
    updateOne,
    deleteOne
};

const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: authHeader()
});

function login(email, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${process.env.VUE_APP_API_URL}login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(user));
            }

            return user;
        });
}

function logout() {
    localStorage.removeItem("user");
}

function changePassword(userID, newPassword) {
    //@TODO!!! test it
    var data = { newPassword: newPassword };
    return client.post(`user/${userID}/changepassword`, data).then(user => {
        // login successful if there's a jwt token in the response
        if (user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("user", JSON.stringify(user));
        }
        this.logout();
    });
}

function register(data) {
    return client.post("register", data);
}

function getAll() {
    return client.get(`users`);
}

function findOne(id) {
    return client.get(`users/${id}`);
}

function updateOne(id, data) {
    return client.put(`users/${id}`, data);
}

function deleteOne(id) {
    return client.delete(`users/${id}`);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
