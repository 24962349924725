var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-nav',{attrs:{"tabs":"","align":"center"}},[_c('b-nav-item',{attrs:{"active":_vm.isActive('users-overview'),"to":{
            name: 'users.overview'
        }}},[_vm._v("Übersicht")]),_c('b-nav-item',{attrs:{"active":_vm.isActive('employees-create'),"to":{
            name: 'employees.create'
        }}},[_vm._v("Neuen Mitarbeiter anlegen")]),_c('b-nav-item',{attrs:{"active":_vm.isActive('users-create'),"to":{
            name: 'users.create'
        }}},[_vm._v("Neuen Benutzer anlegen")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }