<template>
    <div>
        <b-button-group size="sm">
            <b-button variant="outline-secondary" @click="previousYear()">«</b-button>
            <b-button variant="outline-secondary" disabled>{{ localyearDisplay }}</b-button>
            <b-button variant="outline-secondary" @click="nextYear()">»</b-button>
        </b-button-group>
        <b-button-group size="sm" class="ml-3">
            <b-button
                v-if="showcurrentyear"
                variant="outline-secondary"
                @click="changeYear(currentyear)"
                >aktuelles Jahr</b-button
            >
            <b-button v-if="allyears" variant="outline-secondary" @click="changeYear(0)"
                >alle Jahre</b-button
            >
        </b-button-group>
        <b-form-checkbox
            v-if="switchtext.length > 0"
            v-model="localswitchvalue"
            name="check-button"
            class="d-inline ml-3"
            switch
            >{{ switchtext }}</b-form-checkbox
        >
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true,
            default: 0
        },
        switchtext: {
            type: String,
            required: false,
            default: ""
        },
        switchvalue: {
            type: Boolean,
            required: false,
            default: false
        },
        allyears: {
            type: Boolean,
            required: false,
            default: true
        },
        showcurrentyear: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            localswitchvalue: this.switchvalue,
            currentyear: parseInt(window.moment().format("YYYY"))
        };
    },
    created() {},
    methods: {
        nextYear() {
            if (this.value > 0) {
                this.changeYear(this.value + 1);
            } else {
                this.changeYear(this.currentyear);
            }
        },
        previousYear() {
            if (this.value > 0) {
                this.changeYear(this.value - 1);
            } else {
                this.changeYear(this.currentyear);
            }
        },
        changeYear(newValue) {
            this.$emit("input", newValue);
        }
    },
    computed: {
        localyearDisplay: function() {
            if (this.value == 0) {
                return "alle Jahre";
            } else {
                return this.value;
            }
        }
    },
    watch: {
        localswitchvalue: function() {
            this.$emit("switchvalue-changed", this.localswitchvalue);
        }
    }
};
</script>
