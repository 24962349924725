<template>
    <table class="table table-hover mb-0">
        <thead>
            <tr>
                <th>Datum</th>
                <th>Faktor</th>
                <th class="text-right">Stundenzahl</th>
                <th>Beschreibung</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <NoEntriesFound
                :colspan="5"
                message="Es wurden keine Krankheitstage gefunden."
                v-if="activeEntries.length == 0"
            />
            <tr v-else v-for="vd in activeEntries" v-bind:key="vd.date">
                <td class="text-left">
                    {{ vd.date | DateLong }}
                    <span v-if="currentdate <= vd.date">
                        <b-badge variant="warning">zukünftig</b-badge>
                    </span>
                </td>
                <td>{{ vd.factor | DayFractionsThreeDigits }}</td>
                <td class="text-right">{{ vd.recordvalue_computed | NumberTwoDigits }}</td>
                <td>{{ vd.description }}</td>
                <td>
                    <!-- <button type="button" class="btn btn-link btn-sm">
                        <font-awesome-icon icon="edit" />
                    </button> -->
                    <button
                        type="button"
                        class="btn btn-link btn-sm"
                        @click="deleteSickday(vd.date)"
                    >
                        <font-awesome-icon icon="trash-alt" />
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { employeeService } from "../_services";

export default {
    props: {
        entries: Array,
        year: Number,
        employee_id: Number
    },
    data() {
        return { currentdate: window.moment().format("YYYY-MM-DD") };
    },
    created() {},
    methods: {
        deleteSickday(date) {
            var data = { date: date };
            employeeService
                .deleteSickdayFromEmployee(this.employee_id, data)
                .then(response => {
                    this.showUpdateSuccess = true;
                    this.$emit("sickday-deleted", response.data);
                })
                .catch(response => {
                    this.showUpdateError = true;
                    //@TODO bug: some may be created (only third sd fails because exists), but user gets no feedback for those created and where it failed
                });
        }
    },
    computed: {
        activeEntries: function() {
            if (this.year == 0) {
                return this.entries;
            }
            let ret = [];
            this.entries.forEach(element => {
                if (element.date.includes(this.year)) {
                    ret.push(element);
                }
            });
            return ret;
        }
    },
    watch: {}
};
</script>
