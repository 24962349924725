<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>Projekt-Teams</h1>
        </template>

        <div class="d-flex justify-content-center mt-5 mb-5" v-if="!loaded">
            <b-spinner label="loaded..."></b-spinner>
        </div>
        <div v-else>
            <b-alert type="info" show>
                Es werden nur aktive Projekte bzw. aktive und freie Mitarbeiter angezeigt.<br />
                Die jeweiligen Mitarbeiter können in der Projektliste gepflegt werden.
            </b-alert>

            <div class="card shadow table-responsive mt-5 mb-5">
                <table class="table table-without-top-line mb-0">
                    <tbody>
                        <tr
                            v-for="project in projectsWithEmployees"
                            v-bind:key="project.id"
                            class="mb-5"
                        >
                            <td><ProjectName :id="project.id" :link="true" /></td>
                            <td v-for="employee in employeesActive" v-bind:key="employee.id">
                                <span v-if="ProjectContainsEmployee(project.id, employee.id)">
                                    <EmployeeName :id="employee.id" :dot="true" :initials="true" />
                                    <!-- <div class="small text-muted">
                                        {{
                                            $root.ProjectroleFullTitle(
                                                ProjectContainsEmployee(project.id, employee.id)
                                            )
                                        }}
                                    </div> -->
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <b-row class="pt-5">
                <b-col>
                    <b-card class="shadow">
                        <div
                            v-for="project in projectsWithEmployees"
                            v-bind:key="project.id"
                            class="mb-5"
                        >
                            <h4><ProjectName :id="project.id" /></h4>
                            <div
                                v-for="pe in project.projectemployees_ids"
                                v-bind:key="project.id + '_' + pe.employee_id"
                            >
                                <EmployeeName :id="pe.employee_id" :dot="true" />

                                <!-- <span class="small text-muted ml-2">
                                    {{ $root.ProjectroleFullTitle(pe.projectrole_id) }}
                                </span> -->
                            </div>
                        </div>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card class="shadow">
                        <div
                            v-for="employee in employeesActive"
                            v-bind:key="employee.id"
                            class="mb-5"
                        >
                            <h4>
                                <EmployeeName :id="employee.id" :dot="true" />
                            </h4>
                            <div v-if="ProjectsOfEmployee(employee.id).length > 0">
                                <div
                                    v-for="project in ProjectsOfEmployee(employee.id)"
                                    v-bind:key="project.id"
                                >
                                    <ProjectName :id="project.id" :icon="false" />
                                    <!-- <span class="small text-muted ml-2">
                                        {{
                                            $root.ProjectroleFullTitle(
                                                ProjectContainsEmployee(project.id, employee.id)
                                            )
                                        }}
                                    </span> -->
                                </div>
                            </div>
                            <div v-else class="small text-muted">
                                Es wurden keine zugeordneten Projekte gefunden.
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </base-layout>
</template>
<script>
import { employeeService } from "../_services";
import { filter } from "lodash";
import { mapGetters } from "vuex";

export default {
    components: {},
    data() {
        return { loaded: true };
    },
    created() {},
    computed: {
        ...mapGetters({
            employeesActive: "employees/employeesActive"
        }),
        projectsWithEmployees() {
            var projects = this.$store.state.projects.projects.items;
            var ret = filter(projects, function(p) {
                return p.status_id == 2 && p.projectemployees_ids.length > 0;
            });
            return ret;
        }
    },
    methods: {
        ProjectsOfEmployee(employee) {
            var ret = [];
            var ps = this.projectsWithEmployees;
            for (var i = 0; i < ps.length; i++) {
                for (var j = 0; j < ps[i].projectemployees_ids.length; j++) {
                    if (ps[i].projectemployees_ids[j].employee_id == employee) {
                        ret.push(ps[i]);
                    }
                }
            }
            return ret;
        },
        ProjectContainsEmployee(project, employee) {
            var ps = this.projectsWithEmployees;
            for (var i = 0; i < ps.length; i++) {
                for (var j = 0; j < ps[i].projectemployees_ids.length; j++) {
                    if (
                        ps[i].id == project &&
                        ps[i].projectemployees_ids[j].employee_id == employee
                    ) {
                        return ps[i].projectemployees_ids[j].projectrole_id;
                    }
                }
            }
            return false;
        }
    },
    watch: {}
};
</script>
