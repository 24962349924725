<template>
    <b-row class="quicklinks">
        <b-col v-for="ql in quicklinks" v-bind:key="ql.name">
            <b-card>
                <a :href="ql.link" target="_blank" class="float-right">
                    <font-awesome-icon size="2x" icon="external-link-alt" />
                </a>
                <h4>{{ ql.name }}</h4>
                {{ ql.description }}
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default {
    data() {
        return {
            quicklinks: [
                {
                    name: "Asana",
                    description: "Aufgaben & ToDos",
                    link: "https://app.asana.com/"
                },
                {
                    name: "AVAnova",
                    description: "Ausschreibung, Vergabe, Kosten",
                    link: "https://2g-architekten.avanova.de/?s=user&m=login"
                },
                {
                    name: "Bautagebuch",
                    description: "Projektdokumentation",
                    link: "https://www.2g-architekten.de/btb/"
                },
                {
                    name: "Snipe",
                    description: "Assets",
                    link: "https://snipe.zweige.org"
                },
                {
                    name: "WIKI",
                    description: "Intranet",
                    link: "https://wiki.zweige.org"
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.quicklinks {
    position: relative;
    top: -30px;
    .card {
        padding-top: 10px;
    }
    color: #808080;
    a {
        color: #808080;
    }
}
</style>
