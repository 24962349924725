<template>
    <div>
        <StackedBarChart
            v-if="getPeriods().length > 1"
            :chart-data="datacollection"
        ></StackedBarChart>
    </div>
</template>

<script>
import StackedBarChart from "./StackedBarChart.js";
import { map, sortBy, uniq, filter } from "lodash";

export default {
    props: {
        chartData: Array
    },
    components: {
        StackedBarChart
    },
    data() {
        return {
            datacollection: null
        };
    },
    mounted() {
        this.fillData();
    },
    methods: {
        fillData() {
            this.datacollection = {
                labels: map(this.getPeriods(), this.$options.filters.PeriodSmall),
                datasets: this.getDatasetEntries(),
                borderWidth: 0
            };
        },
        getPeriods() {
            var ret = [];
            for (var i = 0; i < this.chartData.length; i++) {
                ret.push(this.chartData[i].period);
            }
            return sortBy(uniq(ret));
        },
        getEmployees() {
            var ret = [];
            for (var i = 0; i < this.chartData.length; i++) {
                ret.push(this.chartData[i].employee_id);
            }
            return sortBy(uniq(ret));
        },
        getDatasetEntries() {
            var ret = [];
            var eids = this.getEmployees();
            for (var i = 0; i < eids.length; i++) {
                ret.push({
                    label: this.$root.EmployeeFullName(eids[i]),
                    backgroundColor: this.$root.hexToRgbA(
                        this.$root.EmployeeBaseColor(eids[i]),
                        0.9
                    ),
                    borderColor: this.$root.EmployeeBaseColor(eids[i]),
                    data: this.getPeriodValuesOf(eids[i])
                });
            }
            return ret;
        },
        getPeriodValuesOf(employee_id) {
            var periods = this.getPeriods();
            var ret = [];
            for (var i = 0; i < periods.length; i++) {
                ret.push(this.getValueOf(employee_id, periods[i]));
            }
            return ret;
        },
        getValueOf(employee_id, period) {
            var found = filter(this.chartData, {
                employee_id: parseInt(employee_id),
                period: period
            });
            if (found.length == 0) {
                return null;
            } else {
                return found[0].duration;
            }
        }
    }
};
</script>
