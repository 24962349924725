<template>
    <div>
        <b-button v-b-modal.modal-1 size="sm" variant="outline-secondary" class="mb-3 float-right"
            ><font-awesome-icon icon="cogs" />&nbsp;Spalten-Optionen</b-button
        >

        <b-modal size="sm" id="modal-1" title="verfügbare Spalten" hide-footer>
            <p class="small text-muted pb-4 ">
                <!-- Die Reihenfolge der Optionen kann per Drag&Drop verändert werden -->
                <!--@TODO does not work when closed and reopened! -->
            </p>

            <draggable :list="cols" class="list-group" ghost-class="ghost">
                <!--handle=".handle"-->
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <div class="list-group-item" v-for="element in cols" :key="element.name">
                        <!-- <font-awesome-icon icon="align-justify" class="handle" /> -->
                        <b-form-checkbox v-model="element.active" name="check-button" switch>
                            {{ element.title }}
                        </b-form-checkbox>
                    </div>
                </transition-group>
            </draggable>
        </b-modal>
    </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
    props: {
        cols: Array
    },
    components: { draggable },
    data() {
        return {
            drag: false
        };
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    }
};
</script>

<style>
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
