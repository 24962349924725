import { employeeService } from "../_services";
import { basedataService } from "../_services";

export const employees = {
    namespaced: true,
    state: {
        user: {
            data: [],
            loading: true,
            error: null
        },
        users: {
            items: [],
            loading: true,
            error: null
        },
        employees: {
            items: [],
            loading: true,
            error: null
        },
        rights: {
            items: [],
            loading: true,
            error: null
        }
    },
    getters: {
        user() {
            return employees.state.user;
        },
        users() {
            return employees.state.users.items;
        },
        employees() {
            return employees.state.employees.items;
        },
        employeesInactive() {
            return employees.state.employees.items.filter(
                employee => employee.currentstatustitle == "inactive"
            );
        },
        employeesActive() {
            return employees.state.employees.items.filter(
                employee =>
                    employee.currentstatustitle == "employed" ||
                    employee.currentstatustitle == "freelancer"
            );
        },
        employeesFreelancer() {
            return employees.state.employees.items.filter(
                employee => employee.currentstatustitle == "freelancer"
            );
        },
        employeesEmployed() {
            return employees.state.employees.items.filter(
                employee => employee.currentstatustitle == "employed"
            );
        },
        rights() {
            return employees.state.rights.items;
        }
    },
    actions: {
        getAll({ commit }) {
            commit("getInitRequest");
            basedataService.getEmployeeBasedata().then(
                basedata => commit("getInitSuccess", basedata),
                error => commit("getInitFailure", error)
            );
        },
        reload({ commit }) {
            commit("reloadRequest");
            basedataService.getEmployeeBasedata().then(
                basedata => commit("reloadSuccess", basedata),
                error => commit("reloadFailure", error)
            );
        }
    },
    mutations: {
        UsersLoading(state) {
            state.users.loading = true;
        },
        getInitRequest(state) {
            state.user.loading = state.users.loading = state.employees.loading = state.rights.loading = true;
            state.user.error = state.users.error = state.employees.error = state.rights.error = null;
        },
        getInitSuccess(state, basedata) {
            state.user.data = basedata.user;
            state.users.items = basedata.users;
            state.employees.items = basedata.employees;
            state.rights.items = basedata.rights;

            state.user.loading = state.users.loading = state.employees.loading = state.rights.loading = false;
            state.user.error = state.users.error = state.employees.error = state.rights.error = null;
        },
        getInitFailure(state, error) {
            state.user.loading = state.users.loading = state.employees.loading = state.rights.loading = false;
            state.user.error = state.users.error = state.employees.error = state.rights.error = error;
        }
    }
};
