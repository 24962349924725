// import config from 'config';
import { authHeader } from "../_helpers";
import axios from "axios";

export const employeeService = {
    getAll,
    allInactive,
    findOne,
    updateOne,
    deleteOne,
    createOne,
    getSickdaysPerEmployeePerYear,
    getSickdaysPerEmployee,
    getRecordOverviewPerEmployee,
    getPublicholidaysPerEmployee,
    getPublicholidays,
    getEmployeesPerPublicholiday,
    updateEmployeesPerPublicholiday,
    getTimeSheetPerEmployeePerPeriod,
    getRecordsPerEmployeePerDay,
    updateRecordsPerEmployeePerDay,
    getVacationdays,
    getVacationdaysPerEmployeePerYear,
    updateVacationdaysPerEmployeePerYear,
    getVacationdaysPerEmployee,
    copyPublicholidaysFromLastYear,
    getRecordOverviewPerEmployeeForAllActive,
    createSickdaysPerEmployee,
    deleteSickdayFromEmployee,
    updateVacationQuota,
    updateCustomQuota
};

const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: authHeader()
});

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_URL + `employees`, requestOptions).then(handleResponse);
}

function allInactive() {
    return client.get("employees/inactive");
}
function findOne(id) {
    return client.get(`employees/${id}`);
}
function updateOne(id, data) {
    return client.put(`employees/${id}`, data);
}
function deleteOne(id) {
    return client.delete(`employees/${id}`);
}
function createOne(data) {
    return client.post("employees", data);
}

function getSickdaysPerEmployeePerYear(employee_id, year) {
    return client.get(`sickdaysperemployeeperyear/${employee_id}/${year}`);
}
function getSickdaysPerEmployee(employee_id) {
    return client.get(`sickdaysperemployee/${employee_id}`);
}
function getRecordOverviewPerEmployee(employee_id) {
    return client.get(`recordoverviewperemployee/${employee_id}`);
}
function getPublicholidaysPerEmployee(employee_id) {
    return client.get(`publicholidaysperemployee/${employee_id}`);
}
function getPublicholidays(year) {
    return client.get(`publicholidays/${year}`);
}
function getEmployeesPerPublicholiday(year) {
    return client.get(`employeesperpublicholiday/${year}`);
}
function updateEmployeesPerPublicholiday(year, data) {
    return client.put(`employeesperpublicholiday/${year}`, data);
}
function getTimeSheetPerEmployeePerPeriod(employee_id, period) {
    return client.get(`timesheetperemployee/${employee_id}/${period}`);
}
function getRecordsPerEmployeePerDay(employee_id, date) {
    return client.get(`recordsperemployeeperday/${employee_id}/${date}`);
}
function updateRecordsPerEmployeePerDay(employee_id, date, data) {
    return client.put(`recordsperemployeeperday/${employee_id}/${date}`, data);
}
function getVacationdays(year) {
    return client.get(`vacationdays/${year}`);
}
function getVacationdaysPerEmployeePerYear(employee_id, year) {
    return client.get(`vacationdaysperemployeeperyear/${employee_id}/${year}`);
}
function updateVacationdaysPerEmployeePerYear(employee_id, year, data) {
    return client.put(`vacationdaysperemployeeperyear/${employee_id}/${year}`, data);
}
function getVacationdaysPerEmployee(employee_id) {
    return client.get(`vacationdaysperemployee/${employee_id}`);
}
function copyPublicholidaysFromLastYear(year) {
    return client.get(`task/copypublicholidaysfromlastyear/${year}`);
}
function getRecordOverviewPerEmployeeForAllActive() {
    return client.get(`recordoverviewforallactiveemployees`);
}
function createSickdaysPerEmployee(employee_id, data) {
    return client.post(`sickdaysperemployee/${employee_id}`, data);
}
function deleteSickdayFromEmployee(employee_id, data) {
    return client.delete(`sickdaysperemployee/${employee_id}`, { data });
}
function updateVacationQuota(employee_id, data) {
    return client.put(`vacationquotas/${employee_id}`, data);
}
function updateCustomQuota(employee_id, data) {
    return client.put(`customquotas/${employee_id}`, data);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
