<template>
    <span v-if="editable">
        <b-card class="shadow mb-5" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />&nbsp;{{ title }}
                <b-button-group class="float-right">
                    <b-button variant="outline-secondary" size="sm" @click="cancelEditing()">
                        <font-awesome-icon icon="times" />&nbsp;abbrechen
                    </b-button>
                    <b-button variant="outline-primary" size="sm" @click="saveData()">
                        <font-awesome-icon icon="save" />&nbsp;speichern
                    </b-button>
                </b-button-group>
            </h5>

            <b-form-group label="Straße">
                <b-form-input v-model="projectinfo.locationstreet"></b-form-input>
            </b-form-group>
            <b-form-group label="PLZ/Ort">
                <b-row>
                    <b-col>
                        <b-form-input v-model="projectinfo.locationplz"></b-form-input>
                    </b-col>
                    <b-col>
                        <b-form-input v-model="projectinfo.locationcity"></b-form-input>
                    </b-col>
                </b-row>
            </b-form-group>
        </b-card>
    </span>
    <span v-else>
        <b-card class="card-transparent mb-5  hover-field" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />&nbsp;{{ title }}
                <b-button-group class="float-right show-on-hover">
                    <b-button
                        variant="link"
                        size="sm"
                        class="btn-sm-without-padding"
                        @click="startEditing()"
                    >
                        <font-awesome-icon icon="edit" />&nbsp;bearbeiten
                    </b-button>
                </b-button-group>
            </h5>

            <span
                v-if="
                    projectinfo.locationcity.length > 0 ||
                        projectinfo.locationplz.length > 0 ||
                        projectinfo.locationstreet.length > 0
                "
            >
                {{ projectinfo.locationstreet }}
                <br />
                {{ projectinfo.locationplz }} {{ projectinfo.locationcity }} <br />
                <br />

                <a
                    class="btn btn-sm btn-secondary"
                    :href="
                        $root.generateGoogleMapsLink(
                            this.projectinfo.locationcity,
                            this.projectinfo.locationstreet
                        )
                    "
                    target="_blank"
                    >in Google Maps anzeigen</a
                >
            </span>
            <span v-else class="text-muted small">Keine Adresse hinterlegt.</span>
        </b-card>
    </span>
</template>

<script>
import { projectService } from "../../_services";

export default {
    props: {
        projectinfo: Object
    },
    components: {},
    data: function() {
        return {
            loaded: true,
            editable: false,
            title: "Adresse",
            icon: "map-marked-alt"
        };
    },
    methods: {
        cancelEditing() {
            this.editable = false;
        },
        saveData() {
            var data = {
                locationcity: this.projectinfo.locationcity,
                locationplz: this.projectinfo.locationplz,
                locationstreet: this.projectinfo.locationstreet
            };
            projectService
                .updateOne(this.projectinfo.id, data)
                .then(response => {
                    this.editable = false;
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");
                    this.$root.toast("Das Projekt wurde erfolgreich geändert.", "success");
                })
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht geändert werden.", "danger");
                });
        },
        startEditing() {
            this.editable = true;
        }
    }
};
</script>
