<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1 class="mt-2">
                Buchungsauswertung
                <br />
                <span class="font-weight-bold">
                    <ProjectName :id="project_id" :icon="false" />
                </span>
            </h1>
        </template>

        <template v-slot:page-sub-menu>
            <ProjectSubMenu active="projects-analysis" :project_id="project_id" />
        </template>

        <Loading v-if="loading" />

        <div class="card" v-else>
            <div class="card-body">
                <ProjectMonthEmployeeChart :chart-data="projectaggregates.aggregates" />
                <b-row class="my-5">
                    <b-col>
                        <h4>Projektbuchungen nach Mitarbeitern</h4>
                        <table class="table">
                            <tbody>
                                <tr
                                    v-for="entry in projectaggregates.aggregates_employees"
                                    v-bind:key="entry.employee_id"
                                >
                                    <td><EmployeeName :id="entry.employee_id" :dot="false" /></td>
                                    <td class="text-right">
                                        {{ entry.duration | NumberTwoDigits }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Gesamt</td>
                                    <td class="text-right">
                                        {{ sum_employees | NumberTwoDigits }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </b-col>
                    <b-col>
                        <h4>Projektbuchungen nach Monaten</h4>
                        <table class="table">
                            <tbody>
                                <tr
                                    v-for="entry in projectaggregates.aggregates_periods"
                                    v-bind:key="entry.employee_id"
                                >
                                    <td>{{ entry.period | PeriodSmall }}</td>
                                    <td class="text-right">
                                        {{ entry.duration | NumberTwoDigits }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Gesamt</td>
                                    <td class="text-right">
                                        {{ sum_employees | NumberTwoDigits }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </b-col>
                </b-row>
            </div>
        </div>
    </base-layout>
</template>
<script>
import { projectService } from "../../_services";
import ProjectSubMenu from "../../components/SubMenus/ProjectSubMenu.vue";
import ProjectMonthEmployeeChart from "../../components/Charts/ProjectMonthEmployeeChart.vue";

export default {
    components: {
        ProjectSubMenu,
        ProjectMonthEmployeeChart
    },
    data() {
        return {
            project_id: parseInt(this.$route.params.id),
            loading: false,
            projectaggregates: null
        };
    },
    created() {
        // if (this.$root.UserHasRight("canManageProjects")) {
        this.fetchData();
        // }
    },
    methods: {
        fetchData() {
            this.projectaggregates = null;
            this.loading = true;
            projectService.getAllAggregates(this.$route.params.id).then(response => {
                this.loading = false;
                this.projectaggregates = response.data;
            });
        }
    },
    computed: {
        sum_employees() {
            var ret = 0;
            this.projectaggregates.aggregates_employees.forEach(element => {
                ret += parseFloat(element.duration);
            });
            return ret;
        },
        sum_periods() {
            var ret = 0;
            this.projectaggregates.aggregates_periods.forEach(element => {
                ret += parseFloat(element.duration);
            });
            return ret;
        }
    }
};
</script>
