<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>Projekt-Favoriten</h1>
        </template>

        <b-row>
            <div
                class="col-12 col-md-6 col-lg-4 col-xl-3 mb-5"
                v-for="project in projects"
                v-bind:key="'project-' + project.id"
            >
                <ProjectBox :projectinfo="project" />
            </div>
        </b-row>
    </base-layout>
</template>
<script>
import ProjectBox from "../components/ProjectBox";

export default {
    components: {
        ProjectBox
    },
    computed: {
        projects() {
            var ret = [];
            this.$store.state.projects.projects.items.forEach(project => {
                if (project.isfavorite == true) {
                    ret.push(project);
                }
            });
            return ret;
        }
    },
    methods: {}
};
</script>
