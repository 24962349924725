<template>
    <router-view v-if="!this.$root.isLoggedIn || everythingIsLoaded()" />
    <Loading v-else size="xl" />
</template>
<script>
export default {
    created() {
        this.makeBaseData();
    },
    methods: {
        makeBaseData() {
            if (this.$root.isLoggedIn) {
                this.$store.dispatch("employees/getAll");
                this.$store.dispatch("projects/getAll");
            }
        },
        everythingIsLoaded() {
            if (
                this.$store.state.employees !== undefined &&
                !this.$store.state.employees.employees.loading &&
                this.$store.state.projects !== undefined &&
                !this.$store.state.projects.projects.loading
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
