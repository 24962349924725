<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <EmployeeSelector v-model="employee_id"></EmployeeSelector>
            <h1>Meine Übersicht: <EmployeeName :id="employee_id" :dot="false" /></h1>
        </template>

        <template v-slot:page-sub-menu>
            <EmployeeDetailSubMenu active="employee-sickdays" :employee_id="employee_id" />
        </template>

        <div class="card shadow mb-4">
            <div class="card-body">
                <SickdayCreate
                    v-if="$root.userHasManagerRoleForEmployee(employee_id)"
                    :employee_id="employee_id"
                    @new-sickdays-created="employeesickdays = $event"
                />
                <YearSelector v-model="year" />
            </div>
            <Loading v-if="loading" />
            <SickdaysTable
                v-else
                :year="year"
                :entries="employeesickdays.sickdays"
                :employee_id="employee_id"
                v-on:sickday-deleted="employeesickdays = $event"
            />
        </div>
    </base-layout>
</template>
<script>
import EmployeeDetailSubMenu from "../../components/SubMenus/EmployeeDetailSubMenu.vue";
import EmployeeSelector from "../../components/EmployeeSelector.vue";
import YearSelector from "../../components/YearSelector.vue";
import { employeeService } from "../../_services";
import SickdaysTable from "../../components/SickdaysTable.vue";
import SickdayCreate from "../../components/SickdayCreate.vue";

export default {
    data() {
        return {
            employee_id: parseInt(this.$route.params.id),
            loading: true,
            year: parseInt(window.moment().format("YYYY")),
            employeesickdays: {}
        };
    },
    components: {
        EmployeeDetailSubMenu,
        EmployeeSelector,
        YearSelector,
        SickdaysTable,
        SickdayCreate
    },
    methods: {
        fetchData() {
            this.loading = true;
            employeeService.getSickdaysPerEmployee(this.employee_id).then(response => {
                this.loading = false;
                this.employeesickdays = response.data;
            });
        }
    },
    created() {
        this.fetchData();
    },
    watch: {
        employee_id: function(newVal, oldVal) {
            this.$router.push({
                name: "employee.sickdays",
                params: { id: newVal }
            });
            this.fetchData();
        }
    }
};
</script>
