<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <QuickLinks />
            <h1 class="display-3 mt-3 mb-1">Willkommen, {{ basedata.name }}</h1>
        </template>

        <b-row class="mb-4">
            <b-col v-if="$root.userHasAnyEmployees()">
                <LoadingEmployeeInfobox cols="12" number="6" v-if="!loaded_data" />

                <b-row v-else>
                    <b-col
                        cols="12"
                        class="mb-5"
                        v-for="employeedata in allemployeedata"
                        v-bind:key="employeedata.employee.id"
                    >
                        <EmployeeInfobox :data="employeedata" :freelancer="false" />
                    </b-col>
                </b-row>
            </b-col>
            <b-col>
                <b-row>
                    <b-col
                        :cols="projectcols"
                        class="mb-5"
                        v-for="project in projects"
                        v-bind:key="'project-' + project.id"
                    >
                        <ProjectBox :projectinfo="project" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </base-layout>
</template>
<script>
import { employeeService } from "../_services";
import ProjectBox from "../components/ProjectBox";
import EmployeeInfobox from "../components/EmployeeInfobox.vue";
import LoadingEmployeeInfobox from "../components/LoadingEmployeeInfobox";
import QuickLinks from "../components/QuickLinks";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            loaded_data: false,
            allemployeedata: {},
            CurrentPeriod: window.moment().format("YYYY-MM")
        };
    },
    components: {
        ProjectBox,
        LoadingEmployeeInfobox,
        EmployeeInfobox,
        QuickLinks
    },
    computed: {
        ...mapGetters({
            employeesFreelancer: "employees/employeesFreelancer"
        }),
        projectcols() {
            if (this.$root.userHasAnyEmployees()) {
                return 6;
            } else {
                return 3;
            }
        },
        basedata() {
            return this.$store.state.employees.user.data;
        },
        projects() {
            var ret = [];
            this.$store.state.projects.projects.items.forEach(project => {
                if (project.isfavorite == true) {
                    ret.push(project);
                }
            });
            return ret;
        },
        employees_freelancer() {
            var ret = [];
            this.employeesFreelancer.forEach(employee => {
                if (this.$root.userHasEmployee(employee.id)) {
                    ret.push(employee);
                }
            });
            return ret;
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loaded_data = false;
            employeeService
                .getRecordOverviewPerEmployeeForAllActive()
                .then(response => {
                    this.loaded_data = true;
                    this.allemployeedata = response.data;
                })
                .catch(error => {
                    //@TODO ONLY WORKAROUND!
                    this.$store.dispatch("authentication/logout");
                    location.reload(true);
                });
        }
    }
};
</script>
