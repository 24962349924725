<template>
    <div class="d-flex justify-content-between align-items-center">
        <span>
            <font-awesome-icon
                v-if="managed"
                icon="user-lock"
                class="text-info"
                :id="'tooltip-managed' + employee.id + '-' + user.id"
            />
            <b-tooltip :target="'tooltip-managed' + employee.id + '-' + user.id" triggers="hover">
                eingeschränkte Rechte
            </b-tooltip>
            <font-awesome-icon
                v-if="manager"
                icon="user-shield"
                class="text-success"
                :id="'tooltip-manager' + employee.id + '-' + user.id"
            />
            <b-tooltip :target="'tooltip-manager' + employee.id + '-' + user.id" triggers="hover">
                alle Rechte
            </b-tooltip>

            <EmployeeName :id="employee_id" :dot="false" />
            <b-badge v-if="self" variant="primary">HAUPTNUTZER</b-badge>
            <br />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        employee_id: {
            type: Number,
            required: true
        },
        user_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        employee() {
            return this.$root.getEmployeeObject(this.employee_id);
        },
        user() {
            return this.$root.getUserObject(this.user_id);
        },
        pivot() {
            return this.user.employee_ids.find(
                employee => employee.employee_id == this.employee_id
            );
        },
        managed() {
            return this.pivot.role == "managed";
        },
        manager() {
            return this.pivot.role == "manager";
        },
        self() {
            return this.pivot.self == 1;
        }
    }
};
</script>
