<template>
    <b-nav tabs align="center">
        <b-nav-item
            :active="isActive('employee-detail')"
            :to="{
                name: 'employee.detail',
                params: { id: employee_id }
            }"
            >Übersicht</b-nav-item
        >
        <b-nav-item
            :active="isActive('employee-monthlysums')"
            :to="{
                name: 'employee.monthlysums',
                params: { id: employee_id }
            }"
            >Monatsübersichten</b-nav-item
        >
        <b-nav-item
            :active="isActive('employee-vacationdays')"
            :to="{
                name: 'employee.vacationdays',
                params: { id: employee_id }
            }"
            >Urlaubstage
        </b-nav-item>
        <b-nav-item
            :active="isActive('employee-sickdays')"
            :to="{
                name: 'employee.sickdays',
                params: { id: employee_id }
            }"
            >Krankheitstage
        </b-nav-item>
        <b-nav-item
            :active="isActive('employee-publicholidays')"
            :to="{
                name: 'employee.publicholidays',
                params: { id: employee_id }
            }"
            >Feiertage
        </b-nav-item>
    </b-nav>
</template>
<script>
export default {
    props: {
        active: String,
        employee_id: Number
    },
    data() {
        return {};
    },
    methods: {
        isActive(NameOfNavItem) {
            return NameOfNavItem == this.active;
        }
    },
    computed: {
        // employee() {
        //     return this.$root.getEmployeeObject(this.employee_id);
        // }
    }
};
</script>
