<template>
    <table class="table table-hover mb-0">
        <thead>
            <tr>
                <th v-for="col in cols" v-bind:key="'row' + col.name" v-show="col.active">
                    <span v-if="!col.hidetitle"
                        >{{ col.title }}
                        <small><br />{{ col.description }}</small>
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <NoEntriesFound
                message="Es wurden keine Projekte gefunden."
                :colspan="cols.length"
                v-if="projects.length == 0"
            />
            <tr v-else v-for="entry in projects" v-bind:key="'project-' + entry.id">
                <td v-for="col in cols" v-bind:key="'row' + col.name" v-show="col.active">
                    <div v-if="col.name == 'actions'" style="display: block; width: 130px;">
                        <b-button-group>
                            <b-dropdown variant="outline-primary" size="sm" no-caret dropdown>
                                <template v-slot:button-content>
                                    <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
                                </template>
                                <b-dropdown-item
                                    v-if="entry.linkasana"
                                    :href="entry.linkasana"
                                    target="_blank"
                                >
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'tasks']"
                                    />&nbsp;Projekt in Asana öffnen
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="entry.linkavanova"
                                    :href="entry.linkavanova"
                                    target="_blank"
                                >
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'list-ul']"
                                    />&nbsp;Projekt in Avanova öffnen
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="entry.linkbtb"
                                    :href="entry.linkbtb"
                                    target="_blank"
                                >
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'clipboard-list']"
                                    />&nbsp;Projekt im Bautagebuch öffnen
                                </b-dropdown-item>
                                <div class="dropdown-divider" v-if="hasLinks(entry)"></div>
                                <b-dropdown-item
                                    :to="{
                                        name: 'project.analysis',
                                        params: { id: entry.id }
                                    }"
                                >
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'chart-pie']"
                                    />&nbsp;Auswertung anzeigen
                                </b-dropdown-item>
                                <b-dropdown-item
                                    :to="{
                                        name: 'project.recordoverview',
                                        params: { id: entry.id }
                                    }"
                                >
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'clipboard']"
                                    />&nbsp;Projekteinträge anzeigen
                                </b-dropdown-item>
                                <b-dropdown-item
                                    :to="{
                                        name: 'project.warranties',
                                        params: { id: entry.id }
                                    }"
                                >
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'certificate']"
                                    />&nbsp;Gewährleistungen anzeigen
                                </b-dropdown-item>
                                <div class="dropdown-divider" v-if="entry.isdeletable"></div>
                                <b-dropdown-item
                                    v-if="entry.isdeletable"
                                    variant="danger"
                                    href="#"
                                    @click="showMsgBoxOne(entry.number, entry.name, entry.id)"
                                >
                                    <font-awesome-icon
                                        fixed-width
                                        :icon="['fas', 'trash-alt']"
                                    />&nbsp;Projekt löschen
                                </b-dropdown-item>
                            </b-dropdown>
                            <b-button
                                variant="primary"
                                size="sm"
                                :to="{
                                    name: 'project.detail',
                                    params: { id: entry.id }
                                }"
                            >
                                Details anzeigen
                            </b-button>
                        </b-button-group>
                    </div>
                    <span v-if="col.name == 'number'">
                        {{ entry.number }}
                    </span>
                    <span v-if="col.name == 'name'">
                        {{ entry.name }}
                    </span>
                    <span v-if="col.name == 'location'">
                        <span v-if="entry.locationcity">
                            <!--  <a
                                :href="
                                    $root.generateGoogleMapsLink(
                                        entry.locationcity,
                                        entry.locationstreet
                                    )
                                "
                                data-toggle="tooltip"
                                data-placement="top"
                                title="show in Google Maps"
                                target="_blank"
                            >
                                <font-awesome-icon fixed-width :icon="['fas', 'map-marked-alt']" />
                            </a>-->
                            <span v-if="entry.locationstreet">{{ entry.locationstreet }},</span>
                            {{ entry.locationcity }}
                        </span>
                    </span>
                    <span v-if="col.name == 'status'">
                        <b-badge variant="light">{{
                            $root.StatusFullTitle(entry.status_id)
                        }}</b-badge>
                        <p v-if="entry.inwarranty" class="small text-muted mt-1 mb-0">
                            <font-awesome-icon icon="exclamation-triangle" />&nbsp;noch in
                            Gewährleistung
                        </p>
                    </span>
                    <span v-if="col.name == 'documentlocation'">
                        <b-badge variant="light">{{
                            $root.DocumentlocationFullTitle(entry.documentlocation_id)
                        }}</b-badge>
                    </span>
                    <span v-if="col.name == 'startdate'"
                        >{{ entry.startdate | DateFullShort }}
                    </span>
                    <span v-if="col.name == 'enddate'">{{ entry.enddate | DateFullShort }} </span>
                    <span v-if="col.name == 'notes'">{{ entry.notes }} </span>
                    <span v-if="col.name == 'bsn'">
                        <span v-if="entry.services_ids.includes(11)">
                            <font-awesome-icon icon="fire-extinguisher" />&nbsp;enthält BSN
                        </span>
                    </span>
                    <span v-if="col.name == 'links'">
                        <span v-if="entry.linkasana != ''">
                            <a :href="entry.linkasana" target="_blank"
                                ><font-awesome-icon fixed-width :icon="['fas', 'tasks']"
                            /></a>
                        </span>
                        <span v-if="entry.linkavanova != ''">
                            <a :href="entry.linkavanova" target="_blank"
                                ><font-awesome-icon fixed-width :icon="['fas', 'list-ul']"
                            /></a>
                        </span>
                        <span v-if="entry.linkbtb != ''">
                            <a :href="entry.linkbtb" target="_blank"
                                ><font-awesome-icon fixed-width :icon="['fas', 'clipboard-list']"
                            /></a>
                        </span>
                    </span>

                    <span v-if="col.name == 'client'">
                        {{ entry.client }}
                        <p v-if="entry.client_contactperson" class="text-muted small mb-0">
                            {{ entry.client_contactperson }}
                        </p>
                    </span>
                    <span v-if="col.name == 'favorite'">
                        <b-tooltip
                            :target="'tooltip-favorite' + entry.id"
                            triggers="hover"
                            v-if="entry.isfavorite == 0"
                        >
                            Projekt zum Favoriten machen
                        </b-tooltip>
                        <b-tooltip :target="'tooltip-favorite' + entry.id" triggers="hover" v-else>
                            Projekt von Favoriten entfernen
                        </b-tooltip>
                        <b-button
                            variant="link"
                            class="float-right pl-0"
                            :id="'tooltip-favorite' + entry.id"
                            @click="toggleFavorite(entry)"
                        >
                            <font-awesome-icon :icon="starIcon(entry)"
                        /></b-button>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { projectService } from "../_services";
import { faStar as faStarR } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarS } from "@fortawesome/free-solid-svg-icons";

export default {
    props: {
        cols: Array,
        projects: Array
    },
    data() {
        return {
            faStarR,
            faStarS
        };
    },
    methods: {
        hasLinks(project) {
            return project.linkbtb || project.linkasana || project.linkasana;
        },
        showMsgBoxOne(number, name, id) {
            this.boxOne = "";
            this.$bvModal
                .msgBoxConfirm(
                    "Sind Sie sicher, dass Sie das Projekt " +
                        number +
                        ": " +
                        name +
                        "löschen möchten?",
                    { centered: true }
                )
                .then(value => {
                    if (value == true) {
                        this.onDelete(id);
                    }
                });
        },
        onDelete(id) {
            this.saving = true;
            projectService
                .deleteOne(id)
                .then(response => {
                    this.$store.commit("projects/integrateOne", {
                        id: id,
                        data: []
                    });
                    this.$root.toast("Das Projekt wurde erfolgreich gelöscht.", "success");
                }) //@TODO bug: error and success at the same time
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht gelöscht werden.", "danger");
                });
        },
        starIcon(projectinfo) {
            if (projectinfo.isfavorite == 0) {
                return this.faStarR;
            } else {
                return this.faStarS;
            }
        },
        toggleFavorite(projectinfo) {
            var data = { isfavorite: Math.abs(projectinfo.isfavorite - 1) };
            projectService
                .updateOne(projectinfo.id, data)
                .then(response => {
                    this.$store.commit("projects/integrateOne", {
                        id: projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");
                    this.$root.toast("Das Projekt wurde erfolgreich geändert.", "success");
                })
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht geändert werden.", "danger");
                });
        }
    }
};
</script>
