<template>
    <v-date-picker
        is-expanded
        is-inline
        mode="multiple"
        title-position="left"
        :rows="3"
        :columns="$screens({ default: 1, lg: 2 })"
        color="pink"
        :min-date="startdate"
        :max-date="enddate"
        :from-page="frompage"
        :value="vacationdays"
        :attributes="attributes"
        :disabled-dates="disabledDates"
        v-on:dayclick="onDayClick($event)"
    />
</template>

<script>
export default {
    props: {
        vacationdays: Array,
        publicholidays: Array,
        year: Number,
        employee_id: Number
    },

    data: function() {
        return {
            startdate: this.year + "-01-01",
            enddate: this.year + "-12-31",
            frompage: {
                month: 1,
                year: this.year
            },
            today: window.moment().format("YYYY-MM-DD")
        };
    },
    methods: {
        onDayClick: function(event) {
            if (this.userHasManagerRoleForEmployee) {
                this.$emit("vacationday-changed", event.id);
            } else {
                if (event.id >= this.today) {
                    this.$emit("vacationday-changed", event.id);
                }
            }
        }
    },
    computed: {
        userHasManagerRoleForEmployee() {
            return this.$root.userHasManagerRoleForEmployee(this.employee_id);
        },
        disabledDates() {
            if (this.userHasManagerRoleForEmployee) {
                return [{ weekdays: [1, 7] }];
            } else {
                return [
                    {
                        start: window.moment(this.year + "-01-01").toDate(),
                        end: window
                            .moment()
                            .subtract(1, "day")
                            .toDate()
                    },
                    { weekdays: [1, 7] }
                ];
            }
        },
        attributes() {
            return [
                ...this.publicholidays.map(phd => ({
                    dates: window.moment(phd.date).toDate(),
                    dot: {
                        color: "pink"
                    },
                    popover: {
                        label: (phd.fraction == 0.5 ? "halber " : "") + "Feiertag: " + phd.name
                    },
                    customData: phd
                }))
            ];
        }
    }
};
</script>
