<template>
    <tr>
        <td :colspan="colspan" class="text-center text-muted small py-3">
            <font-awesome-icon icon="times-circle" />&nbsp;{{ message }}
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        colspan: {
            type: Number,
            required: false,
            default: 1
        },
        message: {
            type: String,
            required: false,
            default: "Es wurden keine Einträge gefunden."
        }
    }
};
</script>
