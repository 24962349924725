<template>
    <b-row class="mt-5">
        <b-col cols="8" offset="2">
            <b-form-input
                ref="fastlaneinput"
                size="lg"
                v-model="fastlanestring"
                v-on:keyup.enter="submit"
                placeholder=""
            />
            <p class="text-super-muted mt-2">
                schneller eintragen mit z.B. <strong>"G300 von 11 bis 1.30: Bauleitung"</strong>
                &nbsp;
                <span class="float-right" v-if="submittable"><kbd>&#9166; enter</kbd> </span>
                <span class="float-right " v-else
                    ><kbd class="kbd-super-muted">&#9166; enter</kbd>
                </span>
            </p>

            <p v-if="fastlanestring" class="mt-2">
                <span class="mr-3 small text-success " v-if="entry.project">
                    <font-awesome-icon icon="check-circle" /> Projekt
                </span>
                <span class="mr-3 small text-danger" v-else>
                    <font-awesome-icon icon="times-circle" /> Projekt
                </span>
                <span class="mr-3 small text-success" v-if="entry.starttime">
                    <font-awesome-icon icon="check-circle" /> von
                </span>
                <span class="mr-3 small text-danger" v-else>
                    <font-awesome-icon icon="times-circle" /> von
                </span>
                <span class="mr-3 small text-success" v-if="entry.endtime">
                    <font-awesome-icon icon="check-circle" /> bis
                </span>
                <span class="mr-3 small text-danger" v-else>
                    <font-awesome-icon icon="times-circle" /> bis
                </span>
                <span class="mr-3 small text-success" v-if="entry.description">
                    <font-awesome-icon icon="check-circle" /> Beschreibung
                </span>
                <span class="mr-3 small text-danger" v-else>
                    <font-awesome-icon icon="times-circle" /> Beschreibung
                </span>
            </p>

            <b-alert>
                von: {{ entry.starttime }}
                <br />
                bis: {{ entry.endtime }}
                <br />
                Beschreibung: {{ entry.description }}
                <br />
                Projekt: {{ entry.project.name }}
            </b-alert>
        </b-col>
    </b-row>
</template>

<script>
import { trim } from "lodash";

export default {
    data() {
        return {
            fastlanestring: "",
            date: window.moment().format("YYYY-mm-dd"),
            entry: { project: "", starttime: "", endtime: "", description: "" },
            COMPTIME_PROJECTID: 79
        };
    },
    mounted() {
        this.calculate();
        this.focusSearchTerm();
    },
    computed: {
        projects() {
            return this.$store.state.projects.projects;
        },
        submittable() {
            if (
                this.entry.project != "" &&
                this.entry.starttime != "" &&
                this.entry.endtime != "" &&
                this.entry.description != ""
            ) {
                return true;
            }
            return null;
        }
    },
    methods: {
        calculate() {
            this.entry = { project: "", starttime: "", endtime: "", description: "" };
            var whereSomethingWasFound = [];

            //replace multiple spaces with single spaces
            this.fastlanestring = this.fastlanestring.replace(/ +(?= )/g, "");

            // search for Project Number
            var split_space = this.fastlanestring.split(" ");
            var split_trimmed = [];
            split_space.forEach(function(value) {
                split_trimmed.push(trim(value, ":"));
            });

            var split_lowercase = [];
            split_trimmed.forEach(function(value) {
                split_lowercase.push(value.toLowerCase());
            });

            var filtered = this.projects.items.filter(project =>
                split_lowercase.includes(project.number.toLowerCase())
            );
            if (filtered.length == 1) {
                this.entry.project = filtered[0];
                whereSomethingWasFound.push(
                    this.fastlanestring.indexOf(this.entry.project.number) +
                        this.entry.project.number.length
                );
            }

            // search for starttime which is right after "von"
            for (var i = 0; i < split_trimmed.length; i++) {
                if (split_trimmed[i] == "von" && i + 1 < split_trimmed.length) {
                    this.entry.starttime = this.parseTime(split_trimmed[i + 1]);
                    whereSomethingWasFound.push(
                        this.fastlanestring.indexOf(split_trimmed[i + 1]) +
                            split_trimmed[i + 1].length
                    );
                    break;
                }
            }
            // search for endtime which is right after "bis"
            for (var j = 0; j < split_trimmed.length; j++) {
                if (split_trimmed[j] == "bis" && j + 1 < split_trimmed.length) {
                    this.entry.endtime = this.parseTime(split_trimmed[j + 1]);
                    whereSomethingWasFound.push(
                        this.fastlanestring.indexOf("bis " + split_trimmed[j + 1]) +
                            ("bis " + split_trimmed[j + 1]).length
                    );
                    break;
                }
            }

            // startdate still empty? check right before bis
            if (this.entry.starttime == "") {
                for (var k = 0; k < split_trimmed.length; k++) {
                    if (split_trimmed[k] == "bis" && k - 1 >= 0) {
                        this.entry.starttime = this.parseTime(split_trimmed[k - 1]);
                        whereSomethingWasFound.push(
                            this.fastlanestring.indexOf(split_trimmed[k - 1]) +
                                split_trimmed[k - 1].length
                        );
                        break;
                    }
                }
            }

            // if negative: +12
            if (this.entry.starttime > this.entry.endtime) {
                this.entry.endtime = this.add12Hours(this.entry.endtime);
            }

            //if both too small: +12
            if (window.moment(this.entry.starttime, "HH:mm").hours() < 6) {
                this.entry.starttime = this.add12Hours(this.entry.starttime);
                this.entry.endtime = this.add12Hours(this.entry.endtime);
            }

            var description = this.fastlanestring.substring(
                Math.max(...whereSomethingWasFound),
                this.fastlanestring.length
            );
            description = trim(description, [":", " ", "-"]);
            this.entry.description = description;

            //check for Zeitausgleich
            if (this.entry.project.id == this.COMPTIME_PROJECTID) {
                this.entry.starttime = "00:00:00";
                this.entry.endtime = "00:00:00";
            }
        },
        parseTime(timestring) {
            if (timestring == "") {
                return "";
            }
            timestring = timestring.replace(".", ":");
            var ts = null;
            if (parseInt(timestring) + "" == timestring) {
                ts = window.moment(timestring, "HH");
            } else {
                ts = window.moment(timestring, "HH:mm");
            }
            if (ts.isValid()) {
                return ts.format("HH:mm:ss");
            } else {
                return "";
            }
        },
        add12Hours(timestring) {
            if (timestring == "") {
                return "";
            }
            var ts = window.moment(timestring, "HH:mm").add(12, "hours");
            if (ts.isValid()) {
                return ts.format("HH:mm:ss");
            } else {
                return "";
            }
        },
        submit() {
            if (this.submittable) {
                this.$emit("new-fastlane-entry", this.entry);
                this.fastlanestring = "";
            }
        },
        focusSearchTerm(e) {
            this.$refs.fastlaneinput.$el.focus();
        }
    },
    watch: {
        fastlanestring: function(newVal, oldVal) {
            this.calculate();
        }
    }
};
</script>
