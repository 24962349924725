<template>
    <span>
        <span :style="'color:' + employee.basecolor" v-if="dot">
            <font-awesome-icon icon="circle" />
        </span>
        <span v-if="initials"> {{ employee.initials }}</span>
        <span v-else> {{ employee.firstname }} {{ employee.lastname }} </span>
    </span>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        dot: {
            type: Boolean,
            required: false,
            default: true
        },
        initials: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        employee() {
            return this.$root.getEmployeeObject(this.id);
        }
    },
    data() {
        return {};
    }
};
</script>

<style></style>
