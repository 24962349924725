<template>
    <div id="sidebar-container" class="sidebar-expanded d-print-none">
        <ul class="list-group">
            <li class="list-group-item logo-separatormt-3">
                <router-link :to="{ name: 'home' }">
                    <img src="../../assets/logo-white.svg" width="140" height="40" class="mt-3" />
                </router-link>
            </li>

            <b-list-group-item :to="{ name: 'home' }" class=" welcome menu-collapsed-invisible">
                <div class="">
                    <span class="welcome-welcome"> Willkommen,</span>
                    <p class="welcome-username mb-0">{{ this.username }}</p>
                </div>
            </b-list-group-item>

            <b-list-group-item v-if="$root.userHasSelf()" :to="{ name: 'my.records' }" class="">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="calendar-check" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Meine Einträge</span>
                </div>
            </b-list-group-item>
            <b-list-group-item v-if="$root.userHasSelf()" :to="{ name: 'my.overview' }" href="#">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="folder-open" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Meine Übersicht</span>
                </div>
            </b-list-group-item>
            <b-list-group-item v-if="$root.userHasSelf()" :to="{ name: 'my.vacation' }">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="suitcase-rolling" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Mein Urlaub </span>
                </div>
            </b-list-group-item>

            <b-list-group-item
                v-if="$root.userHasAnyEmployees()"
                class=" sidebar-separator-title text-muted menu-collapsed-invisible"
            >
                <small>Mitarbeiter</small>
            </b-list-group-item>

            <b-list-group-item :to="{ name: 'employees.overview' }">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="users" fixed-width class="mr-3" />
                    <span class="menu-collapsed"> Mitarbeiter-Übersichten</span>
                </div>
            </b-list-group-item>
            <b-list-group-item
                :to="{
                    name: 'employees.vacation',
                    params: { year: year }
                }"
            >
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="suitcase-rolling" fixed-width class="mr-3" />
                    <span class="menu-collapsed"> Urlaubsplaner</span>
                </div>
            </b-list-group-item>
            <b-list-group-item
                v-if="$root.UserHasRight('canManagePublicholidays')"
                :to="{ name: 'employees.publicholidays' }"
            >
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="glass-cheers" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Feiertagsplaner </span>
                </div>
            </b-list-group-item>

            <b-list-group-item class=" sidebar-separator-title text-muted menu-collapsed-invisible">
                <small>Projekte</small>
            </b-list-group-item>
            <b-list-group-item :to="{ name: 'projects.index' }" class=" list-group-item ">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="building" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Projektliste</span>
                </div>
            </b-list-group-item>
            <b-list-group-item v-b-toggle.collapse-2 class=" flex-column align-items-start">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="building" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Projekt-Übersichten</span>
                    <font-awesome-icon icon="caret-down" fixed-width class="" />
                </div>
            </b-list-group-item>
            <b-collapse id="collapse-2" class="sidebar-submenu">
                <b-list-group-item :to="{ name: 'projects.statuses' }">
                    <span class="menu-collapsed">nach Projektstatus</span>
                </b-list-group-item>
                <b-list-group-item :to="{ name: 'projects.documentlocations' }">
                    <span class="menu-collapsed">nach Unterlagenstandort</span>
                </b-list-group-item>
            </b-collapse>
            <b-list-group-item :to="{ name: 'warranties.overview' }" class=" list-group-item ">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="building" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Gewährleistungsfristen</span>
                </div>
            </b-list-group-item>
            <b-list-group-item :to="{ name: 'projectteams' }" class=" list-group-item ">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="users" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Projekt-Teams</span>
                </div>
            </b-list-group-item>
            <!-- <b-list-group-item
                :to="{ name: 'driverslog' }"
                class=" list-group-item "
                v-if="$root.UserHasRight('canManageDriverslogs')"
            >
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="car" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Fahrtenbuch</span>
                </div>
            </b-list-group-item> -->

            <li class="list-group-item sidebar-separator menu-collapsed-invisible"></li>
            <b-list-group-item
                :to="{ name: 'users.overview' }"
                v-if="$root.UserHasRight('canManageAllEmployees')"
                class=""
            >
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="user-shield" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Benutzerverwaltung</span>
                </div>
            </b-list-group-item>
            <b-list-group-item :to="{ name: 'my.profile' }" class="">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="address-card" fixed-width class="mr-3" />
                    <span class="menu-collapsed">Profil</span>
                </div>
            </b-list-group-item>

            <b-list-group-item :to="{ name: 'login' }" class="">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <font-awesome-icon icon="sign-out-alt" fixed-width class="mr-3" />
                    <span class="menu-collapsed">abmelden</span>
                </div>
            </b-list-group-item>
        </ul>
    </div>
</template>

<script>
export default {
    computed: {
        user() {
            return this.$store.state.employees.user.data;
        },
        username() {
            if (this.user !== undefined) {
                return this.user.name;
            } else {
                return "";
            }
        },
        year() {
            return window.moment().format("YYYY");
        }
    }
};
</script>
