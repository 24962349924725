<template>
    <span v-if="editable">
        <b-card class="shadow mb-5" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right">
                    <b-button variant="outline-secondary" size="sm" @click="cancelEditing()">
                        <font-awesome-icon icon="times" />&nbsp;abbrechen
                    </b-button>
                    <b-button variant="outline-primary" size="sm" @click="saveData()">
                        <font-awesome-icon icon="save" />&nbsp;speichern
                    </b-button>
                </b-button-group>
            </h5>

            <Loading v-if="!loaded" />
            <b-form-group v-else>
                <b-form-radio
                    v-for="element in statuses.items"
                    v-bind:key="element.id"
                    name="statuses"
                    :value="element.id"
                    v-model="selectedStatus"
                    class="pb-2"
                >
                    {{ element.name }}
                    <br />
                    <small>{{ element.description }}</small>
                </b-form-radio>
            </b-form-group>

            <b-form-checkbox
                class="mt-5"
                id="inwarranty"
                v-model="localinwarranty"
                name="inwarranty"
                >Dieses Projekt befindet sich noch in der Gewährleistung.</b-form-checkbox
            >
        </b-card>
    </span>
    <span v-else>
        <b-card class="card-transparent mb-5 hover-field" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right show-on-hover">
                    <b-button
                        variant="link"
                        size="sm"
                        class="btn-sm-without-padding"
                        @click="startEditing()"
                    >
                        <font-awesome-icon icon="edit" />&nbsp;bearbeiten
                    </b-button>
                </b-button-group>
            </h5>

            <span v-if="projectinfo.status.length > 0">
                {{ projectinfo.status[0].name }}
                <br />
                <small>{{ projectinfo.status[0].description }}</small>
            </span>
            <span v-else class="text-muted small">Keine Status-Information hinterlegt.</span>

            <span v-if="projectinfo.inwarranty">
                <b-alert variant="info" show class="mt-3">
                    <font-awesome-icon icon="exclamation-triangle" />&nbsp;Dieses Projekt befindet
                    sich noch in der Gewährleistung.
                </b-alert>
            </span>
        </b-card>
    </span>
</template>

<script>
import { projectService } from "../../_services";

export default {
    props: {
        projectinfo: Object
    },
    components: {},
    data: function() {
        return {
            loaded: true,
            editable: false,
            title: "Status",
            icon: "toggle-on",
            selectedStatus: this.projectinfo.status_id,
            localinwarranty: this.projectinfo.inwarranty == 1 ? true : false
        };
    },
    methods: {
        cancelEditing() {
            this.editable = false;
            this.selectedStatus = this.projectinfo.status_id;
            this.localinwarranty = this.projectinfo.inwarranty == 1 ? true : false;
        },
        saveData() {
            var data = {
                status_id: this.selectedStatus,
                inwarranty: this.localinwarranty == true ? 1 : 0
            };
            projectService
                .updateOne(this.projectinfo.id, data)
                .then(response => {
                    this.editable = false;
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");
                    this.$root.toast("Das Projekt wurde erfolgreich geändert.", "success");
                })
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht geändert werden.", "danger");
                });
        },
        startEditing() {
            this.editable = true;
        }
    },
    computed: {
        statuses() {
            return this.$store.state.projects.statuses;
        }
    }
};
</script>
