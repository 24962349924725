<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>
                Mitarbeiter- und Benutzerverwaltung
                <br />
                <span class="font-weight-bold"> Übersicht</span>
            </h1>
        </template>

        <template v-slot:page-sub-menu>
            <UsersSubMenu active="users-overview" />
        </template>

        <Loading v-if="loading" />

        <div class="" v-else>
            <!-- <b-row class="mt-5 mb-5">
                <b-col>
                    <b-form-checkbox v-model="showemployees" inline>
                        zugeordnete Mitarbeiter anzeigen
                    </b-form-checkbox>
                    <b-form-checkbox v-model="showrights" inline>
                        zugeordnete Rechte anzeigen
                    </b-form-checkbox>
                </b-col>
            </b-row> -->
            <table class="table table-with-bottom-line">
                <tbody>
                    <tr v-for="user in allusers" v-bind:key="user.id">
                        <td style="width:5%">
                            <router-link
                                type="button"
                                class="btn btn-link btn-sm"
                                :to="{
                                    name: 'user.detail',
                                    params: { id: user.id }
                                }"
                            >
                                <font-awesome-icon icon="edit" />
                            </router-link>
                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                @click="deleteUser(user.id)"
                            >
                                <font-awesome-icon icon="trash-alt" />
                            </button>
                        </td>
                        <td style="width:25%">
                            {{ user.name }}
                            <p class="small text-muted">{{ user.email }}</p>
                        </td>
                        <td style="width:35%" v-if="showemployees">
                            <AssignedEmployee
                                v-for="employee_id in user.employee_ids"
                                v-bind:key="user.id + '_' + employee_id.employee_id"
                                :employee_id="employee_id.employee_id"
                                :user_id="user.id"
                            />
                        </td>
                        <td style="width:35%" v-if="showrights">
                            <p v-for="right_id in user.right_ids" v-bind:key="right_id">
                                <RightName :id="right_id" />
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </base-layout>
</template>
<script>
import UsersSubMenu from "../components/SubMenus/UsersSubMenu.vue";
import AssignedEmployee from "../components/AssignedEmployee.vue";
import { userService } from "../_services";

export default {
    components: {
        UsersSubMenu,
        AssignedEmployee
    },
    created() {},
    data() {
        return {
            showemployees: true,
            showrights: true
        };
    },
    methods: {
        deleteUser(id) {
            this.$store.commit("employees/UsersLoading");
            userService
                .deleteOne(id)
                .then(response => {
                    this.$store.dispatch("employees/getAll");
                    this.$root.toast("Der Benutzer wurde erfolgreich gelöscht.", "success");
                })
                .catch(error => {
                    this.$root.toast("Der Benutzer konnte nicht gelöscht werden.", "danger");
                });
        }
    },
    computed: {
        allusers() {
            return this.$store.state.employees.users.items;
        },
        loading() {
            return this.$store.state.employees.users.loading;
        }
    }
};
</script>
