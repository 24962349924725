<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <EmployeeSelector v-model="employee_id"></EmployeeSelector>
            <h1>Meine Übersicht: <EmployeeName :id="employee_id" :dot="false" /></h1>
        </template>

        <template v-slot:page-sub-menu>
            <EmployeeDetailSubMenu active="employee-detail" :employee_id="employee_id" />
        </template>

        <div>
            <LoadingBox cols="6" number="2" v-if="!allLoaded" />
            <b-row class="mb-4" v-else>
                <b-col cols="6" class="mb-5">
                    <EmployeeInfobox :showlinks="false" :data="employeedata" />
                </b-col>
                <b-col>
                    <div class="card shadow">
                        <div class="card-header">
                            <b-button
                                variant="outline-primary"
                                class="float-right"
                                size="sm"
                                :to="{
                                    name: 'my.vacation.withyearandid',
                                    params: { id: employee_id, year: year }
                                }"
                            >
                                <font-awesome-icon icon="plus" />&nbsp;Urlaub eintragen
                            </b-button>
                            Übersicht Urlaubstage
                        </div>
                        <VacationOverview :employeevacationdays="employeevacationdays" />
                    </div>
                </b-col>
            </b-row>

            <LoadingBox v-if="!allLoaded" />
            <div class="card shadow mb-4" v-else>
                <div class="card-header">
                    <span v-if="userHasManagerRoleForEmployee">
                        <span v-if="editmode">
                            <b-button-group class="float-right">
                                <b-button
                                    variant="outline-secondary"
                                    size="sm"
                                    @click="exitEditMode()"
                                >
                                    <font-awesome-icon icon="times" />&nbsp;abbrechen
                                </b-button>
                                <b-button
                                    variant="outline-primary"
                                    size="sm"
                                    @click="saveMasterData()"
                                >
                                    <font-awesome-icon icon="save" />&nbsp;speichern
                                </b-button>
                            </b-button-group>
                        </span>
                        <span v-else>
                            <b-button
                                variant="outline-primary"
                                class="float-right"
                                size="sm"
                                @click="enterEditMode()"
                            >
                                <font-awesome-icon icon="edit" />&nbsp;Stammdaten bearbeiten
                            </b-button>
                        </span>
                    </span>
                    Stammdaten
                </div>
                <div class="card-body">
                    <EmployeeMasterData :employeedata="employeedata" :editmode="editmode" />
                </div>
            </div>
        </div>
    </base-layout>
</template>
<script>
import EmployeeDetailSubMenu from "../../components/SubMenus/EmployeeDetailSubMenu.vue";
import { employeeService } from "../../_services";
import VacationOverview from "../../components/VacationOverview";
import EmployeeSelector from "../../components/EmployeeSelector.vue";
import EmployeeMasterData from "../../components/EmployeeMasterData.vue";
import EmployeeInfobox from "../../components/EmployeeInfobox.vue";

export default {
    data() {
        return {
            editmode: false,
            loading_records: true,
            loading_vacationdays: true,
            employee_id: parseInt(this.$route.params.id),
            employeedata: {},
            employeevacationdays: {},
            year: parseInt(window.moment().format("YYYY"))
        };
    },
    computed: {
        userHasManagerRoleForEmployee() {
            return this.$root.userHasManagerRoleForEmployee(this.employee_id);
        },
        allLoaded() {
            return !this.loading_records && !this.loading_vacationdays;
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading_records = true;
            this.loading_vacationdays = true;
            employeeService.getRecordOverviewPerEmployee(this.$route.params.id).then(response => {
                this.loading_records = false;
                this.employeedata = response.data;
            });
            employeeService.getVacationdaysPerEmployee(this.$route.params.id).then(response => {
                this.loading_vacationdays = false;
                this.employeevacationdays = response.data;
            });
        },
        enterEditMode() {
            this.editmode = true;
        },
        exitEditMode() {
            this.fetchData();
            this.editmode = false;
        },
        saveMasterData() {
            this.editmode = false;
            employeeService
                .updateOne(this.employee_id, this.employeedata.employee)
                .then(response => {
                    this.$store.dispatch("employees/getAll"); //@TODO: implement into delete/update/create service methods directly?
                    this.fetchData();
                    this.$root.toast(
                        "Alle Arbeitszeitmodelle wurden erfolgreich gespeichert.",
                        "success"
                    );
                    this.employeedata.employee = response.data;
                })
                .catch(err => {
                    this.$root.toast(
                        "Nicht alle Änderungen konnten übernommen werden. Bitte überprüfen Sie Ihre Eingaben.",
                        "danger"
                    );
                });
        }
    },
    components: {
        EmployeeDetailSubMenu,
        VacationOverview,
        EmployeeSelector,
        EmployeeMasterData,
        EmployeeInfobox
    },
    watch: {
        employee_id: function(newVal, oldVal) {
            this.$router.push({
                name: "employee.detail",
                params: { id: newVal }
            });
            this.fetchData();
        }
    }
};
</script>
