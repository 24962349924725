<template>
    <span>
        <!-- <font-awesome-icon icon="building" fixed-width v-if="icon" /> -->
        {{ right.name }}
        <br /><span class="small text-muted"> {{ right.description }}</span>
    </span>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        icon: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        right() {
            return this.$root.getRightObject(this.id);
        }
    },
    data() {
        return {};
    }
};
</script>

<style></style>
