<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <EmployeeSelector
                title="Arbeitszeiten eintragen für"
                v-model="employee_id"
            ></EmployeeSelector>

            <h1>Arbeitszeiten eintragen</h1>
        </template>

        <RpepdDaySelection :date="date" v-on:date-selected="date = $event"></RpepdDaySelection>
        <RpepdRecordTable :date="date" :employee_id="employee_id" class="mt-5"></RpepdRecordTable>
    </base-layout>
</template>
<script>
import RpepdDaySelection from "../components/RecordsPerEmployeePerDay/RpepdDaySelection.vue";
import EmployeeSelector from "../components/EmployeeSelector.vue";
import RpepdRecordTable from "../components/RecordsPerEmployeePerDay/RpepdRecordTable.vue";

export default {
    components: {
        RpepdDaySelection,
        EmployeeSelector,
        RpepdRecordTable
    },
    data() {
        return {
            employee_id:
                this.$route.params.id === undefined
                    ? this.$root.selfEmployeeId
                    : parseInt(this.$route.params.id),
            date:
                this.$route.params.date === undefined
                    ? window.moment().format("YYYY-MM-DD")
                    : this.$route.params.date
        };
    },
    created() {
        if (this.$route.params.date === undefined && this.$route.params.id === undefined) {
            this.$router.push({
                name: "my.records.withdateandid",
                params: {
                    date: window.moment().format("YYYY-MM-DD"),
                    id: this.$root.selfEmployeeId
                }
            });
        }
        if (this.$route.params.date === undefined) {
            this.$router.push({
                name: "my.records.withdateandid",
                params: {
                    date: window.moment().format("YYYY-MM-DD"),
                    id: parseInt(this.$route.params.id)
                }
            });
        }
        if (this.$route.params.id === undefined) {
            this.$router.push({
                name: "my.records.withdateandid",
                params: {
                    date: this.$route.params.date,
                    id: this.$root.selfEmployeeId
                }
            });
        }
    }
};
</script>
