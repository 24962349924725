<template>
    <span v-if="editable">
        <b-card class="shadow mb-5" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right">
                    <b-button variant="outline-secondary" size="sm" @click="cancelEditing()">
                        <font-awesome-icon icon="times" />&nbsp;abbrechen
                    </b-button>
                    <b-button variant="outline-primary" size="sm" @click="saveData()">
                        <font-awesome-icon icon="save" />&nbsp;speichern
                    </b-button>
                </b-button-group>
            </h5>

            <Loading v-if="!loaded" />

            <b-form-checkbox-group
                stacked
                id="projectemployees"
                v-model="selected_employees"
                :options="possible_employees"
                name="projectemployees"
            ></b-form-checkbox-group>
        </b-card>
    </span>
    <span v-else>
        <b-card class="card-transparent mb-5 hover-field" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right show-on-hover">
                    <b-button
                        variant="link"
                        size="sm"
                        class="btn-sm-without-padding"
                        @click="startEditing()"
                    >
                        <font-awesome-icon icon="edit" />&nbsp;bearbeiten
                    </b-button>
                </b-button-group>
            </h5>

            <div v-if="projectinfo.projectemployees.length > 0">
                <div
                    v-for="projectemployee in projectinfo.projectemployees"
                    v-bind:key="projectemployee.id"
                    class="mb-3"
                >
                    <EmployeeName :id="projectemployee.employee_id" :dot="false" />
                    <!-- <div class="small text-muted">
                        {{ $root.ProjectroleFullTitle(projectemployee.projectrole_id) }}
                    </div> -->
                </div>
            </div>
            <span v-else class="text-muted small">Keine Team-Mitglieder hinterlegt.</span>
        </b-card>
    </span>
</template>
<script>
import { projectService } from "../../_services";
import { mapGetters } from "vuex";

export default {
    props: {
        projectinfo: Object
    },
    components: {},
    data: function() {
        return {
            loaded: true,
            editable: false,
            title: "Team-Mitglieder",
            icon: "users",
            selected_employees: []
        };
    },
    methods: {
        cancelEditing() {
            this.editable = false;
            this.setSelectedEmployeesVar();
        },
        saveData() {
            var data = { projectemployees: this.selected_employees };
            projectService
                .updateOne(this.projectinfo.id, data)
                .then(response => {
                    this.editable = false;
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");
                    this.$root.toast("Das Projekt wurde erfolgreich geändert.", "success");
                })
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht geändert werden.", "danger");
                });
        },
        startEditing() {
            this.editable = true;
            this.setSelectedEmployeesVar();
        },
        setSelectedEmployeesVar() {
            var ret = [];
            for (var k = 0; k < this.projectinfo.projectemployees.length; k++) {
                ret.push(this.projectinfo.projectemployees[k].employee_id);
            }
            this.selected_employees = ret;
        }
    },
    created() {
        this.setSelectedEmployeesVar();
    },
    computed: {
        ...mapGetters({
            employeesActive: "employees/employeesActive"
        }),
        possible_employees() {
            var ret = [];
            for (var k = 0; k < this.employeesActive.length; k++) {
                ret.push({
                    value: this.employeesActive[k].id,
                    text: this.$root.EmployeeFullName(this.employeesActive[k].id)
                });
            }
            return ret;
        }
    }
};
</script>
