<template>
    <base-layout>
        <template v-slot:header-title>
            <h1>
                {{ period | PeriodSmall }}
                <b-button
                    size="lg"
                    variant="link"
                    class="d-print-none"
                    v-on:click="changeMonth(-1)"
                >
                    <span class="zweige-rpepd-left ml-2">
                        <font-awesome-icon icon="chevron-left" />
                    </span>
                </b-button>
                <b-button
                    size="lg"
                    variant="link"
                    class="d-print-none"
                    v-on:click="changeMonth(+1)"
                >
                    <span class="zweige-rpepd-right mr-2">
                        <font-awesome-icon icon="chevron-right" />
                    </span>
                </b-button>
                <br />
                <span class="font-weight-bold"> <EmployeeName :id="employeeid" :dot="false"/></span>
            </h1>
        </template>

        <template v-slot:options>
            <b-form-checkbox v-model="showtimes" :value="true" :unchecked-value="false">
                Start- und End-Zeiten für alle Projektbuchungen anzeigen
            </b-form-checkbox>
        </template>

        <Loading v-if="loading || projects.items === undefined" />

        <div v-else>
            <div class="card">
                <table class="table table-without-top-line mb-0">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 13%">
                                Datum
                            </th>
                            <th scope="col" style="width: 7%">Σ</th>
                            <th scope="col" style="width: 7%" class="text-right">
                                <font-awesome-icon icon="stopwatch" />
                            </th>
                            <th scope="col" style="width: 38%">
                                Projekt
                            </th>
                            <th scope="col" style="width: 35%">
                                Bemerkung
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="numberOfEntries == 0">
                            <td colspan="5" class="text-center pt-5 pb-5">
                                <font-awesome-icon icon="info-circle" />&nbsp; Für dieses Monat
                                wurde noch nichts eingetragen.
                            </td>
                        </tr>
                        <TimesheetDay
                            v-for="day in days"
                            v-bind:key="day"
                            :timesheetdata="employeetimesheet"
                            :date="day"
                            :showtimes="showtimes"
                        />
                    </tbody>
                </table>
            </div>

            <b-row>
                <b-col cols="8" class="mt-5">
                    <div class="card">
                        <TimesheetProjectOverview
                            :timesheetdata="employeetimesheet"
                            :period="period"
                            v-on:customquota-changed="fetchData()"
                        />
                    </div>
                </b-col>
            </b-row>
        </div>
    </base-layout>
</template>
<script>
import { employeeService } from "../_services";
import TimesheetDay from "../components/TimesheetDay.vue";
import TimesheetProjectOverview from "../components/TimesheetProjectOverview.vue";

export default {
    data() {
        return {
            loading: false,
            period: this.$route.params.period,
            employeeid: parseInt(this.$route.params.id),
            employeetimesheet: null,
            showtimes: false
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        numberOfEntries() {
            var ret =
                this.employeetimesheet.records.length +
                this.employeetimesheet.sickdays.length +
                this.employeetimesheet.vacationdays.length +
                this.employeetimesheet.publicholidays.length;
            return ret;
        },
        days: function() {
            let day = window.moment(this.period).startOf("month");
            let dayE = window.moment(this.period).endOf("month");
            let listOfDays = [];
            while (day < dayE) {
                listOfDays.push(day.format("YYYY-MM-DD"));
                day.add(1, "day");
            }
            return listOfDays;
        },
        projects() {
            return this.$store.state.projects.projects;
        }
    },
    components: {
        TimesheetDay,
        TimesheetProjectOverview
    },
    methods: {
        fetchData() {
            this.loading = true;
            employeeService
                .getTimeSheetPerEmployeePerPeriod(this.employeeid, this.period)
                .then(response => {
                    this.loading = false;
                    this.employeetimesheet = response.data;
                });
        },
        changeMonth(val) {
            this.$router.push({
                name: "employees.timesheet",
                params: {
                    id: this.employeeid,
                    period: window
                        .moment(this.period, "YYYY-MM")
                        .add(val, "month")
                        .format("YYYY-MM")
                }
            });
            this.period = this.$route.params.period;
            this.fetchData();
        }
    }
};
</script>
