<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1 class="mt-2">
                Projektbuchungen
                <br />
                <span class="font-weight-bold">
                    <ProjectName :id="project_id" :icon="false" />
                </span>
            </h1>
        </template>

        <template v-slot:page-sub-menu>
            <ProjectSubMenu active="projects-recordoverview" :project_id="project_id" />
        </template>

        <template v-slot:options>
            <div v-if="!loading && projectrecords.records.length > 0">
                <div class="alert alert-info" v-if="!$root.UserHasRight('canManageProjects')">
                    Es werden nur Projektbuchungen von denjenigen Mitarbeitern angezeigt, die dem
                    aktuellen Benutzer zugewiesen sind.
                </div>

                <b-row class="my-3">
                    <b-col cols="2"
                        >nach Mitarbeitern filtern
                        <b-button
                            size="sm"
                            variant="link"
                            class="p-0"
                            @click="selectAllEmployees()"
                        >
                            alle auswählen
                        </b-button>
                        <br />
                        <b-button size="sm" variant="link" class="p-0" @click="selectNoEmployees()">
                            keinen auswählen
                        </b-button>
                    </b-col>
                    <b-col>
                        <b-form-checkbox-group
                            id="checkbox-group-1"
                            v-model="employee_selected"
                            :options="employee_options"
                        ></b-form-checkbox-group>
                    </b-col>
                </b-row>
                <b-row class="my-3">
                    <b-col cols="2" class="pt-2">
                        nach Datum filtern
                    </b-col>
                    <b-col>
                        <DatePicker v-model="startdate" />
                    </b-col>
                    <b-col class="text-center pt-2" cols="1"> bis </b-col>
                    <b-col>
                        <DatePicker v-model="enddate" />
                    </b-col>
                </b-row>
                <b-row class="my-3">
                    <b-col cols="2">
                        in Beschreibungstext suchen
                    </b-col>
                    <b-col>
                        <b-form-input
                            v-model="searchterm"
                            placeholder="Suchbegriff eingeben"
                        ></b-form-input>
                    </b-col>
                </b-row>
            </div>
        </template>

        <Loading v-if="loading" />

        <div class="card" v-else>
            <div class="card-body">
                <b-alert variant="info" show>
                    Gesamtsumme aller angezeigten Stundeneinträge:
                    <strong>{{ sumOfAllEntries | NumberTwoDigits }} h </strong>
                </b-alert>
            </div>
            <table class="table table-without-top-line mb-0">
                <thead>
                    <tr>
                        <th style="width:20%">Datum</th>
                        <th style="width:20%">Mitarbeiter</th>
                        <th style="width:15%">Stundenzahl</th>
                        <th style="width:45%">Bemerkung</th>
                    </tr>
                </thead>
                <tbody>
                    <NoEntriesFound :colspan="4" v-if="records_filtered.length == 0" />

                    <tr v-for="record in records_filtered" v-bind:key="record.id">
                        <td>{{ record.date | DateLong }}</td>
                        <td><EmployeeName :id="record.employee_id" :dot="false" /></td>
                        <td>
                            {{
                                $root.getDuration(record.starttime, record.endtime)
                                    | NumberTwoDigits
                            }}
                        </td>
                        <td>{{ record.description }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </base-layout>
</template>
<script>
import { projectService } from "../../_services";
import ProjectSubMenu from "../../components/SubMenus/ProjectSubMenu.vue";
import DatePicker from "../../components/Global/DatePicker.vue";
import { sortBy, uniq, minBy, maxBy } from "lodash";

export default {
    components: {
        ProjectSubMenu,
        DatePicker
    },
    data() {
        return {
            project_id: parseInt(this.$route.params.id),
            loading: false,
            projectrecords: null,
            employee_selected: [],
            employee_options: [],
            startdate: null,
            enddate: null,
            searchterm: ""
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.projectrecords = null;
            this.loading = true;
            projectService.getAllRecords(this.$route.params.id).then(response => {
                this.loading = false;
                this.projectrecords = response.data;
                this.startdate = minBy(this.projectrecords.records, "date").date;
                this.enddate = maxBy(this.projectrecords.records, "date").date;
            });
        },
        selectAllEmployees() {
            this.employee_selected = this.employee_ids;
        },
        selectNoEmployees() {
            this.employee_selected = [];
        }
    },
    computed: {
        cleanedSearchterm() {
            let st = this.searchterm;
            return st.toLowerCase();
        },
        sumOfAllEntries() {
            var ret = 0;
            for (var i = 0; i < this.records_filtered.length; i++) {
                ret += this.$root.getDuration(
                    this.records_filtered[i].starttime,
                    this.records_filtered[i].endtime
                );
            }
            return ret;
        },
        employee_ids() {
            if (this.projectrecords != undefined) {
                var ret = [];
                for (var i = 0; i < this.projectrecords.records.length; i++) {
                    ret.push(this.projectrecords.records[i].employee_id);
                }
                return sortBy(uniq(ret));
            }
            return [];
        },
        records_filtered() {
            return this.projectrecords.records.filter(
                element =>
                    element.date >= this.startdate &&
                    element.date <= this.enddate &&
                    this.employee_selected.includes(element.employee_id) &&
                    element.description.toLowerCase().indexOf(this.cleanedSearchterm) >= 0
            );
        }
    },
    watch: {
        employee_ids() {
            if (this.projectrecords != undefined) {
                this.employee_selected = this.employee_ids;
                var ret = [];
                for (var i = 0; i < this.employee_ids.length; i++) {
                    ret.push({
                        value: this.employee_ids[i],
                        text: this.$root.EmployeeFullName(this.employee_ids[i])
                    });
                }
                this.employee_options = ret;
            }
        }
    }
};
</script>
