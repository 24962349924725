<template>
    <div>
        <Loading v-if="loading" />

        <b-form v-else @submit="onSubmit">
            <b-form-group id="input-group-0" label="Vor- und Nachname" label-for="input-0">
                <b-form-input id="input-0" v-model="form.name" type="text" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1" label="E-Mail-Adresse" label-for="input-1">
                <b-form-input
                    id="input-1"
                    v-model="form.email"
                    type="email"
                    required
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-2"
                label="Passwort"
                label-for="input-2"
                v-if="user_id == 0"
            >
                <b-form-input
                    id="input-2"
                    v-model="form.password"
                    type="password"
                    required
                ></b-form-input>
            </b-form-group>
            <ChangePasswordModal v-else :userinfo="userdata" />

            <table class="table table-with-bottom-line mt-5 pt-5">
                <tbody>
                    <tr>
                        <td colspan="2">
                            Hauptnutzer
                            <p class="small text-muted">
                                Der Nutzer entspricht diesem Mitarbeiter.
                            </p>
                        </td>
                        <td class="small text-muted" style="width: 20%">
                            Der Nutzer kann von diesem Mitarbeiter keine Daten sehen.
                        </td>
                        <td class="small text-muted" style="width: 20%">
                            Der Nutzer kann für diesen Mitarbeiter Stunden eintragen, jedoch keine
                            administrativen Einstellungen vornehmen.
                        </td>
                        <td class="small text-muted" style="width: 20%">
                            Der Nutzer kann für diesen Mitarbeiter alle Einstellungen vornehmen.
                        </td>
                    </tr>
                    <tr v-for="employee in form.employees" v-bind:key="employee.id">
                        <td>
                            <b-form-checkbox
                                v-model="employee.self"
                                name="check-button"
                                switch
                                @change="checkEmployeeSelfValues(employee, $event)"
                            >
                            </b-form-checkbox>
                        </td>

                        <td>
                            {{ $root.EmployeeFullName(employee.id) }}
                        </td>
                        <td>
                            <b-form-radio v-model="employee.role" value="none">
                                keine Rolle
                            </b-form-radio>
                        </td>
                        <td>
                            <b-form-radio v-model="employee.role" value="managed">
                                Standard
                            </b-form-radio>
                        </td>
                        <td>
                            <b-form-radio v-model="employee.role" value="manager">
                                Manager
                            </b-form-radio>
                        </td>
                    </tr>
                </tbody>
            </table>

            <b-form-group
                class="pt-5"
                id="input-group-3"
                label="zugeordnete Rechte"
                label-for="input-4"
            >
                <b-form-checkbox-group
                    id="input-4"
                    v-model="form.assignedrights"
                    :options="form.possiblerights"
                    name="input-4"
                    stacked
                ></b-form-checkbox-group>
            </b-form-group>

            <b-button type="submit" variant="primary" v-if="user_id == 0"
                >neuen Benutzer anlegen</b-button
            >
            <b-button type="submit" variant="primary" v-else> Benutzer aktualisieren</b-button>
        </b-form>
    </div>
</template>

<script>
import { filter } from "lodash";
import { userService } from "../_services";
import ChangePasswordModal from "./ChangePasswordModal.vue";

export default {
    props: {
        user_id: { type: Number, required: false, default: 0 }
    },
    data() {
        return {
            form: {
                name: "",
                password: "",
                email: "",
                employees: [],
                possiblerights: [],
                assignedrights: []
            },
            loading: false,
            userdata: {}
        };
    },
    methods: {
        fetchData() {
            this.loading = true;
            userService.findOne(this.user_id).then(response => {
                this.userdata = response.data;
                this.presetValues();
            });
        },
        checkEmployeeSelfValues(employee, event) {
            if (employee.role == "none") {
                employee.role = "managed";
            }
            for (var i = 0; i < this.form.employees.length; i++) {
                this.form.employees[i].self = false;
            }
            this.form.employees.find(e => e.id == employee.id).self = true;
        },
        onSubmit(evt) {
            evt.preventDefault();
            this.loading = true;
            if (this.user_id == 0) {
                userService
                    .register(this.form)
                    .then(response => {
                        this.loading = false;
                        this.$store.dispatch("employees/getAll");
                        this.$root.toast("Der Benutzer wurde erfolgreich erstellt.", "success");
                        this.$router.push({
                            name: "users.overview"
                        });
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$root.toast("Der Benutzer konnte nicht erstellt werden.", "danger");
                    });
            } else {
                userService
                    .updateOne(this.user_id, this.form)
                    .then(response => {
                        this.loading = false;
                        this.$store.dispatch("employees/getAll");
                        this.$root.toast("Der Benutzer wurde erfolgreich geändert.", "success");
                        this.$router.push({
                            name: "users.overview"
                        });
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$root.toast("Der Benutzer konnte nicht geändert werden.", "danger");
                    });
            }
        },
        presetValues() {
            this.form.name = this.userdata.name;
            this.form.email = this.userdata.email;
            for (var i = 0; i < this.form.employees.length; i++) {
                var entryInLoaded = filter(this.userdata.employee_ids, {
                    employee_id: this.form.employees[i].id
                });
                if (entryInLoaded.length > 0 && entryInLoaded[0].employee_id > 0) {
                    this.form.employees[i].id = entryInLoaded[0].employee_id;
                    this.form.employees[i].role = entryInLoaded[0].role;
                    this.form.employees[i].self = entryInLoaded[0].self == 1 ? true : false;
                }
            }
            for (var j = 0; j < this.userdata.right_ids.length; j++) {
                this.form.assignedrights[j] = this.userdata.right_ids[j];
            }
            for (var k = 0; k < this.allrights.length; k++) {
                this.form.possiblerights[k] = {
                    value: this.allrights[k].id,
                    text: this.allrights[k].name + ": " + this.allrights[k].description
                };
            }
            this.loading = false;
        }
    },
    computed: {
        allemployees() {
            return this.$store.state.employees.employees.items;
        },
        allrights() {
            return this.$store.state.employees.rights.items;
        }
    },
    created() {
        if (this.user_id > 0) {
            this.fetchData();
        }
        for (let i = 0; i < this.allemployees.length; i++) {
            this.form.employees.push({
                id: this.allemployees[i].id,
                initials: this.allemployees[i].initials,
                role: "none",
                self: false
            });
        }
        for (let i = 0; i < this.allrights.length; i++) {
            this.form.possiblerights.push({
                value: this.allrights[i].id,
                text: this.allrights[i].name + ": " + this.allrights[i].description
            });
        }
    },
    components: { ChangePasswordModal }
};
</script>
