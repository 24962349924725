import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faStar,
    faBuilding,
    faPlusSquare,
    faEllipsisV,
    faInfoCircle,
    faEdit,
    faTasks,
    faListUl,
    faClipboardList,
    faClipboard,
    faTrashAlt,
    faMapMarkedAlt,
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faChevronUp,
    faBed,
    faSuitcaseRolling,
    faGlassCheers,
    faCalendarCheck,
    faFolderOpen,
    faToggleOn,
    faThumbtack,
    faUsers,
    faUserCog,
    faUserPlus,
    faCogs,
    faAddressCard,
    faSignOutAlt,
    faCircle,
    faStopwatch,
    faSortUp,
    faSortDown,
    faSort,
    faCheckCircle,
    faCheck,
    faCalendar,
    faUser,
    faExclamationCircle,
    faTimes,
    faPlus,
    faTimesCircle,
    faSave,
    faExternalLinkAlt,
    faFile,
    faExclamationTriangle,
    faUserTie,
    faStickyNote,
    faUserClock,
    faUserShield,
    faUserLock,
    faKey,
    faPenSquare,
    faUserCircle,
    faSadTear,
    faAlignJustify,
    faFireExtinguisher,
    faHandshake,
    faChartPie,
    faSpinner,
    faCaretDown,
    faExchangeAlt,
    faRedo,
    faCertificate,
    faCar,
    faCalculator,
    faLevelDownAlt,
    faLevelUpAlt,
    faUndo,
    faSchool
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faStar);
library.add(faBuilding);
library.add(faPlusSquare);
library.add(faEllipsisV);
library.add(faInfoCircle);
library.add(faEdit);
library.add(faTasks);
library.add(faListUl);
library.add(faClipboardList);
library.add(faClipboard);
library.add(faTrashAlt);
library.add(faMapMarkedAlt);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faGlassCheers);
library.add(faSuitcaseRolling);
library.add(faBed);
library.add(faCalendarCheck);
library.add(faFolderOpen);
library.add(faToggleOn);
library.add(faThumbtack);
library.add(faUsers);
library.add(faUserCog);
library.add(faUserPlus);
library.add(faCogs);
library.add(faAddressCard);
library.add(faSignOutAlt);
library.add(faCircle);
library.add(faStopwatch);
library.add(faSortUp);
library.add(faSortDown);
library.add(faSort);
library.add(faCheckCircle);
library.add(faCheck);
library.add(faCalendar);
library.add(faUser);
library.add(faExclamationCircle);
library.add(faTimes);
library.add(faPlus);
library.add(faTimesCircle);
library.add(faSave);
library.add(faExternalLinkAlt);
library.add(faFile);
library.add(faExclamationTriangle);
library.add(faUserTie);
library.add(faStickyNote);
library.add(faUserClock);
library.add(faUserShield);
library.add(faUserLock);
library.add(faKey);
library.add(faPenSquare);
library.add(faUserCircle);
library.add(faExternalLinkAlt);
library.add(faSadTear);
library.add(faAlignJustify);
library.add(faFireExtinguisher);
library.add(faHandshake);
library.add(faChartPie);
library.add(faSpinner);
library.add(faCaretDown);
library.add(faExchangeAlt);
library.add(faRedo);
library.add(faCertificate);
library.add(faCar);
library.add(faCalculator);
library.add(faLevelDownAlt);
library.add(faLevelUpAlt);
library.add(faRedo);
library.add(faUndo);
library.add(faSchool);

Vue.component("font-awesome-icon", FontAwesomeIcon);
