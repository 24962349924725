<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <YearSelector class="float-right" v-model="year" :allyears="false" />
            <h1>Urlaubs-Übersicht</h1>
        </template>
        <div class="d-flex justify-content-center mt-5 mb-5" v-if="!loaded">
            <b-spinner label="loaded..."></b-spinner>
        </div>

        <div v-else>
            <b-row no-gutters class="mb-5">
                <b-col cols="1">
                    <table class="table table-sm bg-light">
                        <thead>
                            <tr class="text-light">
                                <th>Monat</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-light small">
                                <td>Tag</td>
                            </tr>
                            <tr v-for="employee in employeesActive" v-bind:key="employee.id">
                                <td style="width:20px">
                                    <EmployeeName :initials="true" :id="employee.id" :dot="true" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-col>
                <b-col cols="11">
                    <div class="table-responsive ">
                        <table class="table table-sm bg-light">
                            <thead class="text-center">
                                <tr>
                                    <th></th>
                                    <th
                                        v-for="month in months"
                                        v-bind:key="month.name"
                                        :colspan="month.daysinmonth"
                                        class="firstofmonth"
                                    >
                                        {{ month.name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="small">
                                    <th></th>
                                    <td
                                        v-for="day in days"
                                        v-bind:key="day.formatted"
                                        v-bind:class="{
                                            firstofmonth: day.day == '01'
                                        }"
                                    >
                                        {{ day.day }}
                                    </td>
                                </tr>
                                <tr v-for="employee in employeesActive" v-bind:key="employee.id">
                                    <td class="text-light">
                                        x
                                    </td>
                                    <td
                                        v-for="day in days"
                                        v-bind:key="day.formatted"
                                        v-bind:class="{
                                            weekend: day.weekend,
                                            ispublicholiday: isPublicholiday(day.formatted),
                                            hasvacationday: employeeHasDate(
                                                employee.id,
                                                day.formatted
                                            ),
                                            firstofmonth: day.day == '01'
                                        }"
                                    ></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </b-col>
            </b-row>

            <VacationCalendar
                :year="year"
                :vacationdays="vacationdata.vacationdays"
                :publicholidays="publicholidaydata.publicholidays"
            />
        </div>
    </base-layout>
</template>
<script>
import { employeeService } from "../_services";
import VacationCalendar from "../components/VacationCalendar";
import YearSelector from "../components/YearSelector.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        VacationCalendar,
        YearSelector
    },
    data() {
        return {
            year:
                this.$route.params.year == null
                    ? parseInt(window.moment().format("YYYY"))
                    : parseInt(this.$route.params.year),
            vacationdata: {},
            publicholidaydata: {},
            pholoaded: false,
            vacloaded: false,
            loaded: false
        };
    },
    created() {
        if (this.$route.params.year == null) {
            this.$router.push({
                name: "employees.vacation",
                params: { year: window.moment().format("YYYY") }
            });
        }

        this.fetchVacationData();
        this.fetchPublicholidayData();
    },
    methods: {
        VacationdataForMonth(month) {
            return this.vacationdata.vacationdays.filter(
                vacationday => window.moment(vacationday.date, "YYYY-MM-DD").month() == month
            );
        },
        checkIfLoaded() {
            this.loaded = this.pholoaded && this.vacloaded;
        },
        fetchVacationData() {
            this.vacloaded = false;
            employeeService.getVacationdays(this.year).then(response => {
                this.vacloaded = true;
                this.vacationdata = response.data;
                this.checkIfLoaded();
            });
        },
        fetchPublicholidayData() {
            this.pholoaded = false;
            employeeService.getPublicholidays(this.year).then(response => {
                this.pholoaded = true;
                this.publicholidaydata = response.data;
                this.checkIfLoaded();
            });
        },
        employeeHasDate(employee_id, date) {
            for (let i = 0; i < this.vacationdata.vacationdays.length; i++) {
                let vacationday = this.vacationdata.vacationdays[i];
                if (vacationday.date == date && vacationday.employee_id == employee_id) {
                    return true;
                }
            }
            return false;
        },
        isPublicholiday(date) {
            for (let i = 0; i < this.publicholidaydata.publicholidays.length; i++) {
                let publicholiday = this.publicholidaydata.publicholidays[i];
                if (publicholiday.date == date) {
                    return true;
                }
            }
        }
    },
    computed: {
        datefrom() {
            if (this.year == window.moment().format("YYYY")) {
                // return this.year + "-" + window.moment().format("MM") + "-01";
                return this.year + "-01-01";
            } else {
                return this.year + "-01-01";
            }
        },
        months() {
            let datefrom = window.moment(this.datefrom, "YYYY-MM-DD");
            let dateto = window.moment(this.year + "-12-01", "YYYY-MM-DD");
            let months = [];
            months.push({
                name: datefrom.format("MMMM"),
                daysinmonth: datefrom.daysInMonth()
            });
            while (datefrom.format("YYYY-MM-DD") != dateto.format("YYYY-MM-DD")) {
                datefrom = datefrom.add(1, "months");
                months.push({
                    name: datefrom.format("MMMM"),
                    daysinmonth: datefrom.daysInMonth()
                });
            }
            return months;
        },
        days() {
            let datefrom = window.moment(this.datefrom, "YYYY-MM-DD");
            let dateto = window.moment(this.year + "-12-31", "YYYY-MM-DD");
            let days = [];
            days.push({
                formatted: datefrom.format("YYYY-MM-DD"),
                day: datefrom.format("DD"),
                weekend: datefrom.isoWeekday() == 7 || datefrom.isoWeekday() == 6
            });
            while (datefrom.format("YYYY-MM-DD") != dateto.format("YYYY-MM-DD")) {
                datefrom = datefrom.add(1, "days");
                days.push({
                    formatted: datefrom.format("YYYY-MM-DD"),
                    day: datefrom.format("DD"),
                    weekend: datefrom.isoWeekday() == 7 || datefrom.isoWeekday() == 6
                });
            }
            return days;
        },
        ...mapGetters({
            employeesActive: "employees/employeesEmployed"
        })
    },
    watch: {
        year: function() {
            this.$router.push({
                name: "employees.vacation",
                params: { year: this.year }
            });

            this.loaded = false;
            this.fetchVacationData();
            this.fetchPublicholidayData();
        }
    }
};
</script>
<style lang="scss" scoped>
.hasvacationday {
    background: #404040;
}
.weekend {
    background: #dedede;
}
.ispublicholiday {
    background: #a7a7a7;
}
.firstofmonth {
    border-left: 1px solid #a7a7a7;
}
.table-sm th,
.table-sm td {
    padding-left: 0.1rem !important;
    padding-right: 0.1rem !important;
}
</style>
