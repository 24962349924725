import Vue from "vue";
import VueRouter from "vue-router";

import HomePage from "../views/Home";
import LoginPage from "../views/Login";

import ProjectsFavorites from "../views/ProjectsFavorites";
import ProjectsIndex from "../views/ProjectsIndex";
import ProjectsCreate from "../views/ProjectsCreate";
import ProjectsDocumentlocations from "../views/ProjectsDocumentlocations";
import ProjectsStatuses from "../views/ProjectsStatuses";

import ProjectDetail from "../views/ProjectDetail/ProjectDetail.vue";
import ProjectRecordOverview from "../views/ProjectDetail/ProjectRecordOverview.vue";
import ProjectAnalysis from "../views/ProjectDetail/ProjectAnalysis.vue";
import ProjectWarranties from "../views/ProjectDetail/ProjectWarranties.vue";
import ProjectTeams from "../views/ProjectTeams.vue";

import EmployeesVacation from "../views/EmployeesVacation";
import EmployeesOverview from "../views/EmployeesOverview";
import EmployeesPublicholidays from "../views/EmployeesPublicholidays";
import EmployeesTimesheet from "../views/EmployeesTimesheet";
import EmployeeDetail from "../views/EmployeeDetail/EmployeeDetail.vue";
import EmployeeMonthlySums from "../views/EmployeeDetail/MonthlySums.vue";
import EmployeeVacationdays from "../views/EmployeeDetail/Vacationdays.vue";
import EmployeeSickdays from "../views/EmployeeDetail/Sickdays.vue";
import EmployeePublicholidays from "../views/EmployeeDetail/Publicholidays.vue";

import UsersCreate from "../views/UsersCreate";
import UsersOverview from "../views/UsersOverview";
import UserDetail from "../views/UserDetail.vue";
import EmployeesCreate from "../views/EmployeesCreate";

import MyProfile from "../views/MyProfile";
import MyOverview from "../views/MyOverview";
import MyVacation from "../views/MyVacation";
import MyRecords from "../views/MyRecords";

import WarrantiesOverview from "../views/WarrantiesOverview";
// import Driverslog from "../views/Driverslog.vue";
// import DriverslogAnalysis from "../views/DriverslogAnalysis.vue";
// import DriverslogValidator from "../views/DriverslogValidator.vue";

Vue.use(VueRouter);

const routes = [
    { path: "/", name: "home", component: HomePage },
    { path: "/login", name: "login", component: LoginPage },
    { path: "/my/profile", name: "my.profile", component: MyProfile },
    {
        path: "/projects/",
        name: "projects.favorites",
        component: ProjectsFavorites
    },
    {
        path: "/projects/list",
        name: "projects.index",
        component: ProjectsIndex
    },
    {
        path: "/projects/create/",
        name: "projects.create",
        component: ProjectsCreate
    },
    {
        path: "/projects/documentlocations",
        name: "projects.documentlocations",
        component: ProjectsDocumentlocations
    },
    {
        path: "/projects/statuses",
        name: "projects.statuses",
        component: ProjectsStatuses
    },
    {
        path: "/projects/:id",
        name: "project.detail",
        component: ProjectDetail
    },
    {
        path: "/projects/:id/analysis",
        name: "project.analysis",
        component: ProjectAnalysis
    },
    {
        path: "/projects/:id/recordoverview",
        name: "project.recordoverview",
        component: ProjectRecordOverview
    },
    {
        path: "/projects/:id/warranties",
        name: "project.warranties",
        component: ProjectWarranties
    },
    {
        path: "/employees",
        name: "employees.overview",
        component: EmployeesOverview
    },
    {
        path: "/employees/vacation",
        name: "employees.vacation.withoutyear",
        component: EmployeesVacation
    },
    {
        path: "/employees/vacation/:year",
        name: "employees.vacation",
        component: EmployeesVacation
    },
    {
        path: "/employees/publicholidays",
        name: "employees.publicholidays",
        component: EmployeesPublicholidays
    },
    {
        path: "/employees/create",
        name: "employees.create",
        component: EmployeesCreate
    },
    {
        path: "/employees/:id",
        name: "employee.detail",
        component: EmployeeDetail
    },
    {
        path: "/employees/:id/monthlysums",
        name: "employee.monthlysums",
        component: EmployeeMonthlySums
    },
    {
        path: "/employees/:id/vacationdays",
        name: "employee.vacationdays",
        component: EmployeeVacationdays
    },
    {
        path: "/employees/:id/sickdays",
        name: "employee.sickdays",
        component: EmployeeSickdays
    },
    {
        path: "/employees/:id/publicholidays",
        name: "employee.publicholidays",
        component: EmployeePublicholidays
    },
    {
        path: "/employees/:id/timesheets/:period",
        name: "employees.timesheet",
        component: EmployeesTimesheet
    },
    {
        path: "/records/me",
        name: "my.records",
        component: MyRecords
    },
    {
        path: "/records",
        name: "my.records2",
        component: MyRecords
    },
    {
        path: "/records/me/:date",
        name: "my.records.withdate",
        component: MyRecords
    },
    {
        path: "/records/:id",
        name: "my.records.withid",
        component: MyRecords
    },
    {
        path: "/records/:id/:date",
        name: "my.records.withdateandid",
        component: MyRecords
    },
    {
        path: "/my/overview",
        name: "my.overview",
        component: MyOverview
    },
    {
        path: "/my/vacation",
        name: "my.vacation",
        component: MyVacation
    },
    {
        path: "/my/vacation/:id/:year",
        name: "my.vacation.withyearandid",
        component: MyVacation
    },
    {
        path: "/users/create",
        name: "users.create",
        component: UsersCreate
    },
    {
        path: "/users",
        name: "users.overview",
        component: UsersOverview
    },
    {
        path: "/users/:id",
        name: "user.detail",
        component: UserDetail
    },
    {
        path: "/warranties",
        name: "warranties.overview",
        component: WarrantiesOverview
    },
    {
        path: "/projectteams",
        name: "projectteams",
        component: ProjectTeams
    },
    // {
    //     path: "/driverslog",
    //     name: "driverslog",
    //     component: Driverslog
    // },
    // {
    //     path: "/driverslog/:id",
    //     name: "driverslog.list",
    //     component: Driverslog
    // },
    // {
    //     path: "/driverslog/:id/analysis",
    //     name: "driverslog.analysis",
    //     component: DriverslogAnalysis
    // },
    // {
    //     path: "/driverslog/:id/validator",
    //     name: "driverslog.validator",
    //     component: DriverslogValidator
    // },
    // {
    //     path: "/driverslog/:id/settings",
    //     name: "driverslog.settings",
    //     component: Driverslog
    // },
    // otherwise redirect to home
    { path: "*", redirect: "/" }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["/login"];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem("user");

    if (authRequired && !loggedIn) {
        // console.log("redirect because loggedout");
        return next("/login");
    }

    next();
});

export default router;
