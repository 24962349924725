<template>
    <small class="text-muted" v-if="getOvertime() < 0">
        <font-awesome-icon icon="sort-down" class="text-success" v-if="getOvertime() >= success" />
        <font-awesome-icon
            icon="sort-down"
            class="text-warning"
            v-if="getOvertime() < success && getOvertime() >= warning"
        />
        <font-awesome-icon icon="sort-down" class="text-danger" v-if="getOvertime() < warning" />
        &nbsp;{{ -getOvertime() | NumberTwoDigits }} Stunden unter dem Tages-Soll
    </small>

    <small class="text-muted" v-else-if="getOvertime() > 0">
        <font-awesome-icon icon="sort-up" class="text-success" v-if="getOvertime() <= success" />
        <font-awesome-icon
            icon="sort-up"
            class="text-warning"
            v-if="getOvertime() > success && getOvertime() <= warning"
        />
        <font-awesome-icon icon="sort-up" class="text-danger" v-if="getOvertime() > warning" />
        &nbsp;{{ getOvertime() | NumberTwoDigits }} Stunden über dem Tages-Soll
    </small>

    <small class="text-muted" v-else-if="getOvertime() == 0">
        <font-awesome-icon icon="check" />&nbsp;entspricht dem Tages-Soll
    </small>
</template>
<script>
export default {
    props: {
        quota: Number,
        recordsum: Number
    },
    data: function() {
        return {
            success: 2,
            warning: 4
        };
    },
    methods: {
        getOvertime() {
            return this.recordsum - this.quota;
        }
    }
};
</script>
