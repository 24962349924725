<template>
    <b-container class="my-5">
        <b-row class="justify-content-center">
            <b-col cols="12" md="6" sm="8" lg="5" class=" mt-5">
                <div class="card shadow">
                    <div class="card-body">
                        <img
                            src="../assets/logo.svg"
                            class="mx-auto d-block mt-3 mb-5"
                            style="max-width:300px"
                        />

                        <form @submit.prevent="handleLoginSubmit()">
                            <div class="form-group">
                                <input
                                    type="email"
                                    v-model="emailaddress"
                                    name="emailaddress"
                                    class="form-control"
                                    placeholder="E-Mail-Adresse"
                                    :class="{
                                        'is-invalid': submitted && !emailaddress
                                    }"
                                    :disabled="loggingIn"
                                />
                                <div v-show="submitted && !emailaddress" class="invalid-feedback">
                                    E-Mail-Adresse ist ein Pflichtfeld.
                                </div>
                            </div>
                            <div class="form-group">
                                <input
                                    type="password"
                                    v-model="password"
                                    name="password"
                                    class="form-control"
                                    placeholder="Passwort"
                                    :class="{
                                        'is-invalid': submitted && !password
                                    }"
                                    :disabled="loggingIn"
                                />
                                <div v-show="submitted && !password" class="invalid-feedback">
                                    Passwort ist ein Pflichtfeld.
                                </div>
                            </div>
                            <div class="form-group">
                                <button class="btn btn-primary btn-block" :disabled="loggingIn">
                                    <font-awesome-icon far icon="spinner" spin v-if="loggingIn" />
                                    <span v-if="loggingIn">&nbsp;Anmeldung wird ausgeführt</span>
                                    <span v-if="!loggingIn">anmelden</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            emailaddress: "",
            password: "",
            loggingIn: false,
            submitted: false
        };
    },
    created() {
        this.$store.dispatch("authentication/logout");
    },
    methods: {
        handleLoginSubmit() {
            this.submitted = true;
            // if email or password empty, abord
            if (!this.emailaddress || !this.password) {
                return;
            }
            // start logginin process
            this.loggingIn = true;
            // check credentials
            this.$store.dispatch("authentication/login", {
                username: this.emailaddress,
                password: this.password
            });
        }
    }
};
</script>
