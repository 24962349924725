var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-nav',{attrs:{"tabs":"","align":"center"}},[_c('b-nav-item',{attrs:{"active":_vm.isActive('projects-overview'),"to":{
            name: 'project.detail',
            params: { id: _vm.localProjectId }
        }}},[_vm._v("Übersicht")]),_c('b-nav-item',{attrs:{"active":_vm.isActive('projects-analysis'),"to":{
            name: 'project.analysis',
            params: { id: _vm.localProjectId }
        }}},[_vm._v("Buchungsauswertung")]),_c('b-nav-item',{attrs:{"active":_vm.isActive('projects-recordoverview'),"to":{
            name: 'project.recordoverview',
            params: { id: _vm.localProjectId }
        }}},[_vm._v("Projektbuchungen ")]),_c('b-nav-item',{attrs:{"active":_vm.isActive('projects-warranties'),"to":{
            name: 'project.warranties',
            params: { id: _vm.localProjectId }
        }}},[_vm._v("Gewährleistungen ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }