<template>
    <div class="mt-1" v-if="BadgeValues != null && BadgeValues.length > 0">
        <small class="text-muted">Vorschläge: </small>
        <b-badge
            v-for="badge in BadgeValues"
            class="mr-1"
            v-bind:key="badge"
            pill
            variant="dark"
            @click="activateBadge(badge)"
            style="cursor:pointer"
        >
            {{ badge }}
        </b-badge>
        <br />
        <b-badge
            v-for="tag in TagValues"
            class="mr-1"
            v-bind:key="tag"
            pill
            variant="light"
            @click="activateTag(tag)"
            style="cursor:pointer"
        >
            {{ tag }}
        </b-badge>
        &nbsp;
    </div>
</template>

<script>
export default {
    props: {
        project_id: Number,
        description: String
    },
    computed: {
        services() {
            return this.$store.state.projects.services.items;
        },
        projectinfo() {
            return this.$root.getProjectObject(this.project_id);
        },
        selectedBadges() {
            if (this.project_id == 0) {
                return;
            }
            var ret = [];
            for (var k = 0; k < this.services.length; k++) {
                if (
                    this.projectinfo.services_ids != null &&
                    this.projectinfo.services_ids.includes(this.services[k].id) &&
                    this.description != null &&
                    this.description.includes(this.services[k].name)
                ) {
                    ret.push(this.services[k].name);
                }
            }
            return ret;
        },
        BadgeValues() {
            if (this.project_id == 0) {
                return;
            }
            var ret = [];
            for (var k = 0; k < this.services.length; k++) {
                if (
                    this.projectinfo.services_ids != null &&
                    this.projectinfo.services_ids.includes(this.services[k].id) &&
                    !this.selectedBadges.includes(this.services[k].name)
                ) {
                    ret.push(this.services[k].name);
                }
            }
            return ret;
        },
        TagValues() {
            if (this.project_id == 0) {
                return;
            }
            var ret = [];
            for (var k = 0; k < this.services.length; k++) {
                if (
                    this.projectinfo.services_ids != null &&
                    this.projectinfo.services_ids.includes(this.services[k].id)
                ) {
                    for (var l = 0; l < this.services[k].tags.length; l++) {
                        if (
                            this.selectedBadges != null &&
                            this.selectedBadges.includes(this.services[k].name) &&
                            this.services[k].tags[l].suggest == true
                        ) {
                            ret.push(this.services[k].tags[l].name);
                        }
                    }
                }
            }
            return ret;
        }
    },
    methods: {
        activateBadge(badge) {
            this.$emit("set-badge", badge);
        },
        activateTag(tag) {
            this.$emit("set-tag", tag);
        }
    }
};
</script>

<style></style>
