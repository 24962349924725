var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-button-group',{staticClass:"float-right d-print-none"},[_c('b-button',{attrs:{"size":"sm","variant":"secondary","to":{
            name: 'employee.detail',
            params: {
                id: _vm.employee_id
            }
        }}},[_c('font-awesome-icon',{attrs:{"icon":"external-link-alt"}}),_vm._v(" Übersicht")],1),(_vm.showtimesheetlinks)?_c('b-dropdown',{attrs:{"right":"","size":"sm","variant":"outline-secondary","id":"dropdown-1"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"file"}}),_vm._v(" Stundenaufstellung ")]},proxy:true}],null,false,3121967615)},_vm._l((_vm.periodoptions),function(period){return _c('b-dropdown-item',{key:period,attrs:{"to":{
                name: 'employees.timesheet',
                params: {
                    id: _vm.employee_id,
                    period: period
                }
            }}},[_vm._v(_vm._s(_vm._f("PeriodSmall")(period)))])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }