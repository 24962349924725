<template>
    <div>
        <b-row no-gutters>
            <b-col>
                <p class="display-3">
                    <small :class="getDotColor(OvertimeFromLastMonth, 50)" v-if="showdots">
                        <font-awesome-icon icon="circle" />
                    </small>
                    {{ OvertimeFromLastMonth | NumberTwoDigitsPlusMinusFirstHalf
                    }}<sup class="duration-sup">
                        {{ OvertimeFromLastMonth | NumberTwoDigitsPlusMinusSecondHalf }}</sup
                    >
                </p>
                <div>
                    Überstunden
                    <font-awesome-icon
                        icon="info-circle"
                        :id="'tooltip-info-1-' + meta[0].employee_id"
                        class="text-muted"
                    />
                    <b-tooltip :target="'tooltip-info-1-' + meta[0].employee_id" triggers="hover">
                        bis einschließlich {{ LastDayOfLastMonth | DateWithoutYear }}
                    </b-tooltip>
                </div>
            </b-col>
            <b-col>
                <p class="display-3">
                    <small :class="getDotColor(OvertimeUntilYesterday, 20)" v-if="showdots">
                        <font-awesome-icon icon="circle" />
                    </small>
                    {{ OvertimeUntilYesterday | NumberTwoDigitsPlusMinusFirstHalf
                    }}<sup class="duration-sup">
                        {{ OvertimeUntilYesterday | NumberTwoDigitsPlusMinusSecondHalf }}</sup
                    >
                </p>
                <div>
                    Überstunden im {{ new Date() | moment("MMMM") }}
                    <font-awesome-icon
                        icon="info-circle"
                        :id="'tooltip-info-2-' + meta[0].employee_id"
                        class="text-muted"
                    />
                    <b-tooltip :target="'tooltip-info-2-' + meta[0].employee_id" triggers="hover">
                        bis einschließlich gestern
                    </b-tooltip>
                </div>

                <!-- @TODO no info when first of month -->
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentperiod: window.moment().format("YYYY-MM"),
            LastDayOfLastMonth: window
                .moment()
                .startOf("month")
                .subtract(1, "day")
                .format("YYYY-MM-DD")
        };
    },
    props: {
        meta: {
            type: Array,
            required: true
        },
        monthlysums: {
            type: Array,
            required: true
        },
        currentmonth: {
            type: Object,
            required: true
        },
        showdots: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    mounted() {},
    computed: {
        QuotaUntilYesterday() {
            let current = window.moment().startOf("month");
            let today = window
                .moment()
                .startOf("day")
                .format("YYYY-MM-DD");
            let quota = 0;
            while (current.format("YYYY-MM-DD") != today) {
                quota += this.getQuotaOfDay(current);
                current = current.add(1, "days");
            }
            return quota;
        },
        OvertimeFromLastMonth() {
            let sum = 0;
            this.monthlysums.forEach(element => {
                if (element.period < this.currentperiod) {
                    sum = sum + element.haben_sum - element.soll_sum;
                }
            });
            return sum;
        },
        RecordsUntilYesterday() {
            let yesterday = window
                .moment()
                .subtract(1, "day")
                .format("YYYY-MM-DD");
            let sum = 0;
            this.currentmonth.records.forEach(element => {
                if (element.date <= yesterday) {
                    sum +=
                        window.moment
                            .duration(element.endtime)
                            .subtract(window.moment.duration(element.starttime)) / 3600000;
                }
            });
            this.currentmonth.publicholidays.forEach(element => {
                if (element.date <= yesterday) {
                    sum += element.recordvalue_computed;
                }
            });
            this.currentmonth.sickdays.forEach(element => {
                if (element.date <= yesterday) {
                    sum += element.recordvalue_computed;
                }
            });
            this.currentmonth.vacationdays.forEach(element => {
                if (element.date <= yesterday) {
                    sum += element.recordvalue_computed;
                }
            });
            return sum;
        },
        OvertimeUntilYesterday() {
            return this.RecordsUntilYesterday - this.QuotaUntilYesterday;
        }
    },
    methods: {
        getQuotaOfDay(day) {
            let date = day.format("YYYY-MM-DD");
            for (let i = 0; i < this.meta.length; i++) {
                let row = this.meta[i];
                if (row.datefrom > date) {
                    // nothing found, because $date is before any metas
                    return 0; //@TODO throw error?!
                } else if (row.datefrom <= date && (row.dateto >= date || row.dateto === null)) {
                    // correct one found: save correct weekday-quota
                    let weekdayOfDate = parseInt(day.format("E"));
                    let ret = 0;
                    if (weekdayOfDate == 1) {
                        ret = row.quotamon;
                    } else if (weekdayOfDate == 2) {
                        ret = row.quotatue;
                    } else if (weekdayOfDate == 3) {
                        ret = row.quotawed;
                    } else if (weekdayOfDate == 4) {
                        ret = row.quotathu;
                    } else if (weekdayOfDate == 5) {
                        ret = row.quotafri;
                    } else if (weekdayOfDate == 6) {
                        ret = row.quotasat;
                    } else if (weekdayOfDate == 7) {
                        ret = row.quotasun;
                    }
                    return ret;
                } else {
                    // maybe next one?
                }
            }
        },
        getDotColor(value, threshold) {
            if (value > threshold || value < -threshold) {
                return "text-danger";
            } else {
                return "text-success";
            }
        }
    }
};
</script>
