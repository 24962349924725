var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-layout',{attrs:{"fluid":""},scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_c('YearSelector',{staticClass:"float-right",attrs:{"allyears":false},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_c('h1',[_vm._v("Urlaubs-Übersicht")])]},proxy:true}])},[(!_vm.loaded)?_c('div',{staticClass:"d-flex justify-content-center mt-5 mb-5"},[_c('b-spinner',{attrs:{"label":"loaded..."}})],1):_c('div',[_c('b-row',{staticClass:"mb-5",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"1"}},[_c('table',{staticClass:"table table-sm bg-light"},[_c('thead',[_c('tr',{staticClass:"text-light"},[_c('th',[_vm._v("Monat")])])]),_c('tbody',[_c('tr',{staticClass:"text-light small"},[_c('td',[_vm._v("Tag")])]),_vm._l((_vm.employeesActive),function(employee){return _c('tr',{key:employee.id},[_c('td',{staticStyle:{"width":"20px"}},[_c('EmployeeName',{attrs:{"initials":true,"id":employee.id,"dot":true}})],1)])})],2)])]),_c('b-col',{attrs:{"cols":"11"}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-sm bg-light"},[_c('thead',{staticClass:"text-center"},[_c('tr',[_c('th'),_vm._l((_vm.months),function(month){return _c('th',{key:month.name,staticClass:"firstofmonth",attrs:{"colspan":month.daysinmonth}},[_vm._v(" "+_vm._s(month.name)+" ")])})],2)]),_c('tbody',[_c('tr',{staticClass:"small"},[_c('th'),_vm._l((_vm.days),function(day){return _c('td',{key:day.formatted,class:{
                                        firstofmonth: day.day == '01'
                                    }},[_vm._v(" "+_vm._s(day.day)+" ")])})],2),_vm._l((_vm.employeesActive),function(employee){return _c('tr',{key:employee.id},[_c('td',{staticClass:"text-light"},[_vm._v(" x ")]),_vm._l((_vm.days),function(day){return _c('td',{key:day.formatted,class:{
                                        weekend: day.weekend,
                                        ispublicholiday: _vm.isPublicholiday(day.formatted),
                                        hasvacationday: _vm.employeeHasDate(
                                            employee.id,
                                            day.formatted
                                        ),
                                        firstofmonth: day.day == '01'
                                    }})})],2)})],2)])])])],1),_c('VacationCalendar',{attrs:{"year":_vm.year,"vacationdays":_vm.vacationdata.vacationdays,"publicholidays":_vm.publicholidaydata.publicholidays}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }