<template>
    <table class="table text-right table-hover mb-0" v-if="detailed">
        <!-- @TODO make a better v-if (less duplicate code!)-->
        <thead>
            <tr>
                <th class="text-left">Monat</th>
                <th>Überstunden</th>
                <th>Soll</th>
                <th>Monats-Soll</th>
                <th>Extra-Soll</th>
                <th>Haben</th>
                <th><font-awesome-icon icon="building" fixed-width />&nbsp;Projekte</th>
                <th><font-awesome-icon icon="suitcase-rolling" fixed-width />&nbsp;Urlaub</th>
                <th><font-awesome-icon icon="bed" fixed-width />&nbsp;Krankheit</th>
                <th><font-awesome-icon icon="glass-cheers" fixed-width />&nbsp;Feiertag</th>
            </tr>
        </thead>
        <tbody>
            <NoEntriesFound v-if="activeEntries.length == 0" :colspan="10" />
            <tr v-else v-for="month in activeEntries" v-bind:key="month.period">
                <td class="text-left">
                    {{ month.period | PeriodSmall }}
                    <b-button
                        :to="{
                            name: 'employees.timesheet',
                            params: { id: employeedata.id, period: month.period }
                        }"
                        size="sm"
                        variant="link"
                        :id="'record_sheet_' + month.period"
                    >
                        <font-awesome-icon icon="file" />
                        <b-tooltip :target="'record_sheet_' + month.period" triggers="hover"
                            >Stundenaufstellung für
                            {{ month.period | PeriodSmall }} anzeigen</b-tooltip
                        >
                    </b-button>
                    <span v-if="currentperiod <= month.period">
                        <b-badge variant="warning">vorläufig</b-badge>
                    </span>
                </td>
                <td class="bg-light">
                    <span v-if="currentperiod > month.period">{{
                        (month.haben_sum - month.soll_sum) | NumberTwoDigitsPlusMinus
                    }}</span>
                    <span v-else>-</span>
                </td>
                <td class="bg-light">
                    {{ month.soll_sum | NumberTwoDigitsOrEmpty }}
                </td>
                <td class="text-muted">
                    {{ month.soll_quota | NumberTwoDigitsOrEmpty }}
                </td>
                <td class="text-muted">
                    {{ month.soll_custom | NumberTwoDigitsOrEmpty }}
                    <span v-if="month.soll_description != ''">
                        <font-awesome-icon
                            icon="info-circle"
                            :id="'tooltip-target-' + month.period"
                        />
                        <b-tooltip :target="'tooltip-target-' + month.period" triggers="hover">{{
                            month.soll_description
                        }}</b-tooltip>
                    </span>
                </td>
                <td class="bg-light">
                    {{ month.haben_sum | NumberTwoDigitsOrEmpty }}
                </td>
                <td class="text-muted">
                    {{ month.haben_pro | NumberTwoDigitsOrEmpty }}
                    <b-button
                        size="sm"
                        variant="link"
                        type="button"
                        :id="'show_project_records' + month.period"
                        v-if="month.haben_pro != 0"
                    >
                        <font-awesome-icon icon="external-link-alt" />
                        <b-tooltip :target="'show_project_records' + month.period" triggers="hover"
                            >Buchungen in {{ month.period | PeriodSmall }}</b-tooltip
                        >
                    </b-button>
                </td>
                <td class="text-muted">
                    {{ month.haben_vad | NumberTwoDigitsOrEmpty }}
                    <b-button
                        size="sm"
                        variant="link"
                        type="button"
                        :id="'show_vacationdays' + month.period"
                        v-if="month.haben_vad != 0"
                    >
                        <font-awesome-icon icon="external-link-alt" />
                        <b-tooltip :target="'show_vacationdays' + month.period" triggers="hover"
                            >alle Urlaubstage in {{ month.period | PeriodSmall }}</b-tooltip
                        >
                    </b-button>
                </td>
                <td class="text-muted">
                    {{ month.haben_sid | NumberTwoDigitsOrEmpty }}
                    <b-button
                        size="sm"
                        variant="link"
                        type="button"
                        :id="'show_sickdays' + month.period"
                        v-if="month.haben_sid != 0"
                    >
                        <font-awesome-icon icon="external-link-alt" />
                        <b-tooltip :target="'show_sickdays' + month.period" triggers="hover"
                            >alle Krankheitstage in {{ month.period | PeriodSmall }}</b-tooltip
                        >
                    </b-button>
                </td>
                <td class="text-muted">
                    {{ month.haben_phd | NumberTwoDigitsOrEmpty }}
                    <b-button
                        size="sm"
                        variant="link"
                        type="button"
                        :id="'show_publicholidays' + month.period"
                        v-if="month.haben_phd != 0"
                    >
                        <font-awesome-icon icon="external-link-alt" />
                        <b-tooltip :target="'show_publicholidays' + month.period" triggers="hover"
                            >alle Feiertage in {{ month.period | PeriodSmall }}</b-tooltip
                        >
                    </b-button>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table text-right table-hover mb-0" v-else>
        <thead>
            <tr>
                <th class="text-left">Monat</th>
                <th>Überstunden</th>
                <th>Soll</th>
                <th>Haben</th>
            </tr>
        </thead>
        <tbody>
            <NoEntriesFound v-if="activeEntries.length == 0" :colspan="4" />
            <tr v-else v-for="month in activeEntries" v-bind:key="month.period">
                <td class="text-left">
                    {{ month.period | PeriodSmall }}
                    <b-button
                        :to="{
                            name: 'employees.timesheet',
                            params: { id: employeedata.id, period: month.period }
                        }"
                        size="sm"
                        variant="link"
                        :id="'record_sheet_' + month.period"
                    >
                        <font-awesome-icon icon="file" />
                        <b-tooltip :target="'record_sheet_' + month.period" triggers="hover"
                            >Stundenaufstellung für
                            {{ month.period | PeriodSmall }} anzeigen</b-tooltip
                        >
                    </b-button>
                    <span v-if="currentperiod <= month.period">
                        <b-badge variant="warning">vorläufig</b-badge>
                    </span>
                </td>
                <td>
                    <span v-if="currentperiod > month.period">{{
                        (month.haben_sum - month.soll_sum) | NumberTwoDigitsPlusMinus
                    }}</span>
                    <span v-else>-</span>
                </td>
                <td>
                    {{ month.soll_sum | NumberTwoDigitsOrEmpty }}
                    <span v-if="month.soll_custom != 0" class="text-muted">
                        <font-awesome-icon
                            icon="info-circle"
                            :id="'tooltip-target-' + month.period"
                        />
                        <b-tooltip :target="'tooltip-target-' + month.period" triggers="hover">
                            enthält Extra-Soll:
                            {{ month.soll_custom | NumberTwoDigitsOrEmpty }}
                            <span v-if="month.soll_description != ''"
                                >({{ month.soll_description }})</span
                            >
                        </b-tooltip>
                    </span>
                </td>
                <td>{{ month.haben_sum | NumberTwoDigitsOrEmpty }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        entries: Array,
        year: Number,
        detailed: Boolean,
        employeedata: Object
    },
    data() {
        return { currentperiod: window.moment().format("YYYY-MM") };
    },
    created() {},
    methods: {},
    computed: {
        userHasManagerRoleForEmployee() {
            return this.$root.userHasManagerRoleForEmployee(this.employeedata.id);
        },
        activeEntries: function() {
            if (this.year == 0) {
                return this.entries;
            }
            let ret = [];
            this.entries.forEach(element => {
                if (element.period.includes(this.year)) {
                    ret.push(element);
                }
            });
            return ret;
        }
    },
    watch: {}
};
</script>
