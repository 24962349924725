<template>
    <b-nav tabs align="center">
        <b-nav-item
            :active="isActive('users-overview')"
            :to="{
                name: 'users.overview'
            }"
            >Übersicht</b-nav-item
        >
        <b-nav-item
            :active="isActive('employees-create')"
            :to="{
                name: 'employees.create'
            }"
            >Neuen Mitarbeiter anlegen</b-nav-item
        >
        <b-nav-item
            :active="isActive('users-create')"
            :to="{
                name: 'users.create'
            }"
            >Neuen Benutzer anlegen</b-nav-item
        >
    </b-nav>
</template>
<script>
export default {
    props: {
        active: String
    },
    data() {
        return {};
    },
    methods: {
        isActive(NameOfNavItem) {
            return NameOfNavItem == this.active;
        }
    },
    computed: {}
};
</script>
