<template>
    <div class="my-2" v-if="$root.UserHasRight('canDebug')">
        <b-collapse id="collapse-1" class="mt-2">
            <b-row>
                <b-col cols="4">
                    <h4 class="mt-3">User ("me")</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.employees.user }}</pre>
                    </b-card>
                </b-col>
                <b-col cols="4">
                    <h4 class="mt-3">Projects</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.projects.projects }}</pre>
                    </b-card>
                </b-col>
                <b-col cols="4">
                    <h4 class="mt-3">Employees</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.employees.employees }}</pre>
                    </b-card>
                </b-col>
                <b-col cols="4">
                    <h4 class="mt-3">Statuses</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.projects.statuses }}</pre>
                    </b-card>
                </b-col>
                <b-col cols="4">
                    <h4 class="mt-3">Documentlocations</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.projects.documentlocations }}</pre>
                    </b-card>
                </b-col>
                <b-col cols="4">
                    <h4 class="mt-3">Rights</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.employees.rights }}</pre>
                    </b-card>
                </b-col>
                <b-col cols="4">
                    <h4 class="mt-3">Services</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.projects.services }}</pre>
                    </b-card>
                </b-col>
                <b-col cols="4">
                    <h4 class="mt-3">Projectroles</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.projects.projectroles }}</pre>
                    </b-card>
                </b-col>
                <b-col cols="4">
                    <h4 class="mt-3">Users</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.employees.users }}</pre>
                    </b-card>
                </b-col>
                <b-col cols="4">
                    <h4 class="mt-3">Cars</h4>
                    <b-card style="height:300px; overflow-y: scroll">
                        <pre>{{ this.$store.state.projects.cars }}</pre>
                    </b-card>
                </b-col>
            </b-row>
        </b-collapse>
        <b-button v-b-toggle.collapse-1 class="float-right my-2" variant="outline-dark" size="sm"
            >StoreCheck</b-button
        >
    </div>
</template>
<script>
export default {
    data() {
        return {};
    }
};
</script>
