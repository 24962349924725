<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>Mitarbeiter-Übersicht</h1>
        </template>

        <div class="d-flex justify-content-center mt-5 mb-5" v-if="!loaded_data">
            <b-spinner label="loaded..."></b-spinner>
        </div>

        <div class="card mb-5" v-if="loaded_data">
            <table class="table table-hover table-without-top-line mb-0">
                <thead>
                    <tr>
                        <th></th>
                        <th style="min-width:190px">
                            <div>Überstunden</div>
                            <small class="text-sm text-muted"
                                >bis einschließlich
                                {{ LastDayOfLastMonth | DateWithoutYear }}</small
                            >
                        </th>
                        <th style="min-width:160px">
                            <div>Überstunden im {{ new Date() | moment("MMMM") }}</div>
                            <small class="text-sm text-muted">bis einschließlich gestern</small>
                        </th>
                        <th class="text-right">Urlaub: <br />verfügbar</th>
                        <th class="text-right">genommen</th>
                        <th class="text-right">geplant</th>
                        <th class="text-right">übrig</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <EmployeeInfoRow
                        v-for="employeedata in allemployeedata"
                        v-bind:key="employeedata.employee.id"
                        :employee="employeedata.employee"
                        :meta="employeedata.employee.meta"
                        :monthlysums="employeedata.monthly_sums"
                        :currentmonth="employeedata.timesheet_currentmonth"
                        :vacation="employeedata.vacation"
                    />
                </tbody>
            </table>
        </div>

        <b-row>
            <b-col v-if="loaded_data && employees_inactive.length > 0">
                <h3 class="mb-2">inaktive Mitarbeiter</h3>

                <div class="card">
                    <table class="table table-hover table-without-top-line mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Kürzel</th>
                                <th scope="col" colspan="2">Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="employee in employees_inactive"
                                v-bind:key="'employees-' + employee.id"
                            >
                                <td>{{ employee.initials }}</td>
                                <td>
                                    <EmployeeName :id="employee.id" :dot="false" />
                                </td>
                                <td>
                                    <EmployeeLinks
                                        :employee_id="employee.id"
                                        :showtimesheetlinks="false"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
    </base-layout>
</template>
<script>
import { employeeService } from "../_services";
import EmployeeLinks from "../components/EmployeeLinks";
import EmployeeInfoRow from "../components/EmployeeInfoRow";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            loaded_data: false,
            allemployeedata: {},
            CurrentPeriod: window.moment().format("YYYY-MM"),
            LastDayOfLastMonth: window
                .moment()
                .startOf("month")
                .subtract(1, "day")
                .format("YYYY-MM-DD")
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loaded_data = false;
            employeeService.getRecordOverviewPerEmployeeForAllActive().then(response => {
                this.loaded_data = true;
                this.allemployeedata = response.data;
            });
        }
    },
    computed: {
        ...mapGetters({
            employeesInactive: "employees/employeesInactive"
        }),
        employees_inactive() {
            var ret = [];
            this.employeesInactive.forEach(employee => {
                if (this.$root.userHasEmployee(employee.id)) {
                    ret.push(employee);
                }
            });
            return ret;
        }
    },
    components: {
        EmployeeLinks,
        EmployeeInfoRow
    }
};
</script>
