<template>
    <b-nav tabs align="center">
        <b-nav-item
            :active="isActive('projects-overview')"
            :to="{
                name: 'project.detail',
                params: { id: localProjectId }
            }"
            >Übersicht</b-nav-item
        >
        <b-nav-item
            :active="isActive('projects-analysis')"
            :to="{
                name: 'project.analysis',
                params: { id: localProjectId }
            }"
            >Buchungsauswertung</b-nav-item
        >
        <b-nav-item
            :active="isActive('projects-recordoverview')"
            :to="{
                name: 'project.recordoverview',
                params: { id: localProjectId }
            }"
            >Projektbuchungen
        </b-nav-item>
        <b-nav-item
            :active="isActive('projects-warranties')"
            :to="{
                name: 'project.warranties',
                params: { id: localProjectId }
            }"
            >Gewährleistungen
        </b-nav-item>
        <!-- <b-nav-item-dropdown @TODO check for errors with null
            id="my-nav-dropdown"
            text="Links"
            toggle-class="nav-link-custom"
            right
            v-if="hasLinks"
        >
            <b-dropdown-item :href="project.linkasana" target="_blank" v-if="project.linkasana"
                >Asana&nbsp;<font-awesome-icon icon="external-link-alt"
            /></b-dropdown-item>
            <b-dropdown-item :href="project.linkavanova" target="_blank" v-if="project.linkavanova"
                >Avanova&nbsp;<font-awesome-icon icon="external-link-alt"
            /></b-dropdown-item>
            <b-dropdown-item :href="project.linkbtb" target="_blank" v-if="project.linkbtb"
                >Bautagebuch&nbsp;<font-awesome-icon icon="external-link-alt"
            /></b-dropdown-item>
        </b-nav-item-dropdown> -->
    </b-nav>
</template>
<script>
export default {
    props: {
        active: String,
        project_id: Number
    },
    data() {
        return {
            localProjectId: parseInt(this.project_id)
        };
    },
    methods: {
        isActive(NameOfNavItem) {
            return NameOfNavItem == this.active;
        }
    },
    computed: {
        hasLinks() {
            return this.project.linkbtb || this.project.linkasana || this.project.linkasana;
        },
        project() {
            return this.$root.getProjectObject(this.localProjectId);
        }
    }
};
</script>
