<template>
    <span v-if="editable">
        <b-card class="shadow mb-5" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right">
                    <b-button variant="outline-secondary" size="sm" @click="cancelEditing()">
                        <font-awesome-icon icon="times" />&nbsp;abbrechen
                    </b-button>
                    <b-button variant="outline-primary" size="sm" @click="saveData()">
                        <font-awesome-icon icon="save" />&nbsp;speichern
                    </b-button>
                </b-button-group>
            </h5>

            <!-- <div class="mb-3">
        <small class="text-muted">Unterlagen zum Projekt befindet sich an folgendem Standort:</small>
      </div> -->

            <Loading v-if="documentlocations.loading" />
            <b-form-group v-else>
                <b-form-radio
                    v-for="element in documentlocations.items"
                    v-bind:key="element.id"
                    name="documentlocations"
                    :value="element.id"
                    v-model="selectedDocumentlocation"
                    class="pb-2"
                >
                    {{ element.name }}
                    <br />
                    <small>{{ element.description }}</small>
                </b-form-radio>
            </b-form-group>
        </b-card>
    </span>
    <span v-else>
        <b-card class="card-transparent mb-5  hover-field" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right show-on-hover">
                    <b-button
                        variant="link"
                        size="sm"
                        class="btn-sm-without-padding"
                        @click="startEditing()"
                    >
                        <font-awesome-icon icon="edit" />&nbsp;bearbeiten
                    </b-button>
                </b-button-group>
            </h5>

            <!-- <div class="mb-3">
        <small class="text-muted">Unterlagen zum Projekt befindet sich an folgendem Standort:</small>
      </div> -->

            <span v-if="projectinfo.documentlocation.length > 0">
                {{ projectinfo.documentlocation[0].name }}
                <br />
                <small>{{ projectinfo.documentlocation[0].description }}</small>
            </span>
            <span v-else class="text-muted small"
                >Keine Informationen zum Unterlagenstandort hinterlegt.</span
            >
        </b-card>
    </span>
</template>

<script>
import { projectService } from "../../_services";
export default {
    props: {
        projectinfo: Object
    },
    components: {},
    data: function() {
        return {
            editable: false,
            title: "Unterlagenstandort",
            icon: "thumbtack",
            selectedDocumentlocation: this.projectinfo.documentlocation_id
        };
    },
    methods: {
        cancelEditing() {
            this.editable = false;
            this.selectedDocumentlocation = this.projectinfo.documentlocation_id;
        },
        saveData() {
            var data = {
                documentlocation_id: this.selectedDocumentlocation
            };
            projectService
                .updateOne(this.projectinfo.id, data)
                .then(response => {
                    this.editable = false;
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");
                    this.$root.toast("Das Projekt wurde erfolgreich geändert.", "success");
                })
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht geändert werden.", "danger");
                });
        },
        startEditing() {
            this.editable = true;
        }
    },
    computed: {
        documentlocations() {
            return this.$store.state.projects.documentlocations;
        }
    }
};
</script>
