<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <EmployeeSelector
                title="Arbeitszeiten eintragen für"
                v-model="employee_id"
            ></EmployeeSelector>

            <h1>Meine Übersicht</h1>
        </template>
    </base-layout>
</template>
<script>
import EmployeeSelector from "../components/EmployeeSelector.vue";

export default {
    created() {
        this.employee_id = this.$root.selfEmployeeId;
        if (this.employee_id > 0) {
            this.referTo();
        }
    },
    data() {
        return {
            employee_id: 0
        };
    },
    components: {
        EmployeeSelector
    },
    methods: {
        referTo() {
            this.$router.push({
                name: "employee.detail",
                params: { id: this.employee_id }
            });
        }
    },
    watch: {
        employee_id: function(newVal, oldVal) {
            this.referTo();
        }
    }
};
</script>
