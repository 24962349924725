<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>
                Projekte
                <small class="text-muted">nach Projektstatus</small>
            </h1>
        </template>

        <Loading v-if="statuses.loading" />
        <div v-else>
            <div class="clearfix mb-4">
                <b-form-input
                    ref="projectlistsearchterm"
                    v-model="searchterm"
                    placeholder="nach Projekt suchen"
                ></b-form-input>
                <div class="float-right mt-2">
                    <button class="btn btn-sm btn-link" @click="searchterm = ''">
                        <font-awesome-icon icon="times" />&nbsp;Filter entfernen
                    </button>
                </div>
            </div>
            <b-row>
                <b-col v-for="s in statuses" v-bind:key="s.id">
                    <div>
                        <h4>{{ s.name }}</h4>
                        {{ s.description }}
                        <b-list-group>
                            <!-- <draggable
                                :list="s.projects"
                                group="projects"
                                @end="draggableChange($event)"
                                :data-status-id="s.id"
                            >  -->
                            <b-list-group-item
                                v-for="project in filteredProjects(s.projects)"
                                :key="project.id"
                                :data-project-id="project.id"
                                class="px-2"
                            >
                                <ProjectName :id="project.id" />
                            </b-list-group-item>
                            <!-- </draggable> -->
                        </b-list-group>
                    </div>
                </b-col>
            </b-row>
        </div>
    </base-layout>
</template>
<script>
import draggable from "vuedraggable";
import { projectService } from "../_services";
import { mapGetters } from "vuex";

export default {
    components: {
        //   draggable
    },

    data() {
        return {
            searchterm: ""
        };
    },
    computed: {
        ...mapGetters({
            statuses: "projects/statuses"
        })
    },
    methods: {
        filteredProjects(projects) {
            return this.$root.filteredProjectsAsIds(projects, this.searchterm, true);
        }
    }
};
</script>
