var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[(_vm.type == 'publicholiday')?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"glass-cheers","fixed-width":""}}),_vm._v(" Feiertag "),(_vm.$root.UserHasRight('canManagePublicholidays'))?_c('b-button',{attrs:{"variant":"link","size":"sm","to":{
                    name: 'employees.publicholidays'
                }}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}}),_vm._v(" ändern ")],1):_vm._e()],1):(_vm.type == 'vacationday')?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"suitcase-rolling","fixed-width":""}}),_vm._v(" Urlaub "),_c('b-button',{attrs:{"variant":"link","size":"sm","to":{
                    name: 'my.vacation.withyearandid', //TODO
                    params: { id: 7, year: _vm.year } //TODO
                }}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}}),_vm._v(" ändern ")],1)],1):(_vm.type == 'sickday')?_c('span',[_c('font-awesome-icon',{attrs:{"icon":"bed","fixed-width":""}}),_vm._v(" Krankheit "),_c('b-button',{attrs:{"variant":"link","size":"sm","to":{
                    name: 'employee.sickdays',
                    params: { id: 7 } //TODO
                }}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}}),_vm._v(" ändern ")],1)],1):_vm._e()]),_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("NumberTwoDigits")(_vm.entry.recordvalue_computed)))]),_c('td',[(_vm.type == 'publicholiday')?_c('span',[_vm._v(" "+_vm._s(_vm._f("FactorInfo")(_vm.entry.fraction))+" Feiertag: "+_vm._s(_vm.entry.name)+" ")]):(_vm.type == 'vacationday')?_c('span',[_vm._v(" "+_vm._s(_vm._f("FactorInfo")(_vm.entry.factor))+" Urlaubstag "),(_vm.entry.description != '')?_c('br'):_vm._e(),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.entry.description))])]):(_vm.type == 'sickday')?_c('span',[_vm._v(" "+_vm._s(_vm._f("FactorInfo")(_vm.entry.factor))+" Krankheitstag "),(_vm.entry.description != '')?_c('br'):_vm._e(),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.entry.description))])]):_vm._e()]),_c('td')])
}
var staticRenderFns = []

export { render, staticRenderFns }