<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>
                Mitarbeiter- und Benutzerverwaltung
                <br />
                <span class="font-weight-bold"> Neuen Benutzer anlegen</span>
            </h1>
        </template>

        <template v-slot:page-sub-menu>
            <UsersSubMenu active="users-create" />
        </template>

        <UserDetailForm />
    </base-layout>
</template>
<script>
import UsersSubMenu from "../components/SubMenus/UsersSubMenu.vue";
import UserDetailForm from "../components/UserDetailForm.vue";

export default {
    components: {
        UsersSubMenu,
        UserDetailForm
    }
};
</script>
