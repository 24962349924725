<template>
    <div>
        <div :class="classes">
            <b-spinner type="grow" :style="styles" label="Loading..."></b-spinner>
        </div>

        <div class="text-center" v-if="showreloadbutton">
            <a class="btn btn-outline-primary " :href="baseurl">
                Seite neu laden
            </a>
            <p class="text-muted small mt-3">
                Bitte stellen Sie sicher, dass Sie sich im internen Netzwerk befinden.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        withmargin: {
            type: Boolean,
            required: false,
            default: true
        },
        size: {
            type: String,
            required: false,
            default: "md"
        }
    },
    data() {
        return {
            showreloadbutton: false
        };
    },
    created() {
        setTimeout(
            function() {
                this.showreloadbutton = true;
            }.bind(this),
            7000
        );
    },
    computed: {
        baseurl() {
            return process.env.VUE_APP_BASE_URL;
        },
        classes() {
            var ret = "d-flex justify-content-center";
            if (this.withmargin) {
                ret += " my-5";
            }
            return ret;
        },
        styles() {
            var ret = "";
            if (this.size == "xl") {
                ret += " width: 8rem; height: 8rem; ";
            }
            return ret;
        }
    }
};
</script>
