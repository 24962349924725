import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

Vue.config.productionTip = false;

/**
 * CSS
 */
import sassStyles from "./sass/app.scss";

/**
 * BOOTSTRAP
 */

window.Popper = require("popper.js").default;
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

/**
 * V-CALENDAR
 */
import VCalendar from "v-calendar";

Vue.use(VCalendar, {
    formats: {
        title: "MMMM YYYY",
        weekdays: "W",
        navMonths: "MMM",
        input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
        dayPopover: "L"
    }
});
/**
 * FILTERS
 */

import "./_helpers/filters.js";

/**
 * FONTAWESOME ICONS
 */

import "./_helpers/icons.js";

/**
 *  GLOBAL COMPONENTS
 */

import FeatureMissing from "./components/Global/FeatureMissing.vue";
Vue.component("FeatureMissing", FeatureMissing);
import Loading from "./components/Global/Loading.vue";
Vue.component("Loading", Loading);
import NoEntriesFound from "./components/Global/NoEntriesFound.vue";
Vue.component("NoEntriesFound", NoEntriesFound);
import BaseLayout from "./components/Global/BaseLayout.vue";
Vue.component("BaseLayout", BaseLayout);
import LoadingBox from "./components/Global/LoadingBox.vue";
Vue.component("LoadingBox", LoadingBox);
import StoreCheck from "./components/Global/StoreCheck.vue";
Vue.component("StoreCheck", StoreCheck);
import ProjectName from "./components/Global/ProjectName.vue";
Vue.component("ProjectName", ProjectName);
import EmployeeName from "./components/Global/EmployeeName.vue";
Vue.component("EmployeeName", EmployeeName);
import RightName from "./components/Global/RightName.vue";
Vue.component("RightName", RightName);

import { reverse, sortBy } from "lodash";

new Vue({
    router,
    store,
    render: h => h(App),
    computed: {
        isLoggedIn() {
            return this.$store.state.authentication.user !== null;
        },
        selfEmployeeId() {
            var employee = this.$store.state.employees.user.data.employee_ids.find(
                employee => employee.self == 1
            );
            if (employee != undefined) {
                return employee.employee_id;
            } else {
                return 0;
            }
        }
    },
    methods: {
        isEmpty(myObject) {
            for (var key in myObject) {
                if (myObject.hasOwnProperty(key)) {
                    return false;
                }
            }
            return true;
        },
        EmployeeFullName(employee_id) {
            let obj = this.$store.state.employees.employees.items.find(
                obj => obj.id == employee_id
            );
            if (obj !== undefined) {
                return obj.firstname + " " + obj.lastname;
            }
            return "";
        },
        EmployeeBaseColor(employee_id) {
            let obj = this.$store.state.employees.employees.items.find(
                obj => obj.id == employee_id
            );
            if (obj !== undefined) {
                return obj.basecolor;
            }
            return "#a7a7a7";
        },
        EmployeeIsFreelancer(employee_id) {
            let obj = this.$store.state.employees.employees.items.find(
                obj => obj.id == employee_id
            );
            if (obj !== undefined) {
                return obj.currentstatus == 2;
            }
            return false;
        },
        EmployeeIsEmployed(employee_id) {
            let obj = this.$store.state.employees.employees.items.find(
                obj => obj.id == employee_id
            );
            if (obj !== undefined) {
                return obj.currentstatus == 1;
            }
            return false;
        },
        EmployeeIsInactive(employee_id) {
            let obj = this.$store.state.employees.employees.items.find(
                obj => obj.id == employee_id
            );
            if (obj !== undefined) {
                return obj.currentstatus == 0;
            }
            return false;
        },
        getProjectObject(project_id) {
            let obj = this.$store.state.projects.projects.items.find(obj => obj.id == project_id);
            if (obj !== undefined) {
                return obj;
            }
            return null;
        },
        getEmployeeObject(employee_id) {
            let obj = this.$store.state.employees.employees.items.find(
                obj => obj.id == employee_id
            );
            if (obj !== undefined) {
                return obj;
            }
            return null;
        },
        getRightObject(right_id) {
            let obj = this.$store.state.employees.rights.items.find(obj => obj.id == right_id);
            if (obj !== undefined) {
                return obj;
            }
            return null;
        },
        getUserObject(user_id) {
            let obj = this.$store.state.employees.users.items.find(obj => obj.id == user_id);
            if (obj !== undefined) {
                return obj;
            }
            return null;
        },
        StatusFullTitle(status_id) {
            let obj = this.$store.state.projects.statuses.items.find(obj => obj.id == status_id);
            if (obj !== undefined) {
                return obj.name;
            }
            return "";
        },
        DocumentlocationFullTitle(documentlocation_id) {
            let obj = this.$store.state.projects.documentlocations.items.find(
                obj => obj.id == documentlocation_id
            );
            if (obj !== undefined) {
                return obj.name;
            }
            return "";
        },
        ProjectroleFullTitle(projectrole_id) {
            let obj = this.$store.state.projects.projectroles.items.find(
                obj => obj.id == projectrole_id
            );
            if (obj.name == "Mitarbeit") {
                return "";
            }
            if (obj !== undefined) {
                return obj.name;
            }
            return "";
        },
        getDuration(starttime, endtime) {
            return (
                window.moment.duration(endtime).subtract(window.moment.duration(starttime)) /
                3600000
            );
        },
        userHasAnyEmployees() {
            return this.$store.state.employees.user.data.employee_ids.length > 0;
        },
        userHasSelf() {
            let emp = this.$store.state.employees.user.data.employee_ids.find(obj => obj.self == 1);
            if (emp == undefined) {
                return false;
            } else {
                return true;
            }
        },
        userHasManagerRoleForEmployee(employee_id) {
            let emp = this.$store.state.employees.user.data.employee_ids.find(
                obj => obj.employee_id == employee_id
            );
            if (emp == undefined) {
                return false;
            }
            return emp.role == "manager";
        },
        userHasManagedRoleForEmployee(employee_id) {
            let emp = this.$store.state.employees.user.data.employee_ids.find(
                obj => obj.employee_id == employee_id
            );
            if (emp == undefined) {
                return false;
            }
            return emp.role == "managed";
        },
        userHasEmployee(employee_id) {
            let emp = this.$store.state.employees.user.data.employee_ids.find(
                obj => obj.employee_id == employee_id
            );
            if (emp == undefined) {
                return false;
            }
            return emp.role == "managed" || emp.role == "manager";
        },
        itsMe(employee_id) {
            let emp = this.$store.state.employees.user.data.employee_ids.find(
                obj => obj.employee_id == employee_id
            );
            if (emp == undefined) {
                return false;
            }
            return emp.self;
        },
        toast(message, variant) {
            this.$bvToast.toast(message, {
                toaster: "b-toaster-bottom-left",
                variant: variant,
                solid: true,
                appendToast: true
            });
        },
        toastWithTitle(title, message, variant) {
            this.$bvToast.toast(message, {
                toaster: "b-toaster-bottom-left",
                variant: variant,
                title: title,
                solid: true,
                appendToast: true
            });
        },
        generateGoogleMapsLink(city, street) {
            return "https://maps.google.com/?q=" + city + "+" + street;
        },
        UserHasRight(name) {
            if (
                this.$store.state.employees.user.data !== undefined &&
                this.$store.state.employees.user.data.right_ids !== undefined
            ) {
                var right = this.$store.state.employees.rights.items.find(
                    right => right.name.toLowerCase() == name.toLowerCase()
                );
                if (right === undefined) {
                    return false;
                }
                return this.$store.state.employees.user.data.right_ids.includes(right.id);
            } else {
                return false;
            }
        },
        hexToRgbA(hex, opacity) {
            var c;
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split("");
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                }
                c = "0x" + c.join("");
                return (
                    "rgba(" +
                    [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                    "," +
                    opacity +
                    ")"
                );
            }
            throw new Error("Bad Hex");
        },
        filteredProjects(projects, searchterm, orderit = true) {
            searchterm = searchterm.toLowerCase();
            if (projects === undefined) {
                return;
            }
            if (searchterm == "") {
                if (orderit) {
                    projects = sortBy(projects, [
                        function(o) {
                            return o.number;
                        }
                    ]);
                    reverse(projects);
                }
                return projects;
            }
            let filtered = [];
            projects.forEach(project => {
                if (
                    project.number.toLowerCase().indexOf(searchterm) >= 0 ||
                    project.name.toLowerCase().indexOf(searchterm) >= 0 ||
                    project.locationcity.toLowerCase().indexOf(searchterm) >= 0 ||
                    project.client.toLowerCase().indexOf(searchterm) >= 0
                ) {
                    filtered.push(project);
                }
            });
            if (orderit) {
                filtered = sortBy(filtered, [
                    function(o) {
                        return o.number;
                    }
                ]);
                reverse(filtered);
            }
            return filtered;
        },
        filteredProjectsAsIds(projectids, searchterm, orderit = true) {
            var projects = [];
            for (var i = 0; i < projectids.length; i++) {
                projects.push(this.getProjectObject(projectids[i]));
            }
            return this.filteredProjects(projects, searchterm, orderit);
        }
    }
}).$mount("#app");
