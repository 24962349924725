var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-layout',{attrs:{"fluid":""},scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_c('EmployeeSelector',{attrs:{"title":"Urlaub eintragen für"},model:{value:(_vm.employee_id),callback:function ($$v) {_vm.employee_id=$$v},expression:"employee_id"}}),_c('YearSelector',{staticClass:"float-right",attrs:{"allyears":false,"showcurrentyear":false},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_c('h1',[_vm._v("Urlaubsplaner")])]},proxy:true}])},[(!_vm.loaded)?_c('div',{staticClass:"d-flex justify-content-center mt-5 mb-5"},[_c('b-spinner',{attrs:{"label":"loaded..."}})],1):_c('b-row',[_c('b-col',{staticClass:"d-print-none"},[_c('VacationPicker',{attrs:{"year":_vm.year,"vacationdays":_vm.vacdates,"publicholidays":_vm.publicholidaydata.publicholidays,"employee_id":_vm.employee_id},on:{"vacationday-changed":function($event){return _vm.onVacationDayChanged($event)}}})],1),_c('b-col',[_c('div',{staticClass:"overflow-auto"},[(_vm.vacationdata.vacationdays.length > 0)?_c('table',{staticClass:"table table-sm"},[_c('tbody',_vm._l((_vm.vacationdata.vacationdays),function(vacationday){return _c('tr',{key:vacationday.date},[_c('td',[_vm._v(" "+_vm._s(_vm._f("DateLong")(vacationday.date))+" "),(vacationday.date >= _vm.today)?_c('p',{staticClass:"small text-muted"},[_vm._v(" geplant ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(_vm._f("DayFractions")(vacationday.factor))+" "),(
                                        _vm.userHasManagerRoleForEmployee ||
                                            vacationday.date >= _vm.today
                                    )?_c('br'):_vm._e(),(
                                        _vm.userHasManagerRoleForEmployee ||
                                            vacationday.date >= _vm.today
                                    )?_c('b-button',{staticClass:"p-0",attrs:{"size":"sm","variant":"link"},on:{"click":function($event){return _vm.changeFactor(vacationday.date)}}},[_vm._v(" ändern ")]):_vm._e()],1),_c('td',[(
                                        _vm.userHasManagerRoleForEmployee ||
                                            vacationday.date >= _vm.today
                                    )?_c('button',{staticClass:"btn btn-link btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteEntry(vacationday.date)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1):_vm._e()])])}),0),_c('tfoot',[_c('th'),_c('th',[_vm._v(_vm._s(_vm._f("NumberOneDigit")(_vm.computeNumberOfVacationdays()))+" Tage")]),_c('th')])]):_vm._e()])]),_c('b-col',[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-header"},[_vm._v("Übersicht Urlaubstage")]),_c('VacationOverview',{attrs:{"employeevacationdays":_vm.vacationdata}})],1)])],1),_c('b-navbar',{staticClass:"zweige-fixed-bottom-container",attrs:{"toggleable":"lg","fixed":"bottom"}},[_c('div',{staticClass:"ml-auto"},[_c('b-button-group',{staticClass:"mt-4 mb-4"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.resetVacationData()}}},[_vm._v("zurücksetzen")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateVacationData()}}},[_c('font-awesome-icon',{attrs:{"icon":"save"}}),_vm._v(" Einträge speichern ")],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }