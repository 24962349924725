import { projectService } from "../_services";
import { basedataService } from "../_services";

export const projects = {
    namespaced: true,
    state: {
        projects: {
            items: [],
            loading: true,
            error: null
        },
        statuses: {
            items: [],
            loading: true,
            error: null
        },
        documentlocations: {
            items: [],
            loading: true,
            error: null
        },
        services: {
            items: [],
            loading: true,
            error: null
        },
        projectroles: {
            items: [],
            loading: true,
            error: null
        },
        cars: {
            items: [],
            loading: true,
            error: null
        }
    },
    getters: {
        favorites() {
            return projects.state.projects.items.filter(project => project.isfavorite == true);
        },
        withProjectEmployees() {
            return projects.state.projects.items.filter(
                project => project.projectemployees_ids.length > 0
            );
        },
        withWarranties() {
            //@todo change to ids.length after api change
            return projects.state.projects.items.filter(
                project => project.numberofopenwarranties > 0
            );
        },
        withServices() {
            return projects.state.projects.items.filter(project => project.services_ids.length > 0);
        },
        projects() {
            return projects.state.projects.items;
        },
        statuses() {
            var ret = [];
            for (var i = 0; i < projects.state.statuses.items.length; i++) {
                var s = projects.state.statuses.items[i];
                s.projects = [];
                for (var j = 0; j < projects.state.projects.items.length; j++) {
                    if (
                        projects.state.statuses.items[i].id ==
                        projects.state.projects.items[j].status_id
                    ) {
                        s.projects.push(projects.state.projects.items[j].id);
                    }
                }
                ret.push(s);
            }
            return ret;
        },
        documentlocations() {
            var ret = [];
            for (var i = 0; i < projects.state.documentlocations.items.length; i++) {
                var s = projects.state.documentlocations.items[i];
                s.projects = [];
                for (var j = 0; j < projects.state.projects.items.length; j++) {
                    if (
                        projects.state.documentlocations.items[i].id ==
                        projects.state.projects.items[j].documentlocation_id
                    ) {
                        s.projects.push(projects.state.projects.items[j].id);
                    }
                }
                ret.push(s);
            }
            return ret;
        },
        services() {
            return projects.state.services.items;
        },
        projectroles() {
            return projects.state.projectroles.items;
        },
        oneProject: state => id => {
            return 7;
            // return state.projects.items.find(project => project.id == id);
        }
    },
    actions: {
        getAll({ commit }) {
            commit("getInitRequest");
            basedataService.getProjectBasedata().then(
                basedata => commit("getInitSuccess", basedata),
                error => commit("getInitFailure", error)
            );
        },
        reload({ commit }) {
            commit("reloadRequest");
            basedataService.getProjectBasedata().then(
                basedata => commit("reloadSuccess", basedata),
                error => commit("reloadFailure", error)
            );
        }
    },
    mutations: {
        getInitRequest(state) {
            state.projects.loading = state.statuses.loading = state.documentlocations.loading = state.services.loading = state.projectroles.loading = state.cars.loading = true;
            state.projects.error = state.statuses.error = state.documentlocations.error = state.services.error = state.projectroles.error = state.cars.error = null;
        },
        getInitSuccess(state, basedata) {
            state.projects.items = basedata.projects;
            state.statuses.items = basedata.statuses;
            state.documentlocations.items = basedata.documentlocations;
            state.services.items = basedata.services;
            state.projectroles.items = basedata.projectroles;
            state.cars.items = basedata.cars;

            state.projects.loading = state.statuses.loading = state.documentlocations.loading = state.services.loading = state.projectroles.loading = state.cars.loading = false;
            state.projects.error = state.statuses.error = state.documentlocations.error = state.services.error = state.projectroles.error = state.cars.error = null;
        },
        getInitFailure(state, error) {
            state.projects.loading = state.statuses.loading = state.documentlocations.loading = state.services.loading = state.projectroles.loading = state.cars.loading = false;
            state.projects.error = state.statuses.error = state.documentlocations.error = state.services.error = state.projectroles.error = state.cars.error = error;
        },
        integrateOne(state, { id, data }) {
            var newitems = [];
            var integrated = false;
            if (id == 0) {
                state.projects.items.push(data);
                integrated = true;
            }
            if (!integrated) {
                for (var i = 0; i < state.projects.items.length; i++) {
                    if (state.projects.items[i].id == id) {
                        if (data.length > 0 || data.id == id) {
                            newitems.push(data);
                            integrated = true;
                        }
                    } else {
                        newitems.push(state.projects.items[i]);
                    }
                }
                state.projects.items = newitems;
            }
        }
    }
};
