<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1 class="mt-2">
                Gewährleistungsfristen
                <br />
                <span class="font-weight-bold">
                    <ProjectName :id="project_id" :icon="false" />
                </span>
            </h1>
        </template>

        <template v-slot:page-sub-menu>
            <ProjectSubMenu active="projects-warranties" :project_id="project_id" />
        </template>

        <div>
            <div class="card">
                <div class="card-body">
                    <b-button
                        variant="outline-secondary"
                        size="sm"
                        class="mb-1 mt-1"
                        @click="makeEmptyEntry()"
                    >
                        <font-awesome-icon icon="plus-square" />&nbsp;Gewährleistungseintrag
                        hinzufügen
                    </b-button>
                </div>

                <Loading v-if="loading" />

                <table v-else class="table mb-0 table-without-top-line">
                    <thead>
                        <tr>
                            <th style="width:30%">Gewerk/Unternehmen</th>
                            <th style="width:20%">Abnahmedatum</th>
                            <th style="width:20%">Ablaufdatum</th>
                            <!-- <th>Erinnerungen</th> -->
                            <th style="width:20%"></th>
                            <th style="width:10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <NoEntriesFound :colspan="6" v-if="allwarranties.length == 0" />
                        <WarrantyTableEntry
                            v-if="emptyEntry != null"
                            :warranty="emptyEntry"
                            :initeditmode="true"
                            :newentrymode="true"
                            :withproject="false"
                            v-on:warranty-cancelled="emptyEntry = null"
                            v-on:warranty-created="
                                newEntry($event);
                                emptyEntry = null;
                            "
                        />
                        <WarrantyTableEntry
                            v-for="warranty in allwarranties"
                            v-bind:key="warranty.id"
                            :warranty="warranty"
                            :initeditmode="false"
                            :withproject="false"
                            v-on:warranty-deleted="entryDeleted($event)"
                        />
                    </tbody>
                </table>
            </div>
        </div>
    </base-layout>
</template>
<script>
import { projectService } from "../../_services";
import ProjectSubMenu from "../../components/SubMenus/ProjectSubMenu.vue";
import WarrantyTableEntry from "../../components/WarrantyTableEntry.vue";

export default {
    components: { WarrantyTableEntry, ProjectSubMenu },
    data() {
        return {
            loading: false,
            project_id: parseInt(this.$route.params.id),
            emptyEntry: null,
            allwarranties: []
        };
    },
    created() {
        this.fetchData();
    },
    computed: {},
    methods: {
        makeEmptyEntry() {
            this.emptyEntry = {
                project_id: this.project_id,
                company: "",
                craft: "",
                description: "",
                date_acceptance: window.moment().format("YYYY-MM-DD"),
                date_warrantyend: window.moment().format("YYYY-MM-DD"),
                done: false
            };
        },
        fetchData() {
            this.loading = true;
            projectService.getWarrantiesOfProject(this.project_id).then(response => {
                this.loading = false;
                this.allwarranties = response.data.warranties;
            });
        },
        newEntry(event) {
            this.allwarranties.push(event.data);
        },
        entryDeleted(event) {
            var deletedid = event.id;
            var newentries = this.allwarranties.filter(element => element.id != deletedid);
            this.allwarranties = newentries;
        }
    }
};
</script>
