<template>
    <tr>
        <td v-if="withproject">
            <ProjectName :id="warranty.project_id" />
        </td>

        <td v-if="editmode">
            <b-form-input v-model="warranty.craft" placeholder="Gewerk" class="mb-2"></b-form-input>
            <b-form-input v-model="warranty.company" placeholder="Firma"></b-form-input>
        </td>
        <td v-else>
            {{ warranty.craft }}
            <span v-if="warranty.company"> ({{ warranty.company }})</span>
            <span v-if="localdone">
                <br />
                <b-badge variant="success">
                    <font-awesome-icon icon="check" />&nbsp;erledigt
                </b-badge>
            </span>
            <span v-if="isOverdueSoon(warranty.date_warrantyend, localdone)">
                <br />
                <b-badge variant="warning">
                    <font-awesome-icon icon="check" />&nbsp;bald fällig
                </b-badge>
            </span>
            <span v-if="isOverdue(warranty.date_warrantyend, localdone)">
                <br />
                <b-badge variant="danger">
                    <font-awesome-icon icon="check" />&nbsp;überfällig
                </b-badge>
            </span>
        </td>

        <td v-if="editmode">
            <DatePicker v-model="warranty.date_acceptance" />
        </td>
        <td v-else>
            {{ warranty.date_acceptance | DateFullShort }}
        </td>

        <td v-if="editmode">
            <DatePicker v-model="warranty.date_warrantyend" />
        </td>
        <td v-else>
            {{ warranty.date_warrantyend | DateFullShort }}
            <p class="small text-muted">
                {{ humanizeTimeUntil(warranty.date_warrantyend) }}
            </p>
        </td>

        <!-- <td>
            <div v-for="reminder in warranty.reminders" v-bind:key="reminder.id">
                {{ humanizeTimeDiff(reminder.date_reminder, warranty.date_warrantyend) }}
                vorher
                <p class="small text-muted">am {{ reminder.date_reminder | DateFullShort }}</p>
            </div>
        </td> -->

        <td v-if="!editmode">
            <span v-if="localdone">
                <b-button variant="outline-secondary" size="sm" @click="makeOpen()">
                    <font-awesome-icon icon="redo" fixed-width />&nbsp;wieder eröffnen
                </b-button>
            </span>
            <span v-else>
                <b-button variant="outline-primary" size="sm" @click="makeDone()">
                    <font-awesome-icon icon="check" fixed-width />&nbsp;als erledigt markieren
                </b-button>
            </span>
        </td>

        <td v-if="editmode" colspan="2">
            <b-button-group class="float-right">
                <b-button variant="outline-secondary" size="sm" @click="cancelEditing()">
                    <font-awesome-icon icon="times" />&nbsp;abbrechen
                </b-button>
                <b-button variant="outline-primary" size="sm" @click="saveData()">
                    <font-awesome-icon icon="save" />&nbsp;speichern
                </b-button>
            </b-button-group>
        </td>
        <td v-else>
            <b-button-group class="float-right">
                <b-button size="sm" variant="link" @click="startEditing()">
                    <font-awesome-icon icon="edit" />
                </b-button>
                <b-button size="sm" variant="link" @click="deleteEntry()">
                    <font-awesome-icon icon="trash-alt" />
                </b-button>
            </b-button-group>
        </td>
    </tr>
</template>
<script>
import { projectService } from "../_services";
import DatePicker from "./Global/DatePicker.vue";

export default {
    props: {
        warranty: Object,
        initeditmode: Boolean,
        withproject: Boolean,
        newentrymode: Boolean
    },
    components: {
        DatePicker
    },
    data() {
        return {
            localdone: this.warranty.done == 1 ? true : false,
            editmode: this.initeditmode == true ? true : false
        };
    },
    created() {},
    methods: {
        humanizeTimeUntil(date) {
            return window.moment
                .duration(
                    window
                        .moment(date)
                        .startOf("day")
                        .diff(window.moment().startOf("day"))
                )
                .humanize(true);
        },
        humanizeTimeDiff(date1, date2) {
            return window.moment.duration(window.moment(date1).diff(date2)).humanize();
        },
        isOverdue(date, done) {
            if (done) {
                return false;
            }
            return window.moment().startOf("day") > window.moment(date).startOf("day");
        },
        isOverdueSoon(date, done) {
            if (done) {
                return false;
            }
            if (this.isOverdue(date, done)) {
                return false;
            }
            return (
                window
                    .moment()
                    .startOf("day")
                    .add(1, "month") > window.moment(date).startOf("day")
            );
        },
        cancelEditing() {
            this.$emit("warranty-cancelled");
            this.editmode = false;
        },
        saveData() {
            if (this.newentrymode) {
                projectService
                    .createWarranty(this.warranty)
                    .then(response => {
                        this.editmode = false;
                        this.$emit("warranty-created", response);
                        this.$root.toast(
                            "Die Gewährleistung wurde erfolgreich erstellt.",
                            "success"
                        );
                    })
                    .catch(error => {
                        this.$root.toast(
                            "Die Gewährleistung konnte nicht erstellt werden.",
                            "danger"
                        );
                    });
            } else {
                projectService
                    .updateWarranty(this.warranty.id, this.warranty)
                    .then(response => {
                        this.editmode = false;
                        this.$emit("warranty-updated");
                        this.$root.toast(
                            "Die Gewährleistung wurde erfolgreich geändert.",
                            "success"
                        );
                    })
                    .catch(error => {
                        this.$root.toast(
                            "Die Gewährleistung konnte nicht geändert werden.",
                            "danger"
                        );
                    });
            }
        },
        startEditing() {
            this.editmode = true;
        },
        makeDone() {
            this.localdone = true;
            projectService
                .updateWarranty(this.warranty.id, { done: true })
                .then(response => {
                    this.editmode = false;
                    this.$emit("warranty-updated", response);
                    this.$root.toast(
                        "Die Gewährleistung wurde erfolgreich abgeschlossen.",
                        "success"
                    );
                })
                .catch(error => {
                    this.$root.toast(
                        "Die Gewährleistung konnte nicht abgeschlossen werden.",
                        "danger"
                    );
                });
        },
        makeOpen() {
            this.localdone = false;
            projectService
                .updateWarranty(this.warranty.id, { done: false })
                .then(response => {
                    this.editmode = false;
                    this.$emit("warranty-updated", response);
                    this.$root.toast(
                        "Die Gewährleistung wurde erfolgreich neu geöffnet.",
                        "success"
                    );
                })
                .catch(error => {
                    this.$root.toast(
                        "Die Gewährleistung konnte nicht neu geöffnet werden.",
                        "danger"
                    );
                });
        },
        deleteEntry() {
            projectService
                .deleteWarranty(this.warranty.id)
                .then(response => {
                    this.editmode = false;
                    this.$emit("warranty-deleted", this.warranty);
                    this.$root.toast("Die Gewährleistung wurde erfolgreich gelöscht.", "success");
                })
                .catch(error => {
                    this.$root.toast("Die Gewährleistung konnte nicht gelöscht werden.", "danger");
                });
        }
    }
};
</script>
