// import config from 'config';
import { authHeader } from "../_helpers";

export const basedataService = {
    getProjectBasedata,
    getEmployeeBasedata
};

function getProjectBasedata() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_URL + `basedata/projects`, requestOptions).then(
        handleResponse
    );
}
function getEmployeeBasedata() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_URL + `basedata/employees`, requestOptions).then(
        handleResponse
    );
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
