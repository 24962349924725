<template>
    <tr>
        <td>
            <span v-if="type == 'publicholiday'">
                <font-awesome-icon icon="glass-cheers" fixed-width />&nbsp;Feiertag
                <b-button
                    v-if="$root.UserHasRight('canManagePublicholidays')"
                    variant="link"
                    size="sm"
                    class=""
                    :to="{
                        name: 'employees.publicholidays'
                    }"
                >
                    <font-awesome-icon icon="edit" />&nbsp;ändern
                </b-button>
            </span>
            <span v-else-if="type == 'vacationday'">
                <font-awesome-icon icon="suitcase-rolling" fixed-width />&nbsp;Urlaub
                <b-button
                    variant="link"
                    size="sm"
                    class=""
                    :to="{
                        name: 'my.vacation.withyearandid', //TODO
                        params: { id: 7, year: year } //TODO
                    }"
                >
                    <font-awesome-icon icon="edit" />&nbsp;ändern
                </b-button>
            </span>
            <span v-else-if="type == 'sickday'">
                <font-awesome-icon icon="bed" fixed-width />&nbsp;Krankheit
                <b-button
                    variant="link"
                    size="sm"
                    class=""
                    :to="{
                        name: 'employee.sickdays',
                        params: { id: 7 } //TODO
                    }"
                >
                    <font-awesome-icon icon="edit" />&nbsp;ändern
                </b-button>
            </span>
        </td>
        <td colspan="2"></td>
        <td class="text-right">{{ entry.recordvalue_computed | NumberTwoDigits }}</td>
        <td>
            <span v-if="type == 'publicholiday'">
                {{ entry.fraction | FactorInfo }} Feiertag: {{ entry.name }}
            </span>
            <span v-else-if="type == 'vacationday'">
                {{ entry.factor | FactorInfo }} Urlaubstag
                <br v-if="entry.description != ''" />
                <small class="text-muted">{{ entry.description }}</small>
            </span>
            <span v-else-if="type == 'sickday'">
                {{ entry.factor | FactorInfo }} Krankheitstag
                <br v-if="entry.description != ''" />
                <small class="text-muted">{{ entry.description }}</small>
            </span>
        </td>
        <td></td>
    </tr>
</template>

<script>
export default {
    props: {
        type: String,
        entry: Object
    },
    data() {
        return {
            year: parseInt(window.moment(this.entry.date, "YYYY-MM-DD").format("YYYY"))
        };
    }
};
</script>
