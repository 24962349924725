<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>
                Mitarbeiter- und Benutzerverwaltung
                <br />
                <span class="font-weight-bold"> Benutzer bearbeiten</span>
            </h1>
        </template>

        <template v-slot:page-sub-menu>
            <UsersSubMenu active="users-overview" />
        </template>

        <UserDetailForm :user_id="user_id" />
    </base-layout>
</template>

<script>
import UserDetailForm from "../components/UserDetailForm.vue";
import UsersSubMenu from "../components/SubMenus/UsersSubMenu.vue";

export default {
    components: {
        UserDetailForm,
        UsersSubMenu
    },
    data() {
        return {
            user_id: parseInt(this.$route.params.id)
        };
    }
};
</script>
