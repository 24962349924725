<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>
                Gewährleistungsfristen
            </h1>
        </template>

        <template v-slot:page-sub-menu> </template>

        <b-alert show variant="info" class="mb-5">
            Gewährleistungsfristen können beim jeweiligen Projekt im Tab "Gewährleistungen" gepflegt
            werden.
        </b-alert>

        <b-form-group>
            <b-form-radio-group
                v-model="display_selected"
                :options="display_options"
            ></b-form-radio-group>
        </b-form-group>

        <Loading v-if="loading" />

        <div class="card" v-else>
            <table class="table table-without-top-line mt-0">
                <thead>
                    <tr>
                        <th style="width:20%">Projekt</th>
                        <th style="width:20%">Gewerk/Unternehmen</th>
                        <th style="width:15%">Abnahmedatum</th>
                        <th style="width:15%">Ablaufdatum</th>
                        <!-- <th>Erinnerungen</th> -->
                        <th style="width:20%"></th>
                        <th style="width:10%"></th>
                    </tr>
                </thead>
                <tbody>
                    <NoEntriesFound :colspan="6" v-if="allwarranties_filtered.length == 0" />
                    <WarrantyTableEntry
                        v-else
                        v-for="warranty in allwarranties_filtered"
                        v-bind:key="warranty.id"
                        :warranty="warranty"
                        :initeditmode="false"
                        :withproject="true"
                        v-on:warranty-deleted="entryDeleted($event)"
                    />
                </tbody>
            </table>
        </div>
    </base-layout>
</template>
<script>
import { projectService } from "../_services";
import WarrantyTableEntry from "../components/WarrantyTableEntry.vue";

export default {
    components: { WarrantyTableEntry },
    created() {
        this.fetchData();
    },
    data() {
        return {
            loading: false,
            allwarranties: [],
            display_options: [
                { value: 1, text: "offene Gewährleistungen anzeigen" },
                { value: 2, text: "erledigte Gewährleistungen anzeigen" },
                { value: 3, text: "alle Gewährleistungen anzeigen" }
            ],
            display_selected: 1
        };
    },
    methods: {
        fetchData() {
            this.loading = true;
            projectService.getWarranties().then(response => {
                this.loading = false;
                this.allwarranties = response.data.warranties;
            });
        },
        entryDeleted(event) {
            var deletedid = event.id;
            var newentries = this.allwarranties.filter(element => element.id != deletedid);
            this.allwarranties = newentries;
        }
    },
    computed: {
        allwarranties_filtered() {
            if (this.display_selected == 1) {
                return this.allwarranties.filter(
                    element => element.done == 0 || element.done == false
                );
            } else if (this.display_selected == 2) {
                return this.allwarranties.filter(
                    element => element.done == 1 || element.done == true
                );
            } else {
                return this.allwarranties;
            }
        }
    },
    watch: {
        display_selected() {
            this.fetchData();
        }
    }
};
</script>
