<template>
    <span class="float-right">
        <span v-if="this.selectedEmployee == 0">
            <b-button v-b-modal="'modal' + id" variant="secondary" size="sm">
                <font-awesome-icon icon="user" />&nbsp;Mitarbeiter wählen
            </b-button>
        </span>
        <span v-else>
            <font-awesome-icon icon="user" fixed-width />&nbsp;
            <EmployeeName :id="value" :dot="false" />
            <b-button v-b-modal="'modal' + id" variant="link" size="sm" class="d-print-none">
                <font-awesome-icon icon="edit" />&nbsp;ändern
            </b-button>
        </span>

        <b-modal :id="'modal' + id" :title="title" hide-footer>
            <div
                class="form-check"
                v-for="employee in employeelist"
                v-bind:key="employee.id"
                v-on:change="onChangeEmployee"
            >
                <input
                    class="form-check-input"
                    type="radio"
                    name="'p_' + employee.id"
                    v-model="selectedEmployee"
                    :value="employee.id"
                    :id="'p_' + employee.id"
                />
                <label class="form-check-label" :for="'p_' + employee.id"
                    >{{ employee.firstname }} {{ employee.lastname }}</label
                >
            </div>
        </b-modal>
    </span>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true,
            default: 0
        },
        title: {
            type: String,
            required: false,
            default: "Mitarbeiter wählen"
        },
        id: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            selectedEmployee: this.value
        };
    },
    computed: {
        employeelist() {
            return this.$store.state.employees.employees.items;
        }
    },
    methods: {
        onChangeEmployee(event) {
            this.$emit("input", this.selectedEmployee);
            this.$bvModal.hide("modal" + this.id);
        }
    },
    watch: {
        value: function(newVal, oldVal) {
            this.selectedEmployee = newVal;
        }
    }
};
</script>
