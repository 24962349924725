<template>
    <div class="zweige-rpepd-date-selection btn-toolbar justify-content-center" role="toolbar">
        <div class="btn-group">
            <b-button
                variant="link"
                class="zweige-rpepd-kw zweige-rpepd-kw-minus"
                v-on:click="shiftDate(-7)"
            >
                <span class="zweige-rpepd-kwlabel">KW</span>
                <span class="zweige-rpepd-kwnumber">{{ previousWeekOfYear(selectedDate) }}</span>
            </b-button>
            <b-button
                class="zweige-rpepd-previous-day"
                variant="link"
                v-for="prev in previousDays(selectedDate)"
                v-bind:key="prev._d.toString()"
                v-on:click="setDate(prev._d)"
                >{{ prev.format("dd") }}</b-button
            >
            <b-button variant="link" v-on:click="shiftDate(-1)">
                <span class="zweige-rpepd-left ml-2">
                    <font-awesome-icon icon="chevron-left" />
                </span>
            </b-button>
            <b-button variant="link" disabled class="zweige-rpepd-current-day">{{
                selectedDate | moment("dd, D. MMMM YYYY")
            }}</b-button>
            <b-button variant="link" v-on:click="shiftDate(1)">
                <span class="zweige-rpepd-right mr-2">
                    <font-awesome-icon icon="chevron-right" />
                </span>
            </b-button>
            <b-button
                class="zweige-rpepd-next-day"
                v-for="next in nextDays(selectedDate)"
                v-bind:key="next._d.toString()"
                v-on:click="setDate(next._d)"
                variant="link"
                >{{ next.format("dd") }}</b-button
            >
            <b-button
                variant="link"
                class="zweige-rpepd-kw zweige-rpepd-kw-plus"
                v-on:click="shiftDate(7)"
            >
                <span class="zweige-rpepd-kwlabel">KW</span>
                <span class="zweige-rpepd-kwnumber">{{ nextWeekOfYear(selectedDate) }}</span>
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        date: String
    },

    data: function() {
        return {
            selectedDate: window.moment(this.date).format("YYYY-MM-DD")
        };
    },
    methods: {
        previousDays: function(selectedDate) {
            var dayOfWeek = window.moment(selectedDate).format("E");
            var prevs = Array();
            for (var i = dayOfWeek - 1; i >= 1; i--) {
                var curr = window.moment(selectedDate).subtract(i, "day");
                prevs.push(curr);
            }
            return prevs;
        },
        nextDays: function(selectedDate) {
            var dayOfWeek = window.moment(selectedDate).format("E");
            var prevs = Array();
            for (var i = 1; i <= 7 - dayOfWeek; i++) {
                var curr = window.moment(selectedDate).add(i, "day");
                prevs.push(curr);
            }
            return prevs;
        },
        previousWeekOfYear: function(selectedDate) {
            return window
                .moment(selectedDate)
                .subtract(7, "day")
                .format("ww");
        },
        nextWeekOfYear: function(selectedDate) {
            return window
                .moment(selectedDate)
                .add(7, "day")
                .format("ww");
        },
        shiftDate: function(changeDays) {
            this.selectedDate = window
                .moment(this.selectedDate)
                .add(changeDays, "day")
                .format("YYYY-MM-DD");
            this.onChangeDate();
        },
        setDate: function(newDate) {
            this.selectedDate = window.moment(newDate).format("YYYY-MM-DD");
            this.onChangeDate();
        },
        keyNavigation: function(e) {
            // if (e.code == "ArrowRight") {
            //   this.shiftDate(1);
            // } else if (e.code == "ArrowLeft") {
            //   this.shiftDate(-1);
            // }
        },
        onChangeDate() {
            this.$emit("date-selected", this.selectedDate);
        }
    },
    created: function() {
        window.addEventListener("keydown", this.keyNavigation);
    },
    beforeDestroy: function() {
        // window.removeEventListener("keydown", this.keyNavigation);
    }
};
</script>
