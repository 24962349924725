var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-nav',{attrs:{"tabs":"","align":"center"}},[_c('b-nav-item',{attrs:{"active":_vm.isActive('employee-detail'),"to":{
            name: 'employee.detail',
            params: { id: _vm.employee_id }
        }}},[_vm._v("Übersicht")]),_c('b-nav-item',{attrs:{"active":_vm.isActive('employee-monthlysums'),"to":{
            name: 'employee.monthlysums',
            params: { id: _vm.employee_id }
        }}},[_vm._v("Monatsübersichten")]),_c('b-nav-item',{attrs:{"active":_vm.isActive('employee-vacationdays'),"to":{
            name: 'employee.vacationdays',
            params: { id: _vm.employee_id }
        }}},[_vm._v("Urlaubstage ")]),_c('b-nav-item',{attrs:{"active":_vm.isActive('employee-sickdays'),"to":{
            name: 'employee.sickdays',
            params: { id: _vm.employee_id }
        }}},[_vm._v("Krankheitstage ")]),_c('b-nav-item',{attrs:{"active":_vm.isActive('employee-publicholidays'),"to":{
            name: 'employee.publicholidays',
            params: { id: _vm.employee_id }
        }}},[_vm._v("Feiertage ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }