<template
    ><span>
        <b-button-group size="sm" class="ml-3 float-right">
            <b-button variant="outline-secondary" @click="openNewSickdayModal()"
                ><font-awesome-icon icon="plus-square"></font-awesome-icon>&nbsp;Krankheitstag
                eintragen</b-button
            >
        </b-button-group>
        <b-modal
            id="new-sickday-entry"
            title="Krankheitstag eintragen"
            okTitle="Krankheitstage eintragen"
            cancelTitle="abbrechen"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <b-form-group label="von">
                <DatePicker v-model="newSickdayEntryDate1" />
            </b-form-group>

            <b-form-group
                label="bis"
                invalid-feedback="Die zweite Datumsangabe muss größer als die erste Datumsangabe sein."
                :state="state_newSickdayEntryDate"
            >
                <DatePicker v-model="newSickdayEntryDate2" />
            </b-form-group>

            <b-form-group label="Faktor" label-for="new-sickday-entry-factor" class="mt-5">
                <b-form-input
                    id="new-sickday-entry-factor"
                    v-model="newSickdayEntryFactor"
                    type="range"
                    min="0"
                    max="1"
                    step="0.05"
                ></b-form-input>
                <p class="text-muted text-right ">
                    {{ getSickdayFactorInfo(newSickdayEntryFactor) }}
                </p>
            </b-form-group>

            <b-form-group label="weitere Beschreibung" label-for="new-sickday-entry-description">
                <b-form-input
                    v-model="newSickdayEntryDescription"
                    id="new-sickday-entry-description"
                ></b-form-input>
            </b-form-group>
        </b-modal>
    </span>
</template>
<script>
import { employeeService } from "../_services";
import DatePicker from "../components/Global/DatePicker.vue";

export default {
    props: {
        employee_id: Number
    },
    components: {
        DatePicker
    },
    methods: {
        resetModal() {
            this.newSickdayEntryDate1 = window.moment().format("YYYY-MM-DD");
            this.newSickdayEntryDate2 = window.moment().format("YYYY-MM-DD");
            this.newSickdayEntryDescription = "";
            this.newSickdayEntryFactor = 1;
        },
        handleOk() {
            var data = {
                from: this.newSickdayEntryDate1,
                to: this.newSickdayEntryDate2,
                factor: this.newSickdayEntryFactor,
                description: this.newSickdayEntryDescription
            };
            employeeService
                .createSickdaysPerEmployee(this.employee_id, data)
                .then(response => {
                    this.showUpdateSuccess = true;
                    this.$root.toast(
                        "Alle Krankheitstage wurden erfolgreich gespeichert.",
                        "success"
                    );

                    this.$emit("new-sickdays-created", response.data);
                })
                .catch(response => {
                    this.showUpdateError = true;
                    this.$root.toast(
                        "Nicht alle Änderungen konnten übernommen werden. Möglicherweise wurde ein Krankheitstag bereits eingetragen.",
                        "danger"
                    );
                    //@TODO bug: some may be created (only third sd fails because exists), but user gets no feedback for those created and where it failed
                });
        },
        getSickdayFactorInfo(factor) {
            if (factor == 1) {
                return "ganzer Krankheitstag";
            } else if (factor == 0.5) {
                return "halber Krankheitstag";
            } else if (factor == 0) {
                return "Krankheitstag ohne Stundenwirkung";
            } else {
                return factor + "-facher Krankheitstag";
            }
        },

        openNewSickdayModal() {
            this.$bvModal.show("new-sickday-entry");
        }
    },
    computed: {
        state_newSickdayEntryDate() {
            return this.newSickdayEntryDate1 <= this.newSickdayEntryDate2 ? true : false;
        }
    },
    data() {
        return {
            showUpdateError: false,
            showUpdateSuccess: false,
            newSickdayEntryDate1: window.moment().format("YYYY-MM-DD"),
            newSickdayEntryDate2: window.moment().format("YYYY-MM-DD"),
            newSickdayEntryDescription: "",
            newSickdayEntryFactor: 1
        };
    },
    watch: {
        newSickdayEntryDate1() {
            if (this.newSickdayEntryDate1 > this.newSickdayEntryDate2) {
                this.newSickdayEntryDate2 = this.newSickdayEntryDate1;
            }
        },
        newSickdayEntryDate2() {
            if (this.newSickdayEntryDate1 > this.newSickdayEntryDate2) {
                this.newSickdayEntryDate1 = this.newSickdayEntryDate2;
            }
        }
    }
};
</script>
