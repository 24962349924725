<template>
    <b-card v-if="editable" class="shadow mb-5 " header-tag="header">
        <h5 slot="header" class="mb-0">
            <font-awesome-icon :icon="icon" />&nbsp;{{ title }}
            <b-button-group class="float-right ">
                <b-button variant="outline-secondary" size="sm" @click="cancelEditing()">
                    <font-awesome-icon icon="times" />&nbsp;abbrechen
                </b-button>
                <b-button variant="outline-primary" size="sm" @click="saveData()">
                    <font-awesome-icon icon="save" />&nbsp;speichern
                </b-button>
            </b-button-group>
        </h5>

        <b-form-group label="Link Asana">
            <b-form-input v-model="projectinfo.linkasana"></b-form-input>
        </b-form-group>
        <b-form-group label="Link Avanova">
            <b-form-input v-model="projectinfo.linkavanova"></b-form-input>
        </b-form-group>
        <b-form-group label="Link Bautagebuch">
            <b-form-input v-model="projectinfo.linkbtb"></b-form-input>
        </b-form-group>
    </b-card>
    <b-card v-else class="card-transparent mb-5 hover-field" header-tag="header">
        <h5 slot="header" class="mb-0">
            <font-awesome-icon :icon="icon" />&nbsp;{{ title }}
            <b-button-group class="float-right show-on-hover">
                <b-button
                    variant="link"
                    size="sm"
                    class="btn-sm-without-padding"
                    @click="startEditing()"
                >
                    <font-awesome-icon icon="edit" />&nbsp;bearbeiten
                </b-button>
            </b-button-group>
        </h5>

        <b-list-group class="shadow">
            <b-list-group-item
                v-if="projectinfo.linkasana !== ''"
                :href="projectinfo.linkasana"
                target="_blank"
            >
                <font-awesome-icon
                    fixed-width
                    :icon="['fas', 'tasks']"
                    class="text-primary"
                />&nbsp; Asana
            </b-list-group-item>
            <b-list-group-item v-else @click="createInAsana()">
                <span class="small text-muted" v-if="creatingInAsana">
                    <b-spinner small></b-spinner>&nbsp; Asana-Projekt wird anlegt/verknüft
                </span>
                <span class="small text-muted" v-else>
                    Asana-Projekt anlegen oder bestehendes verknüpfen
                </span>
            </b-list-group-item>
            <b-list-group-item
                v-if="projectinfo.linkavanova !== ''"
                :href="projectinfo.linkavanova"
                target="_blank"
            >
                <font-awesome-icon
                    fixed-width
                    :icon="['fas', 'list-ul']"
                    class="text-primary"
                />&nbsp; AVAnova
            </b-list-group-item>
            <b-list-group-item v-else @click="createInAvanova()">
                <span class="small text-muted" v-if="creatingInAvanova">
                    <b-spinner small></b-spinner>&nbsp; Avanova-Projekt wird anlegt/verknüft
                </span>
                <span class="small text-muted" v-else>
                    Avanova-Projekt anlegen oder bestehendes verknüpfen
                </span>
            </b-list-group-item>
            <b-list-group-item
                v-if="projectinfo.linkbtb !== ''"
                :href="projectinfo.linkbtb"
                target="_blank"
            >
                <font-awesome-icon
                    fixed-width
                    :icon="['fas', 'clipboard-list']"
                    class="text-primary"
                />&nbsp; Bautagebuch
            </b-list-group-item>
        </b-list-group>
    </b-card>
</template>

<script>
import { projectService } from "../../_services";

export default {
    props: {
        projectinfo: Object
    },
    components: {},
    data: function() {
        return {
            loaded: true,
            editable: false,
            title: "Links",
            icon: "external-link-alt",
            creatingInAvanova: false,
            creatingInAsana: false
        };
    },
    methods: {
        cancelEditing() {
            this.editable = false;
        },
        saveData() {
            var data = {
                linkasana: this.projectinfo.linkasana,
                linkavanova: this.projectinfo.linkavanova,
                linkbtb: this.projectinfo.linkbtb
            };
            projectService
                .updateOne(this.projectinfo.id, data)
                .then(response => {
                    this.editable = false;
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");
                    this.$root.toast("Das Projekt wurde erfolgreich geändert.", "success");
                })
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht geändert werden.", "danger");
                });
        },
        startEditing() {
            this.editable = true;
        },
        createInAvanova() {
            this.creatingInAvanova = true;
            projectService
                .createInAvanova(this.projectinfo.id)
                .then(response => {
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");

                    this.$root.toast(
                        "Das Projekt wurde erfolgreich in Avanova angelegt.",
                        "success"
                    );
                    this.creatingInAvanova = false;
                })
                .catch(response => {
                    this.$root.toast(
                        "Das Projekt konnte nicht in Avanova angelegt werden.",
                        "danger"
                    );
                    this.creatingInAvanova = false;
                });
        },
        createInAsana() {
            this.creatingInAsana = true;
            projectService
                .createInAsana(this.projectinfo.id)
                .then(response => {
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");

                    this.$root.toast("Das Projekt wurde erfolgreich in Asana angelegt.", "success");
                    this.creatingInAsana = false;
                })
                .catch(response => {
                    this.$root.toast(
                        "Das Projekt konnte nicht in Asana angelegt werden.",
                        "danger"
                    );
                    this.creatingInAsana = false;
                });
        }
    }
};
</script>
