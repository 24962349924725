<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <YearSelector class="float-right" v-model="year" :allyears="false" />

            <h1>Feiertage</h1>
        </template>

        <div class="d-flex justify-content-center mt-5 mb-5" v-if="!loaded">
            <b-spinner label="loaded..."></b-spinner>
        </div>

        <b-row v-else>
            <b-col>
                <div class="card shadow">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 25%">Datum</th>
                                <th style="width: 30%">Name</th>
                                <th style="width: 15%">Anteil</th>
                                <th v-for="emp in publicholidaydata.employees" v-bind:key="emp.id">
                                    {{ emp.initials }}
                                    <br />
                                    <span class="font-weight-normal small">
                                        <a href="#" @click="deselectAllOfEmployee(emp.id)">keine</a>
                                        /
                                        <a href="#" @click="selectAllOfEmployee(emp.id)">alle</a>
                                    </span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="phd in publicholidaydata.publicholidays" v-bind:key="phd.id">
                                <td>
                                    <DatePicker v-model="phd.date" />
                                </td>
                                <td>
                                    <b-form-input
                                        v-model="phd.name"
                                        placeholder="Name des Feiertags"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        id="range-2"
                                        v-model="phd.fraction"
                                        type="range"
                                        min="0"
                                        max="1"
                                        step="0.5"
                                    ></b-form-input>
                                    <small class="text-muted text-center">{{
                                        getFractionInfo(phd.fraction)
                                    }}</small>
                                </td>
                                <td v-for="emp in publicholidaydata.employees" v-bind:key="emp.id">
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            :checked="employeeHasPublicholiday(emp.id, phd.id)"
                                            @click="toggleEmployeePublicholiday(emp.id, phd.id)"
                                            :name="'checkbox-' + phd.id + '-' + emp.id"
                                        />
                                    </div>
                                    <!-- <span
                    @click="toggleEmployeePublicholiday(emp.id, phd.id)"
                  >{{employeeHasPublicholiday(emp.id, phd.id)}}</span>-->
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        class="btn btn-link btn-sm"
                                        v-on:click="deleteEntry(phd.id)"
                                    >
                                        <font-awesome-icon icon="trash-alt" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="clearfix">
                    <b-button
                        variant="outline-secondary"
                        size="sm"
                        class="mb-3 mt-3 float-right"
                        v-on:click="newEntry()"
                    >
                        <font-awesome-icon icon="plus-square" />&nbsp;Neuer Eintrag
                    </b-button>
                </div>

                <b-alert show variant="info">
                    Feiertage können vom letzten Jahr ({{ year - 1 }}) kopiert werden. Dabei wird
                    lediglich das Jahr auf das aktuelle Jahr ({{ year }}) geändert. Die genauen
                    Datumsangaben müssen dann noch angepasst werden.
                    <br />
                    <strong>Achtung:</strong> Bereits angelegte Feiertage und deren
                    Mitarbeiter-Zuordnungen werden dabei gelöcht!
                    <br />
                    <br />
                    <b-button
                        variant="outline-secondary"
                        size="sm"
                        v-on:click="autoCreatePublicholidays()"
                        >alle Feiertage für {{ year }} automatisch anlegen</b-button
                    >
                </b-alert>
            </b-col>
        </b-row>
        <b-navbar toggleable="lg" class="zweige-fixed-bottom-container" fixed="bottom">
            <div class="ml-auto">
                <b-button-group class="mt-4 mb-4">
                    <b-button variant="outline-primary" v-on:click="resetPublicholidayData()"
                        >zurücksetzen</b-button
                    >
                    <b-button variant="primary" v-on:click="updatePublicholidayData()">
                        <font-awesome-icon icon="save" />&nbsp;Feiertage speichern
                    </b-button>
                </b-button-group>
            </div>
        </b-navbar>
    </base-layout>
</template>
<script>
import YearSelector from "../components/YearSelector.vue";
import { employeeService } from "../_services";
import DatePicker from "../components/Global/DatePicker.vue";

export default {
    components: {
        YearSelector,
        DatePicker
    },
    data() {
        return {
            showUpdateError: false,
            showUpdateSuccess: false,
            year: parseInt(window.moment().format("YYYY")),
            publicholidaydata: {},
            loaded: false,
            counter: 0,
            offset: 9000000
        };
    },
    created() {
        this.fetchPublicholidayData();
    },
    computed: {},
    methods: {
        autoCreatePublicholidays() {
            this.loaded = false;
            employeeService
                .copyPublicholidaysFromLastYear(this.year)
                .then(response => {
                    this.$root.toast("Alle Feiertage wurden erfolgreich gespeichert", "success");
                    this.loaded = true;
                    this.publicholidaydata = response.data;
                })
                .catch(err => {
                    this.$root.toast(
                        "Nicht alle Änderungen konnten übernommen werden. Bitte überprüfen Sie Ihre Eingaben.",
                        "danger"
                    );
                    this.showUpdateSuccess = false;
                    this.loaded = true;
                });
        },
        resetPublicholidayData() {
            this.fetchPublicholidayData();
        },
        updatePublicholidayData() {
            this.loaded = false;
            employeeService
                .updateEmployeesPerPublicholiday(this.year, this.publicholidaydata)
                .then(response => {
                    this.showUpdateError = false;
                    this.showUpdateSuccess = true;
                    this.loaded = true;
                    this.publicholidaydata = response.data;
                })
                .catch(err => {
                    this.showUpdateError = true;
                    this.showUpdateSuccess = false;
                    this.loaded = true;
                });
        },

        fetchPublicholidayData() {
            this.loaded = false;
            employeeService.getEmployeesPerPublicholiday(this.year).then(response => {
                this.loaded = true;
                this.publicholidaydata = response.data;
            });
        },
        employeeHasPublicholiday(employee_id, publicholiday_id) {
            for (let i = 0; i < this.publicholidaydata.employeespervacationday.length; i++) {
                let ev = this.publicholidaydata.employeespervacationday[i];
                if (ev.employee_id === employee_id && ev.publicholiday_id === publicholiday_id) {
                    return true;
                }
            }
            return false;
        },
        toggleEmployeePublicholiday(employee_id, publicholiday_id) {
            let newemployeespervacationday = [];
            let deleted = false;
            // delete it if already exists (by not adding it to the new one)
            this.publicholidaydata.employeespervacationday.forEach(ev => {
                if (ev.employee_id == employee_id && ev.publicholiday_id == publicholiday_id) {
                    deleted = true;
                } else {
                    newemployeespervacationday.push(ev);
                }
            });
            // if not found (not deleted, because it didnt exist): add it
            if (!deleted) {
                newemployeespervacationday.push({
                    employee_id: employee_id,
                    publicholiday_id: publicholiday_id
                });
            }
            this.publicholidaydata.employeespervacationday = newemployeespervacationday;
        },
        setEmployeePublicholiday(employee_id, publicholiday_id) {
            let newemployeespervacationday = [];
            let deleted = false;
            // delete it if already exists (by not adding it to the new one)
            this.publicholidaydata.employeespervacationday.forEach(ev => {
                if (ev.employee_id == employee_id && ev.publicholiday_id == publicholiday_id) {
                    deleted = true;
                }
                newemployeespervacationday.push(ev);
            });
            // if not found (not deleted, because it didnt exist): add it
            if (!deleted) {
                newemployeespervacationday.push({
                    employee_id: employee_id,
                    publicholiday_id: publicholiday_id
                });
            }
            this.publicholidaydata.employeespervacationday = newemployeespervacationday;
        },
        unsetEmployeePublicholiday(employee_id, publicholiday_id) {
            let newemployeespervacationday = [];
            let deleted = false;
            // delete it if already exists (by not adding it to the new one)
            this.publicholidaydata.employeespervacationday.forEach(ev => {
                if (ev.employee_id == employee_id && ev.publicholiday_id == publicholiday_id) {
                    deleted = true;
                } else {
                    newemployeespervacationday.push(ev);
                }
            });
            this.publicholidaydata.employeespervacationday = newemployeespervacationday;
        },
        newEntry() {
            this.counter += 1;
            var empty = {
                id: this.offset + this.counter,
                name: "",
                fraction: 1,
                date: ""
            };
            this.publicholidaydata.publicholidays.push(empty);
        },
        deleteEntry: function(entryid) {
            let newentries = [];
            this.publicholidaydata.publicholidays.forEach(element => {
                if (element.id != entryid) {
                    newentries.push(element);
                } else if (element.id < this.offset) {
                    this.publicholidaydata.delete_publicholidays.push(element);
                }
            });
            this.publicholidaydata.publicholidays = newentries;
        },
        selectAllOfEmployee(employee_id) {
            this.publicholidaydata.publicholidays.forEach(element => {
                this.setEmployeePublicholiday(employee_id, element.id);
            });
        },
        deselectAllOfEmployee(employee_id) {
            this.publicholidaydata.publicholidays.forEach(element => {
                this.unsetEmployeePublicholiday(employee_id, element.id);
            });
        },
        getFractionInfo(fraction) {
            if (fraction == 1) {
                return "ganzer Feiertag";
            } else if (fraction == 0.5) {
                return "halber Feiertag";
            } else if (fraction == 0) {
                return "inaktiver Feiertag";
            }
        }
    },
    watch: {
        year: function() {
            this.fetchPublicholidayData();
        }
    }
};
</script>
