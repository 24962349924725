<template>
    <b-row v-if="projectinfo !== undefined">
        <b-col cols="11">
            <h1>
                <span v-if="editable">
                    <b-row class="my-1">
                        <b-col sm="2">
                            <b-form-input
                                id="input-number"
                                size="lg"
                                placeholder="Nummer"
                                v-model="projectinfo.number"
                            ></b-form-input>
                        </b-col>
                        <b-col sm="10">
                            <b-form-input
                                id="input-name"
                                size="lg"
                                placeholder="Projektbezeichnung"
                                v-model="projectinfo.name"
                            ></b-form-input>
                        </b-col>
                    </b-row>
                </span>
                <span class="font-weight-bold" v-else>
                    {{ ProjectNameNumber }}
                    <!-- <b-button variant="link" @click="editable = !editable"
                        ><font-awesome-icon icon="edit"
                    /></b-button> -->
                </span>
            </h1>
        </b-col>
        <b-col cols="1">
            <b-tooltip
                target="tooltip-favorite"
                triggers="hover"
                v-if="projectinfo.isfavorite == 0"
            >
                Projekt zum Favoriten machen
            </b-tooltip>
            <b-tooltip target="tooltip-favorite" triggers="hover" v-else>
                Projekt von Favoriten entfernen
            </b-tooltip>
            <b-button
                variant="link"
                class="float-right"
                id="tooltip-favorite"
                @click="toggleFavorite()"
            >
                <font-awesome-icon size="2x" :icon="starIcon"
            /></b-button>
        </b-col>
    </b-row>
</template>

<script>
import { projectService } from "../../_services";
import { faStar as faStarR } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarS } from "@fortawesome/free-solid-svg-icons";
export default {
    props: { projectinfo: Object },
    components: {},
    data: function() {
        return {
            loaded: true,
            editable: false,
            projectid: this.projectinfo.id,
            faStarR,
            faStarS
        };
    },
    methods: {
        toggleFavorite() {
            var data = { isfavorite: Math.abs(this.projectinfo.isfavorite - 1) };
            projectService
                .updateOne(this.projectinfo.id, data)
                .then(response => {
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");
                    this.$root.toast("Das Projekt wurde erfolgreich geändert.", "success");
                })
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht geändert werden.", "danger");
                });
        }
    },
    computed: {
        starIcon() {
            if (this.projectinfo.isfavorite == 0) {
                return this.faStarR;
            } else {
                return this.faStarS;
            }
        },
        ProjectNameNumber: function() {
            return this.projectinfo.number + ": " + this.projectinfo.name;
        }
    }
};
</script>
