<template>
    <table class="table table-hover mb-0">
        <thead>
            <tr>
                <th>Datum</th>
                <th>Faktor</th>
                <th class="text-right">Stundenzahl</th>
                <th>Beschreibung</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <NoEntriesFound
                :colspan="5"
                message="Es wurden keine Feiertage gefunden."
                v-if="activeEntries.length == 0"
            />
            <tr v-else v-for="vd in activeEntries" v-bind:key="vd.date">
                <td class="text-left">
                    {{ vd.date | DateLong }}
                    <span v-if="currentdate <= vd.date">
                        <b-badge variant="warning">zukünftig</b-badge>
                    </span>
                </td>
                <td v-if="vd.fraction === null">
                    <font-awesome-icon
                        icon="exclamation-triangle"
                        class="text-danger"
                        :id="'no_entry_' + vd.date"
                    />
                    <b-tooltip :target="'no_entry_' + vd.date" triggers="hover"
                        >Feiertag nicht eingetragen trotz eines aktiven
                        Arbeitszeitmodells</b-tooltip
                    >
                </td>
                <td v-else-if="vd.possiblynotinrange">
                    <font-awesome-icon
                        icon="exclamation-triangle"
                        class="text-danger"
                        :id="'possiblynotinrange_' + vd.date"
                    />
                    <b-tooltip :target="'possiblynotinrange_' + vd.date" triggers="hover"
                        >Feiertag außerhalb eines aktiven Arbeitszeitmodells</b-tooltip
                    >
                </td>
                <td v-else>{{ vd.fraction | DayFractions }}</td>
                <td class="text-right">{{ vd.recordvalue_computed | NumberTwoDigits }}</td>
                <td>{{ vd.name }}</td>
                <td>
                    <!-- <button v-if="vd.fraction === null" type="button" class="btn btn-link btn-sm">
                        <font-awesome-icon icon="plus" />
                    </button>
                    <button v-else type="button" class="btn btn-link btn-sm">
                        <font-awesome-icon icon="trash-alt" />
                    </button> -->
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        entries: Array,
        year: Number
    },
    data() {
        return { currentdate: window.moment().format("YYYY-MM-DD") };
    },
    created() {},
    methods: {},
    computed: {
        activeEntries: function() {
            if (this.year == 0) {
                return this.entries;
            }
            let ret = [];
            this.entries.forEach(element => {
                if (element.date.includes(this.year)) {
                    ret.push(element);
                }
            });
            return ret;
        }
    },
    watch: {}
};
</script>
