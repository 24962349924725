<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <EmployeeSelector title="Urlaub eintragen für" v-model="employee_id" />
            <YearSelector
                class="float-right"
                v-model="year"
                :allyears="false"
                :showcurrentyear="false"
            />
            <h1>Urlaubsplaner</h1>
        </template>

        <div class="d-flex justify-content-center mt-5 mb-5" v-if="!loaded">
            <b-spinner label="loaded..."></b-spinner>
        </div>

        <b-row v-else>
            <b-col class="d-print-none">
                <VacationPicker
                    :year="year"
                    :vacationdays="vacdates"
                    :publicholidays="publicholidaydata.publicholidays"
                    v-on:vacationday-changed="onVacationDayChanged($event)"
                    :employee_id="employee_id"
                />
            </b-col>
            <b-col>
                <div class="overflow-auto">
                    <table class="table table-sm" v-if="vacationdata.vacationdays.length > 0">
                        <tbody>
                            <tr
                                v-for="vacationday in vacationdata.vacationdays"
                                v-bind:key="vacationday.date"
                            >
                                <td>
                                    {{ vacationday.date | DateLong }}
                                    <p v-if="vacationday.date >= today" class="small text-muted">
                                        geplant
                                    </p>
                                </td>
                                <td>
                                    {{ vacationday.factor | DayFractions }}

                                    <br
                                        v-if="
                                            userHasManagerRoleForEmployee ||
                                                vacationday.date >= today
                                        "
                                    />
                                    <b-button
                                        v-if="
                                            userHasManagerRoleForEmployee ||
                                                vacationday.date >= today
                                        "
                                        size="sm"
                                        variant="link"
                                        class="p-0"
                                        v-on:click="changeFactor(vacationday.date)"
                                    >
                                        ändern
                                    </b-button>
                                </td>
                                <!-- <td
                  class="text-right text-muted"
                >{{vacationday.recordvalue_computed | NumberTwoDigits}} h</td>-->
                                <td>
                                    <button
                                        v-if="
                                            userHasManagerRoleForEmployee ||
                                                vacationday.date >= today
                                        "
                                        type="button"
                                        class="btn btn-link btn-sm"
                                        v-on:click="deleteEntry(vacationday.date)"
                                    >
                                        <font-awesome-icon icon="trash-alt" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <th></th>
                            <th>{{ computeNumberOfVacationdays() | NumberOneDigit }} Tage</th>
                            <!-- <th class="text-right text-muted">{{computeHoursOfVacationdays() | NumberTwoDigits}} h</th> -->
                            <th></th>
                        </tfoot>
                    </table>
                </div>
            </b-col>
            <b-col>
                <div class="card shadow">
                    <div class="card-header">Übersicht Urlaubstage</div>
                    <VacationOverview :employeevacationdays="vacationdata" />
                </div>
            </b-col>
        </b-row>
        <b-navbar toggleable="lg" class="zweige-fixed-bottom-container" fixed="bottom">
            <div class="ml-auto">
                <b-button-group class="mt-4 mb-4">
                    <b-button variant="outline-primary" v-on:click="resetVacationData()"
                        >zurücksetzen</b-button
                    >
                    <b-button variant="primary" v-on:click="updateVacationData()">
                        <font-awesome-icon icon="save" />&nbsp;Einträge speichern
                    </b-button>
                </b-button-group>
            </div>
        </b-navbar>
    </base-layout>
</template>
<script>
import { employeeService } from "../_services";
import VacationOverview from "../components/VacationOverview.vue";
import VacationPicker from "../components/VacationPicker";
import EmployeeSelector from "../components/EmployeeSelector";
import YearSelector from "../components/YearSelector.vue";

export default {
    components: {
        VacationOverview,
        VacationPicker,
        EmployeeSelector,
        YearSelector
    },
    data() {
        return {
            employee_id:
                this.$route.params.id == null
                    ? this.$root.selfEmployeeId
                    : parseInt(this.$route.params.id),
            year:
                this.$route.params.year == null
                    ? parseInt(window.moment().format("YYYY"))
                    : parseInt(this.$route.params.year),
            vacationdata: {},
            publicholidaydata: {},
            phodates: [],
            vacdates: [],
            today: window.moment().format("YYYY-MM-DD"),
            updloaded: false,
            pholoaded: false,
            vacloaded: false,
            loaded: false
        };
    },
    created() {
        this.fetchVacationData();
        this.fetchPublicholidayData();
    },
    computed: {
        userHasManagerRoleForEmployee() {
            return this.$root.userHasManagerRoleForEmployee(this.employee_id);
        }
    },
    methods: {
        checkIfLoaded() {
            this.loaded = this.pholoaded && this.vacloaded;
        },
        fetchVacationData() {
            this.vacloaded = false;
            employeeService
                .getVacationdaysPerEmployeePerYear(this.employee_id, this.year)
                .then(response => {
                    this.vacloaded = true;
                    this.vacationdata = response.data;
                    this.vacdates = this.getVacationDates();
                    this.checkIfLoaded();
                });
        },
        fetchPublicholidayData() {
            this.pholoaded = false;
            employeeService.getPublicholidaysPerEmployee(this.employee_id).then(response => {
                this.pholoaded = true;
                this.publicholidaydata = response.data;
                this.phodates = this.getPublicholidayDates();
                this.checkIfLoaded();
            });
        },
        getVacationDates() {
            let dates = [];
            this.vacationdata.vacationdays.forEach(vacationday => {
                dates.push(window.moment(vacationday.date).toDate());
            });
            return dates;
        },
        getPublicholidayDates() {
            let dates = [];
            this.publicholidaydata.publicholidays.forEach(publicholiday => {
                dates.push(window.moment(publicholiday.date).toDate());
            });
            return dates;
        },
        computeNumberOfVacationdays() {
            let sum = 0;
            this.vacationdata.vacationdays.forEach(vacationday => {
                sum += vacationday.factor;
            });
            return sum;
        },
        computeHoursOfVacationdays() {
            let sum = 0;
            this.vacationdata.vacationdays.forEach(vacationday => {
                sum += vacationday.recordvalue_computed;
            });
            return sum;
        },
        deleteEntry: function(date) {
            let newentries = [];
            this.vacationdata.vacationdays.forEach(element => {
                if (element.date != date) {
                    newentries.push(element);
                }
            });
            this.vacationdata.vacationdays = newentries;
            this.vacdates = this.getVacationDates();
        },
        changeFactor: function(date) {
            let newentries = [];
            this.vacationdata.vacationdays.forEach(element => {
                if (element.date == date) {
                    if (element.factor == 1) {
                        element.factor = 0.5;
                    } else {
                        element.factor = 1;
                    }
                }
                newentries.push(element);
            });
            this.vacationdata.vacationdays = newentries;
            this.vacdates = this.getVacationDates();
        },
        updateVacationData() {
            this.updloaded = false;
            employeeService
                .updateVacationdaysPerEmployeePerYear(
                    this.employee_id,
                    this.year,
                    this.vacationdata
                )
                .then(response => {
                    this.$root.toast("Alle Urlaubstage wurden erfolgreich gespeichert.", "success");
                    this.updloaded = true;
                    this.vacationdata = response.data;
                    this.vacdates = this.getVacationDates();
                })
                .catch(err => {
                    this.$root.toast(
                        "Nicht alle Änderungen konnten übernommen werden. Bitte überprüfen Sie Ihre Eingaben.",
                        "danger"
                    );
                    this.updloaded = true;
                });
        },
        resetVacationData() {
            this.fetchVacationData();
        },
        onVacationDayChanged(event) {
            let deleted = false;
            this.vacdates.forEach(vc => {
                if (window.moment(vc).format("YYYY-MM-DD") == event) {
                    //delete it, if already exists
                    deleted = true;
                    this.deleteEntry(event);
                }
            });
            if (!deleted) {
                // add it, if it has not been deleted before
                this.vacationdata.vacationdays.push({
                    date: event,
                    factor: 1,
                    recordvalue_computed: 8,
                    description: "new"
                });
                // sort array for all dates
                this.vacationdata.vacationdays.sort((a, b) => (a.date > b.date ? 1 : -1));
                this.vacdates = this.getVacationDates();
            }
        }
    },
    watch: {
        employee_id: function() {
            this.$router.push({
                name: "my.vacation.withyearandid",
                params: { year: this.year, id: this.employee_id }
            });
            this.loaded = false;
            this.fetchVacationData();
        },
        year: function() {
            this.$router.push({
                name: "my.vacation.withyearandid",
                params: { year: this.year, id: this.employee_id }
            });
            this.loaded = false;
            this.fetchVacationData();
        }
    }
};
</script>
