<template>
    <div class="card shadow" v-if="!freelancer">
        <div class="card-header">
            <EmployeeLinks
                :employee_id="data.employee.id"
                v-if="showlinks"
                :showtimesheetlinks="true"
            />

            <h5><EmployeeName :id="data.employee.id" :dot="false" /></h5>
        </div>
        <div class="card-body">
            <CurrentMonthStatus
                :meta="data.employee.meta"
                :monthlysums="data.monthly_sums"
                :currentmonth="data.timesheet_currentmonth"
                :showdots="$root.EmployeeIsEmployed(data.employee.id)"
            ></CurrentMonthStatus>
        </div>
    </div>
    <div class="card shadow" v-else>
        <div class="card-header">
            <EmployeeLinks :employee_id="data.id" v-if="showlinks" :showtimesheetlinks="true" />
            <h5><EmployeeName :id="data.id" :dot="false" /></h5>
        </div>
    </div>
</template>

<script>
import CurrentMonthStatus from "../components/CurrentMonthStatus.vue";
import EmployeeLinks from "../components/EmployeeLinks.vue";

export default {
    props: {
        data: Object,
        freelancer: Boolean,
        showlinks: { type: Boolean, required: false, default: true }
    },
    components: {
        CurrentMonthStatus,
        EmployeeLinks
    }
};
</script>

<style></style>
