import Vue from "vue";

/**
 * MOMENT
 */
const moment = require("moment");
moment.locale("de");
Vue.use(require("vue-moment"), {
    moment
});
window.moment = moment;

/**
 * NUMERAL
 */

var numeral = require("numeral");
var de = require("numeral/locales/de");
numeral.locale("de");

Vue.filter("DateFullShort", function(value) {
    if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
    }
});
Vue.filter("DateFullLong", function(value) {
    if (value) {
        return moment(String(value)).format("D. MMMM YYYY");
    }
});
Vue.filter("DateLong", function(value) {
    if (value) {
        return moment(String(value)).format("dd, DD.MM.YYYY");
    }
});
Vue.filter("DateWithoutYear", function(value) {
    if (value) {
        return moment(String(value)).format("D. MMMM");
    }
});

Vue.filter("ReadableTimestamp", function(value) {
    if (value) {
        return moment(value).format("DD.MM.YYYY, HH:mm");
    }
});

Vue.filter("NumberTwoDigits", function(value) {
    value = parseFloat(value);
    return numeral(value).format("0.00");
});
Vue.filter("NumberTwoDigitsOrEmpty", function(value) {
    value = parseFloat(value);
    if (value == 0) {
        return "";
    } else {
        return numeral(value).format("0.00");
    }
});

Vue.filter("NumberOneDigit", function(value) {
    return numeral(value).format("0.0");
});
Vue.filter("NumberNoDigit", function(value) {
    return numeral(value).format("0");
});

Vue.filter("DayFractions", function(value) {
    if (value == 1 || value == -1) {
        return numeral(value).format("0.0") + " Tag";
    } else {
        return numeral(value).format("0.0") + " Tage";
    }
});
Vue.filter("DayFractionsThreeDigits", function(value) {
    if (value == 1 || value == -1) {
        return numeral(value).format("0.000") + " Tag";
    } else {
        return numeral(value).format("0.000") + " Tage";
    }
});

Vue.filter("NumberTwoDigitsOrEmptyString", function(value) {
    if (value === "") {
        return "";
    } else {
        return numeral(value).format("0.00");
    }
});

Vue.filter("NumberTwoDigitsPlusMinus", function(value) {
    return numeral(value).format("+ 0.00");
});

Vue.filter("NumberTwoDigitsPlusMinusFirstHalf", function(value) {
    value = parseFloat(value);
    if (value > 0) {
        value = Math.floor(value);
    } else {
        value = Math.ceil(value);
    }
    return numeral(value).format("+ 0");
});

Vue.filter("NumberTwoDigitsPlusMinusSecondHalf", function(value) {
    value = parseFloat(value); // make number
    value = Math.abs(value); // make positive
    var intpart = Math.floor(value); // make integer
    var twodigits = (value - intpart) * 100; //diff = right party, but x100
    return "," + numeral(twodigits).format("00");
});

Vue.filter("TwoDigitsLeadingZero", function(value) {
    return numeral(value).format("00");
});

Vue.filter("MinutesOrHoursBreaktime", function(value) {
    if (value == 0) {
        return "keine ";
    } else if (value < 1) {
        return parseInt(value * 60) + " Minuten  ";
    } else {
        return numeral(value).format("0.00") + " Stunden ";
    }
});

Vue.filter("FactorInfo", function(value) {
    if (value == 1) {
        return "";
    } else if (value == 0.5) {
        return "halber";
    } else {
        return value + "-facher";
    }
});

Vue.filter("PeriodSmall", function(value) {
    if (value) {
        return value.substring(5, 7) + "/" + value.substring(0, 4);
    }
});

Vue.filter("Time", function(value) {
    if (value) {
        return moment(String(value), "HH:mm:ss").format("HH:mm");
    }
});

Vue.filter("Percentage", function(value) {
    return numeral(value).format("0.0 %");
});

Vue.filter("BigNumber", function(value) {
    return numeral(value).format("0,000");
});
