<template>
    <v-date-picker
        :value="toDateObject(value)"
        @input="dateChanged($event)"
        :popover="{ placement: 'top', visibility: 'click' }"
    >
        <b-input-group>
            <b-input-group-prepend v-if="!borderless">
                <b-button variant="secondary">
                    <font-awesome-icon icon="calendar" />
                </b-button>
            </b-input-group-prepend>
            <b-form-input
                :style="borderless ? 'border:0' : ''"
                :state="validDate"
                :value="value | DateFullShort"
                @change="dateTyped($event)"
            ></b-form-input>
        </b-input-group>
    </v-date-picker>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: false,
            default: null
        },
        allownull: {
            type: Boolean,
            required: false,
            default: true
        },
        borderless: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        validDate() {
            if (this.allownull && (this.value == null || this.value == "")) {
                return null;
            } else if (window.moment(this.value, "YYYY-MM-DD").isValid()) {
                return null;
            } else {
                return false;
            }
        }
    },
    methods: {
        dateTyped(event) {
            var localDate = window.moment(event, "DD.MM.YYYY");
            if (localDate.isValid()) {
                this.$emit("input", localDate.format("YYYY-MM-DD"));
            } else {
                this.$emit("input", null);
            }
        },
        toDateObject(date) {
            return window.moment(date).toDate();
        },
        dateChanged(event) {
            var localDate = window.moment(event, "DD.MM.YYYY");
            if (localDate.isValid()) {
                this.$emit("input", localDate.format("YYYY-MM-DD"));
            } else {
                this.$emit("input", null);
            }
        }
    }
};
</script>

<style></style>
