<template>
    <v-calendar
        is-expanded
        is-inline
        mode="multiple"
        title-position="left"
        :rows="3"
        :columns="$screens({ default: 1, md: 3, lg: 4 })"
        color="pink"
        :min-date="startdate"
        :max-date="enddate"
        :attributes="attributes"
    />
</template>

<script>
export default {
    props: {
        vacationdays: Array,
        publicholidays: Array,
        year: Number
    },
    data: function() {
        return {
            startdate: this.year + "-01-01",
            enddate: this.year + "-12-31"
        };
    },
    methods: {
        onDayClick: function(event) {
            this.$emit("vacationday-changed", event.id);
        }
    },
    computed: {
        attributes() {
            return [
                ...this.publicholidays.map(phd => ({
                    dates: window.moment(phd.date).toDate(),
                    dot: {
                        color: "gray"
                    },
                    popover: {
                        label: (phd.fraction == 0.5 ? "halber " : "") + "Feiertag: " + phd.name
                    },
                    customData: phd
                })),
                ...this.vacationdays.map(vad => ({
                    dates: window.moment(vad.date).toDate(),
                    dot: {
                        color: "pink"
                    },
                    popover: {
                        label:
                            (vad.factor == 0.5 ? "halber " : "") +
                            "Urlaubstag: " +
                            vad.firstname +
                            " " +
                            vad.lastname
                    },
                    customData: vad
                }))
            ];
        }
    }
};
</script>
