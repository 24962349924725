<template>
    <b-row>
        <b-col :cols="cols" class="mb-5" v-for="i in is" v-bind:key="i">
            <div class="card shadow">
                <div class="card-header">
                    <div class="sceleton-loader lg w50"></div>
                </div>
                <div class="card-body">
                    <b-row>
                        <b-col>
                            <div class="sceleton-loader xl w30"></div>
                            <div class="sceleton-loader md w100"></div>
                            <div class="sceleton-loader sm w95"></div>
                        </b-col>
                        <b-col>
                            <div class="sceleton-loader xl w30"></div>
                            <div class="sceleton-loader md w100"></div>
                            <div class="sceleton-loader sm w95"></div>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: {
        number: { type: String, required: false, default: "1" },
        cols: { type: [String, Number], required: false, default: "12" }
    },
    computed: {
        is() {
            return Array.from(Array(parseInt(this.number)).keys());
        }
    }
};
</script>

<style></style>
