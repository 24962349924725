<template>
    <b-button-group class="float-right d-print-none">
        <b-button
            size="sm"
            variant="secondary"
            :to="{
                name: 'employee.detail',
                params: {
                    id: employee_id
                }
            }"
            ><font-awesome-icon icon="external-link-alt" />&nbsp;Übersicht</b-button
        >
        <b-dropdown
            v-if="showtimesheetlinks"
            right
            size="sm"
            variant="outline-secondary"
            id="dropdown-1"
        >
            <template v-slot:button-content>
                <font-awesome-icon icon="file" />&nbsp;Stundenaufstellung
            </template>

            <b-dropdown-item
                v-for="period in periodoptions"
                v-bind:key="period"
                :to="{
                    name: 'employees.timesheet',
                    params: {
                        id: employee_id,
                        period: period
                    }
                }"
                >{{ period | PeriodSmall }}</b-dropdown-item
            >
        </b-dropdown>
    </b-button-group>
</template>
<script>
export default {
    props: {
        employee_id: Number,
        showtimesheetlinks: Boolean
    },
    computed: {
        periodoptions() {
            var ret = [];
            for (var i = 0; i <= 2; i++) {
                ret.push(
                    window
                        .moment()
                        .subtract(i, "month")
                        .format("YYYY-MM")
                );
            }
            return ret;
        }
    }
};
</script>
