<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>
                Projektübersicht
                <br />
                <PDTitle :projectinfo="$root.getProjectObject(project_id)" />
            </h1>
        </template>

        <template v-slot:page-sub-menu>
            <ProjectSubMenu active="projects-overview" :project_id="project_id" />
        </template>

        <ProjectDetailsEditable project_id="project_id" />
    </base-layout>
</template>
<script>
import ProjectSubMenu from "../../components/SubMenus/ProjectSubMenu.vue";
import ProjectDetailsEditable from "../../components/ProjectDetailsEditable.vue";
import PDTitle from "../../components/ProjectDetail/PDTitle.vue";

export default {
    components: {
        ProjectSubMenu,
        ProjectDetailsEditable,
        PDTitle
    },
    data() {
        return {
            project_id: parseInt(this.$route.params.id),
            loading: false
        };
    }
};
</script>
