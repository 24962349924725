<template>
    <span v-if="editable">
        <b-card class="shadow mb-5" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right">
                    <b-button variant="outline-secondary" size="sm" @click="cancelEditing()">
                        <font-awesome-icon icon="times" />&nbsp;abbrechen
                    </b-button>
                    <b-button variant="outline-primary" size="sm" @click="saveData()">
                        <font-awesome-icon icon="save" />&nbsp;speichern
                    </b-button>
                </b-button-group>
            </h5>

            <b-form-group label="Kunde">
                <b-form-input v-model="projectinfo.client"></b-form-input>
            </b-form-group>
            <b-form-group label="Haupt-Ansprechpartner">
                <b-form-input v-model="projectinfo.client_contactperson"></b-form-input>
            </b-form-group>
            <b-form-group label="Straße">
                <b-form-input v-model="projectinfo.client_address"></b-form-input>
            </b-form-group>
            <b-form-group label="PLZ/Ort">
                <b-row>
                    <b-col>
                        <b-form-input v-model="projectinfo.client_plz"></b-form-input>
                    </b-col>
                    <b-col>
                        <b-form-input v-model="projectinfo.client_city"></b-form-input>
                    </b-col>
                </b-row>
            </b-form-group>
            <b-form-group label="E-Mail-Adresse">
                <b-form-input v-model="projectinfo.client_email"></b-form-input>
            </b-form-group>
            <b-form-group label="Telefonnummer">
                <b-form-input v-model="projectinfo.client_phone"></b-form-input>
            </b-form-group>
        </b-card>
    </span>
    <span v-else>
        <b-card class="card-transparent mb-5  hover-field" header-tag="header">
            <h5 slot="header" class="mb-0">
                <font-awesome-icon :icon="icon" />
                &nbsp;{{ title }}
                <b-button-group class="float-right show-on-hover">
                    <b-button
                        variant="link"
                        size="sm"
                        class="btn-sm-without-padding"
                        @click="startEditing()"
                    >
                        <font-awesome-icon icon="edit" />&nbsp;bearbeiten
                    </b-button>
                </b-button-group>
            </h5>

            <span
                v-if="projectinfo.client.length > 0 || projectinfo.client_contactperson.length > 0"
            >
                <!-- @TODO make to component -->
                <strong>{{ projectinfo.client }}</strong>
                <br />
                {{ projectinfo.client_contactperson }}
                <br />
                {{ projectinfo.client_address }} <br />
                {{ projectinfo.client_plz }} {{ projectinfo.client_city }} <br />
                {{ projectinfo.client_email }} <br />
                {{ projectinfo.client_phone }}
            </span>
            <span v-else class="text-muted small">Keine Kunden-Information hinterlegt.</span>
        </b-card>
    </span>
</template>

<script>
import { projectService } from "../../_services";

export default {
    props: {
        projectinfo: Object
    },
    components: {},
    data: function() {
        return {
            loaded: true,
            editable: false,
            title: "Kunde",
            icon: "user-tie"
        };
    },
    methods: {
        cancelEditing() {
            this.editable = false;
        },
        saveData() {
            var data = {
                client: this.projectinfo.client,
                client_contactperson: this.projectinfo.client_contactperson,
                client_city: this.projectinfo.client_city,
                client_plz: this.projectinfo.client_plz,
                client_address: this.projectinfo.client_address,
                client_email: this.projectinfo.client_email,
                client_phone: this.projectinfo.client_phone
            };
            projectService
                .updateOne(this.projectinfo.id, data)
                .then(response => {
                    this.editable = false;
                    this.$store.commit("projects/integrateOne", {
                        id: this.projectinfo.id,
                        data: response.data
                    });
                    this.$emit("project-updated");
                    this.$root.toast("Das Projekt wurde erfolgreich geändert.", "success");
                })
                .catch(error => {
                    this.$root.toast("Das Projekt konnte nicht geändert werden.", "danger");
                });
        },
        startEditing() {
            this.editable = true;
        }
    },
    computed: {}
};
</script>
