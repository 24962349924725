<template>
    <Loading v-if="!loaded" />

    <div v-else>
        <!-- <PDTitle :projectinfo="project" v-on:project-updated="fetchData()" /> -->
        <b-row>
            <b-col>
                <PDLocationInfo :projectinfo="project" v-on:project-updated="fetchData()" />
            </b-col>
            <b-col>
                <PDClientInfo :projectinfo="project" v-on:project-updated="fetchData()" />
            </b-col>
            <b-col>
                <PDStatusInfo :projectinfo="project" v-on:project-updated="fetchData()" />
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <PDIncludedServicesInfo :projectinfo="project" v-on:project-updated="fetchData()" />
            </b-col>
            <b-col>
                <PDProjectEmployeesInfo :projectinfo="project" v-on:project-updated="fetchData()" />
            </b-col>
            <b-col> <PDLinks :projectinfo="project" v-on:project-updated="fetchData()" /> </b-col>
        </b-row>

        <b-row>
            <b-col>
                <PDDocumentlocationInfo :projectinfo="project" v-on:project-updated="fetchData()" />
            </b-col>
            <b-col>
                <PDNotes :projectinfo="project" v-on:project-updated="fetchData()" />
            </b-col>
            <b-col> </b-col>
        </b-row>
    </div>
</template>
<script>
import PDDocumentlocationInfo from "../components/ProjectDetail/PDDocumentlocationInfo";
import PDClientInfo from "../components/ProjectDetail/PDClientInfo";
import PDLocationInfo from "../components/ProjectDetail/PDLocationInfo";
import PDNotes from "../components/ProjectDetail/PDNotes";
import PDStatusInfo from "../components/ProjectDetail/PDStatusInfo";
import PDLinks from "../components/ProjectDetail/PDLinks";
import PDIncludedServicesInfo from "../components/ProjectDetail/PDIncludedServicesInfo";
import PDProjectEmployeesInfo from "../components/ProjectDetail/PDProjectEmployeesInfo";
import { projectService } from "../_services";

export default {
    components: {
        PDDocumentlocationInfo,
        PDClientInfo,
        PDLocationInfo,
        PDNotes,
        PDStatusInfo,
        PDLinks,
        PDProjectEmployeesInfo,
        PDIncludedServicesInfo
    },
    data() {
        return {
            loaded: false,
            project: {}
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            projectService.findOne(this.$route.params.id).then(response => {
                this.loaded = true;
                this.project = response.data;
            });
        }
    }
};
</script>
