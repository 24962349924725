<template>
    <tr v-if="numberOfEntries > 0" class="hover-field">
        <td>
            {{ date | DateLong }}
            <b-button
                variant="link"
                size="sm"
                class="btn-sm-without-padding pt-2 show-on-hover d-print-none"
                :to="{
                    name: 'my.records.withdateandid',
                    params: { date: date, id: timesheetdata.employee.id }
                }"
            >
                <font-awesome-icon icon="edit" />&nbsp;bearbeiten
            </b-button>
        </td>
        <td>
            {{ dailyDuration | NumberTwoDigits }}
        </td>
        <td colspan="3" style="padding:0">
            <table class="table table-without-top-line" style="margin-bottom: 0">
                <tr v-for="day in records" v-bind:key="'r_' + day.id">
                    <td :style="'width:' + (7 / 80) * 100 + '%; text-align:right'">
                        {{ $root.getDuration(day.starttime, day.endtime) | NumberTwoDigits }}
                    </td>
                    <td :style="'width:' + (38 / 80) * 100 + '%'">
                        <ProjectName :id="day.project_id" />
                    </td>
                    <td :style="'width:' + (35 / 80) * 100 + '%'">
                        {{ day.description }}
                        <p class="small text-muted mb-0" v-if="showtimes">
                            {{ day.starttime | Time }} - {{ day.endtime | Time }}
                        </p>
                    </td>
                </tr>
                <tr v-for="day in publicholidays" v-bind:key="'phd_' + day.id">
                    <td :style="'width:' + (7 / 80) * 100 + '%; text-align:right'">
                        {{ day.recordvalue_computed | NumberTwoDigits }}
                    </td>
                    <td :style="'width:' + (38 / 80) * 100 + '%'">
                        <font-awesome-icon icon="glass-cheers" fixed-width />&nbsp;
                        {{ day.fraction | FactorInfo }} Feiertag: {{ day.name }}
                    </td>
                    <td :style="'width:' + (35 / 80) * 100 + '%'">{{ day.description }}</td>
                </tr>
                <tr v-for="day in vacationdays" v-bind:key="'vd_' + day.id">
                    <td :style="'width:' + (7 / 80) * 100 + '%; text-align:right'">
                        {{ day.recordvalue_computed | NumberTwoDigits }}
                    </td>
                    <td :style="'width:' + (38 / 80) * 100 + '%'">
                        <font-awesome-icon icon="suitcase-rolling" fixed-width />&nbsp;{{
                            day.factor | FactorInfo
                        }}
                        Urlaubstag
                    </td>
                    <td :style="'width:' + (35 / 80) * 100 + '%'">{{ day.description }}</td>
                </tr>
                <tr v-for="day in sickdays" v-bind:key="'sd_' + day.id">
                    <td :style="'width:' + (7 / 80) * 100 + '%; text-align:right'">
                        {{ day.recordvalue_computed | NumberTwoDigits }}
                    </td>
                    <td :style="'width:' + (38 / 80) * 100 + '%'">
                        <font-awesome-icon icon="bed" fixed-width />&nbsp;
                        {{ day.factor | FactorInfo }} Krankheitstag
                    </td>
                    <td :style="'width:' + (35 / 80) * 100 + '%'">{{ day.description }}</td>
                </tr>
            </table>
        </td>
    </tr>
</template>
<script>
export default {
    props: {
        timesheetdata: Object,
        date: String,
        showtimes: Boolean
    },
    data() {
        return {
            widthNumber: 13.33,
            widthProject: 40.0,
            widthDescription: 46.66
        };
    },
    computed: {
        dailyDuration() {
            let ret = 0;
            this.vacationdays.forEach(day => {
                ret += day.recordvalue_computed;
            });
            this.publicholidays.forEach(day => {
                ret += day.recordvalue_computed;
            });
            this.sickdays.forEach(day => {
                ret += day.recordvalue_computed;
            });
            this.records.forEach(day => {
                ret += this.$root.getDuration(day.starttime, day.endtime);
            });
            return ret;
        },
        numberOfEntries() {
            return (
                this.vacationdays.length +
                this.publicholidays.length +
                this.sickdays.length +
                this.records.length
            );
        },
        vacationdays() {
            let ret = [];
            for (var i = 0; i < this.timesheetdata.vacationdays.length; i++) {
                if (this.timesheetdata.vacationdays[i].date == this.date) {
                    ret.push(this.timesheetdata.vacationdays[i]);
                }
            }
            return ret;
        },
        publicholidays() {
            let ret = [];
            for (var i = 0; i < this.timesheetdata.publicholidays.length; i++) {
                if (this.timesheetdata.publicholidays[i].date == this.date) {
                    ret.push(this.timesheetdata.publicholidays[i]);
                }
            }
            return ret;
        },
        sickdays() {
            let ret = [];
            for (var i = 0; i < this.timesheetdata.sickdays.length; i++) {
                if (this.timesheetdata.sickdays[i].date == this.date) {
                    ret.push(this.timesheetdata.sickdays[i]);
                }
            }
            return ret;
        },
        records() {
            let ret = [];
            for (var i = 0; i < this.timesheetdata.records.length; i++) {
                if (this.timesheetdata.records[i].date == this.date) {
                    ret.push(this.timesheetdata.records[i]);
                }
            }
            return ret;
        }
    }
};
</script>
