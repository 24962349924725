<template>
    <span>
        <router-link
            v-if="link"
            :to="{ name: 'project.detail', params: { id: id } }"
            style="color:inherit"
        >
            <font-awesome-icon icon="building" fixed-width v-if="icon" />
        </router-link>
        <span v-else>
            <font-awesome-icon icon="building" fixed-width v-if="icon" />
        </span>
        {{ project.number }}<span v-if="showname">: {{ project.name }}</span>
    </span>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        icon: {
            type: Boolean,
            required: false,
            default: true
        },
        link: {
            type: Boolean,
            required: false,
            default: true
        },
        showname: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        project() {
            return this.$root.getProjectObject(this.id);
        }
    },
    data() {
        return {};
    }
};
</script>

<style></style>
