<template>
    <div>
        <div class="card-body" v-if="year != 0">
            <span v-if="editmode">
                Für das Jahr {{ year }} stehen
                <b-form-input
                    style="width:75px; display:inline"
                    class="mx-2 text-center"
                    v-model="newquota"
                    type="number"
                ></b-form-input>
                Urlaubstage zur Verfügung.
                <b-button-group class="">
                    <b-button variant="outline-secondary" size="sm" @click="cancelEditing()">
                        <font-awesome-icon icon="times" />&nbsp;abbrechen
                    </b-button>
                    <b-button variant="outline-primary" size="sm" @click="saveData()">
                        <font-awesome-icon icon="save" />&nbsp;speichern
                    </b-button>
                </b-button-group>
            </span>
            <span v-else>
                Für das Jahr {{ year }} stehen
                <strong>{{ quotaOfYear }} Urlaubstage</strong>
                zur Verfügung.
                <b-button
                    variant="link"
                    size="sm"
                    v-if="this.$root.userHasManagerRoleForEmployee(this.employee_id)"
                    @click="startEditing()"
                    ><font-awesome-icon icon="edit" />&nbsp;ändern</b-button
                >
            </span>
        </div>
        <table class="table table-hover mb-0">
            <thead>
                <tr>
                    <th>Datum</th>
                    <th>Faktor</th>
                    <th class="text-right">Stundenzahl</th>
                    <th>Beschreibung</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <NoEntriesFound
                    :colspan="5"
                    message="Es wurden keine Urlaubstage gefunden."
                    v-if="activeEntries.length == 0"
                />
                <tr v-else v-for="vd in activeEntries" v-bind:key="vd.date">
                    <td class="text-left">
                        {{ vd.date | DateLong }}
                        <span v-if="currentdate <= vd.date">
                            <b-badge variant="warning">geplant</b-badge>
                        </span>
                    </td>
                    <td>{{ vd.factor | DayFractions }}</td>
                    <td class="text-right">
                        {{ vd.recordvalue_computed | NumberTwoDigits }}
                    </td>
                    <td>{{ vd.description }}</td>
                    <td>
                        <!-- <button type="button" class="btn btn-link btn-sm">
                        <font-awesome-icon icon="edit" />
                    </button>
                    <button type="button" class="btn btn-link btn-sm">
                        <font-awesome-icon icon="trash-alt" />
                    </button> -->
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="font-weight-bold">
                    <td></td>
                    <td>{{ daysum | DayFractions }}</td>
                    <td class="text-right">
                        {{ recordvaluesum | NumberTwoDigits }}
                    </td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import { employeeService } from "../_services";

export default {
    props: {
        entries: Array,
        year: Number,
        quotas: Array,
        employee_id: Number
    },
    data() {
        return {
            currentdate: window.moment().format("YYYY-MM-DD"),
            editmode: false,
            newquota: 0
        };
    },
    created() {
        this.newquota = this.quotaOfYear;
    },
    methods: {
        saveData() {
            employeeService
                .updateVacationQuota(this.employee_id, { year: this.year, value: this.newquota })
                .then(response => {
                    this.$emit("vacationquota-changed", response);
                    this.$root.toast(
                        "Der Urlaubsanspruch wurde erfolgreich gespeichert.",
                        "success"
                    );
                    this.editmode = false;
                })
                .catch(err => {
                    this.$root.toast(
                        "Der Urlaubsanspruch konnte nicht gespeichert werden. Bitte überprüfen Sie Ihre Eingaben.",
                        "danger"
                    );
                });
        },
        cancelEditing() {
            this.editmode = false;
            this.newquota = this.quotaOfYear;
        },
        startEditing() {
            this.editmode = true;
        }
    },
    computed: {
        activeEntries: function() {
            let ret = [];
            this.entries.forEach(element => {
                if (this.year == 0 || element.date.includes(this.year)) {
                    ret.push(element);
                }
            });
            return ret;
        },
        daysum() {
            var ret = 0;
            this.activeEntries.forEach(element => {
                ret += element.factor;
            });
            return ret;
        },
        recordvaluesum() {
            var ret = 0;
            this.activeEntries.forEach(element => {
                ret += element.recordvalue_computed;
            });
            return ret;
        },
        quotaOfYear() {
            var q = this.quotas.filter(quota => parseInt(quota.year) == this.year);
            if (q.length == 0) {
                return 0;
            } else {
                return q[0].value;
            }
        }
    },
    watch: {
        quotaOfYear() {
            this.newquota = this.quotaOfYear;
        }
    }
};
</script>
