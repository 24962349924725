<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>Profil</h1>
        </template>

        <b-row>
            <b-col cols="8">
                <div class="card shadow mb-4">
                    <div class="card-header">
                        <!-- <span v-if="editmode">
                            <b-button-group class="float-right">
                                <b-button
                                    variant="outline-secondary"
                                    size="sm"
                                    @click="exitEditMode()"
                                >
                                    <font-awesome-icon icon="times" />&nbsp;abbrechen
                                </b-button>
                                <b-button
                                    variant="outline-primary"
                                    size="sm"
                                    @click="saveMasterData()"
                                >
                                    <font-awesome-icon icon="save" />&nbsp;speichern
                                </b-button>
                            </b-button-group>
                        </span>
                        <span v-else>
                            <b-button
                                variant="outline-primary"
                                class="float-right"
                                size="sm"
                                @click="enterEditMode()"
                            >
                                <font-awesome-icon icon="edit" />&nbsp;bearbeiten
                            </b-button>
                        </span> -->
                        <h4>Accountinformationen</h4>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Name</label>
                                <div class="col-sm-8">
                                    <b-form-input
                                        v-if="editmode"
                                        v-model="basedata.name"
                                        placeholder
                                    ></b-form-input>
                                    <p v-else>{{ basedata.name }}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">E-Mail-Adresse</label>
                                <div class="col-sm-8">
                                    <b-form-input
                                        v-if="editmode"
                                        v-model="basedata.email"
                                        placeholder
                                    ></b-form-input>
                                    <p v-else>{{ basedata.email }}</p>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Passwort</label>

                                <div class="col-sm-8">
                                    <ChangePasswordModal :userinfo="basedata" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </b-col>
            <b-col cols="4">
                <div class="card shadow mb-4">
                    <div class="card-header">
                        <h4>zugeordnete Mitarbeiter</h4>
                    </div>
                    <b-list-group-item
                        v-for="employee in basedata.employee_ids"
                        v-bind:key="employee.employee_id"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <span>
                            <strong>
                                <EmployeeName :id="employee.employee_id" :dot="false" />
                                <b-badge v-if="$root.self == employee.employee_id" variant="primary"
                                    >ICH</b-badge
                                >
                            </strong>
                            <br />
                            <span v-if="$root.userHasManagedRoleForEmployee(employee.employee_id)"
                                ><font-awesome-icon icon="user-lock" class="text-info" />
                                eingeschränkte Rechte</span
                            >
                            <span
                                v-else-if="
                                    $root.userHasManagerRoleForEmployee(employee.employee_id)
                                "
                                ><font-awesome-icon icon="user-shield" class="text-success" /> alle
                                Rechte</span
                            >
                        </span>
                    </b-list-group-item>
                </div>
            </b-col>
        </b-row>
    </base-layout>
</template>
<script>
import ChangePasswordModal from "../components/ChangePasswordModal.vue";
export default {
    data() {
        return {
            editmode: false
        };
    },
    computed: {
        basedata() {
            return this.$store.state.employees.user.data;
        }
    },
    methods: {
        enterEditMode() {
            this.editmode = true;
        },
        exitEditMode() {
            this.editmode = false;
        },
        saveMasterData() {
            this.editmode = false;
            //@TODO
        }
    },
    components: {
        ChangePasswordModal
    }
};
</script>
