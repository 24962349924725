<template>
    <tr>
        <td colspan="6" class="text-center">
            <small class="text-muted">
                <font-awesome-icon icon="info-circle" />&nbsp; Für diesen Tag wurde noch nichts
                eingetragen.
            </small>
        </td>
    </tr>
</template>
<script>
export default {};
</script>
