<template>
    <span>
        <b-button variant="outline-primary" size="sm" @click="openModal()">
            <font-awesome-icon icon="key" />&nbsp;Passwort ändern
        </b-button>
        <b-modal
            id="change-password"
            title="Passwort ändern"
            okTitle="Passwort ändern"
            cancelTitle="abbrechen"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            size="sm"
            :ok-disabled="!passwordSaveable"
        >
            <p>
                Geben Sie ein neues Passwort für den Nutzer <strong>{{ userinfo.name }}</strong>
                ein:
            </p>

            <b-form-group
                invalid-feedback="Das Passwort ist nicht stark genug."
                valid-feedback="Das Passwort ist stark genug."
                :state="newPasswordIsStrongEnough"
            >
                <b-form-input
                    :state="newPasswordIsStrongEnough"
                    v-model="newPassword"
                    type="password"
                    id="change-password-new-password"
                ></b-form-input>
            </b-form-group>
        </b-modal>
    </span>
</template>
<script>
import { userService } from "../_services";

export default {
    props: {
        userinfo: Object
    },
    data() {
        return {
            newPassword: ""
        };
    },
    methods: {
        resetModal() {
            this.newPassword = "";
        },
        handleOk() {
            if (!this.passwordSaveable) {
                return false;
            }

            userService
                .changePassword(this.userinfo.id, this.newPassword)
                .then(response => {
                    this.$root.toast("Das Passwort wurde erfolgreich geändert.", "success");
                    this.$emit("password-changed");
                })
                .catch(response => {
                    this.$root.toast("Das Passwort konnte nicht geändert werden.", "danger");
                });
        },
        openModal() {
            this.$bvModal.show("change-password");
        }
    },
    computed: {
        passwordSaveable() {
            return this.newPasswordIsStrongEnough == true;
        },
        newPasswordIsStrongEnough() {
            if (this.newPassword == "") {
                return null;
            }
            var strongRegex = new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
            );
            var mediumRegex = new RegExp(
                "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
            );

            var strength = 0;

            if (strongRegex.test(this.newPassword)) {
                strength = 1; //strong
            } else if (mediumRegex.test(this.newPassword)) {
                strength = 0; //okay
            } else {
                strength = -1; //weak
            }

            if (strength >= 0) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
