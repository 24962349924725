<template>
    <div>
        <!-- <form> -->
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Vorname</label>
            <div class="col-sm-10">
                <b-form-input
                    v-if="editmode"
                    v-model="employeedata.employee.firstname"
                    required
                ></b-form-input>
                <p v-else>{{ employeedata.employee.firstname }}</p>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Nachname</label>
            <div class="col-sm-10">
                <b-form-input
                    v-if="editmode"
                    v-model="employeedata.employee.lastname"
                    required
                ></b-form-input>
                <p v-else>{{ employeedata.employee.lastname }}</p>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Initialen</label>
            <div class="col-sm-10">
                <b-form-input
                    v-if="editmode"
                    v-model="employeedata.employee.initials"
                    required
                ></b-form-input>
                <p v-else>{{ employeedata.employee.initials }}</p>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Geburtsdatum</label>
            <div class="col-sm-10">
                <DatePicker v-if="editmode" v-model="employeedata.employee.birthday" />
                <p v-else>{{ employeedata.employee.birthday | DateFullShort }}</p>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">E-Mail-Adresse</label>
            <div class="col-sm-10">
                <b-form-input v-if="editmode" v-model="employeedata.employee.email"></b-form-input>
                <a v-else :href="'mailto:' + employeedata.employee.email">
                    {{ employeedata.employee.email }}
                </a>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Telefonnummer (geschäftlich)</label>
            <div class="col-sm-10">
                <b-form-input v-if="editmode" v-model="employeedata.employee.phone"></b-form-input>
                <a v-else :href="'tel:' + employeedata.employee.phone">
                    {{ employeedata.employee.phone }}
                </a>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Handynummer (geschäftlich)</label>
            <div class="col-sm-10">
                <b-form-input v-if="editmode" v-model="employeedata.employee.mobile"></b-form-input>
                <a v-else :href="'tel:' + employeedata.employee.mobile">
                    {{ employeedata.employee.mobile }}
                </a>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Snipe (Assets)</label>
            <div class="col-sm-10">
                <b-form-input
                    v-if="editmode"
                    v-model="employeedata.employee.link_snipe"
                ></b-form-input>
                <a
                    v-else-if="employeedata.employee.link_snipe != ''"
                    class="btn btn-outline-primary"
                    :href="employeedata.employee.link_snipe"
                >
                    Snipe öffnen
                </a>
                <p v-else class="text-muted">kein Snipe-Link hinterlegt.</p>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Asana (Aufgaben)</label>
            <div class="col-sm-10">
                <b-form-input
                    v-if="editmode"
                    v-model="employeedata.employee.link_asana"
                ></b-form-input>
                <a
                    v-else-if="employeedata.employee.link_asana != ''"
                    class="btn btn-outline-primary"
                    :href="employeedata.employee.link_asana"
                >
                    Asana öffnen
                </a>
                <p v-else class="text-muted">kein Asana-Link hinterlegt.</p>
            </div>
        </div>

        <div
            class="form-group row"
            v-if="$root.userHasManagerRoleForEmployee(employeedata.employee.id)"
        >
            <label class="col-sm-2 col-form-label">Farbe</label>
            <div class="col-sm-10">
                <b-form-input
                    v-if="editmode"
                    v-model="employeedata.employee.basecolor"
                    type="color"
                ></b-form-input>
                <p v-else>
                    <span :style="'color:' + employeedata.employee.basecolor">
                        <font-awesome-icon icon="circle" />
                    </span>
                    {{ employeedata.employee.basecolor }}
                </p>
            </div>
        </div>
        <!-- <div class="form-group row">
                <label class="col-sm-2 col-form-label">Notes</label>
                <div class="col-sm-10">
                    <b-form-textarea
                        v-if="editmode"
                        rows="6"
                        v-model="employeedata.employee.notes"
                    />
                    <p class="text-pre-line" v-else>{{ employeedata.employee.notes }}</p>
                </div>
            </div> -->
        <!-- </form> -->

        <b-button
            v-if="editmode"
            variant="outline-secondary"
            size="sm"
            class="mb-3 mt-3 float-right"
            v-on:click="newEntry()"
        >
            <font-awesome-icon icon="plus-square" />&nbsp;Neues Arbeitszeitmodell
        </b-button>
        <table class="table table-with-bottom-line table-sm">
            <thead>
                <tr>
                    <th style="width:11.5%">Startdatum</th>
                    <th style="width:11.5%">Enddatum</th>
                    <th style="width:20%">Status</th>
                    <th style="width:6%">Mo</th>
                    <th style="width:6%">Di</th>
                    <th style="width:6%">Mi</th>
                    <th style="width:6%">Do</th>
                    <th style="width:6%">Fr</th>
                    <!-- <th style="width:6%">Sa</th>
                    <th style="width:6%">So</th> -->
                    <th style="width:10%">Beschreibung</th>
                    <th style="width:5%"></th>
                </tr>
            </thead>
            <tbody v-if="editmode">
                <tr v-for="entry in employeedata.employee.meta" v-bind:key="entry.id">
                    <td>
                        <DatePicker v-model="entry.datefrom" :allownull="false" />
                    </td>
                    <td>
                        <DatePicker v-model="entry.dateto" :allownull="true" />
                    </td>
                    <td>
                        <b-form-select
                            v-model="entry.status"
                            :options="employeestatusoptions"
                            size="sm"
                        ></b-form-select>
                    </td>
                    <td>
                        <b-form-input
                            v-if="entry.status == 1"
                            v-model="entry.quotamon"
                            type="number"
                        ></b-form-input>
                    </td>
                    <td>
                        <b-form-input
                            v-if="entry.status == 1"
                            v-model="entry.quotatue"
                            type="number"
                        ></b-form-input>
                    </td>
                    <td>
                        <b-form-input
                            v-if="entry.status == 1"
                            v-model="entry.quotawed"
                            type="number"
                        ></b-form-input>
                    </td>
                    <td>
                        <b-form-input
                            v-if="entry.status == 1"
                            v-model="entry.quotathu"
                            type="number"
                        ></b-form-input>
                    </td>
                    <td>
                        <b-form-input
                            v-if="entry.status == 1"
                            v-model="entry.quotafri"
                            type="number"
                        ></b-form-input>
                    </td>
                    <!-- <td>
                        <b-form-input
                            v-if="entry.status == 1"
                            v-model="entry.quotasat"
                            type="number"
                        ></b-form-input>
                    </td>
                    <td>
                        <b-form-input
                            v-if="entry.status == 1"
                            v-model="entry.quotasun"
                            type="number"
                        ></b-form-input>
                    </td> -->
                    <td>
                        <b-form-input v-model="entry.description"></b-form-input>
                    </td>
                    <td class="text-right">
                        <button
                            type="button"
                            class="btn btn-link btn-sm"
                            v-on:click="deleteEntry(entry.id)"
                        >
                            <font-awesome-icon icon="trash-alt" />
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="entry in employeedata.employee.meta" v-bind:key="entry.id">
                    <td>{{ entry.datefrom | DateFullShort }}</td>
                    <td>
                        <span v-if="entry.dateto != null">{{ entry.dateto | DateFullShort }}</span>
                        <span class="text-muted" v-else>unbegrenzt</span>
                    </td>
                    <td>
                        <span v-if="entry.status == 1">
                            <font-awesome-icon icon="circle" class="text-success" /> angestellt
                            <br /><small class="text-mute"
                                >Abrechnung laut Arbeitszeitmodell inkl. Feiertagen</small
                            >
                        </span>
                        <span v-else-if="entry.status == 2">
                            <font-awesome-icon icon="circle" class="text-info" /> freier Mitarbeiter
                            <br /><small class="text-mute">individuelle Stundenabrechnung</small>
                        </span>
                        <span v-else>
                            <font-awesome-icon icon="times-circle" class="text-danger" /> inaktiv
                            <br /><small class="text-mute">Stundeneintragung deaktiviert</small>
                        </span>
                    </td>
                    <td v-if="entry.status == 1">{{ entry.quotamon }}</td>
                    <td v-if="entry.status == 1">{{ entry.quotatue }}</td>
                    <td v-if="entry.status == 1">{{ entry.quotawed }}</td>
                    <td v-if="entry.status == 1">{{ entry.quotathu }}</td>
                    <td v-if="entry.status == 1">{{ entry.quotafri }}</td>
                    <!-- <td v-if="entry.status == 1">{{ entry.quotasat }}</td>
                    <td v-if="entry.status == 1">{{ entry.quotasun }}</td> -->
                    <td v-else colspan="5"></td>
                    <td>{{ entry.description }}</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import DatePicker from "../components/Global/DatePicker.vue";
export default {
    props: {
        employeedata: Object,
        editmode: Boolean
    },
    components: {
        DatePicker
    },
    data() {
        return {
            counter: 0,
            offset: 9000000,
            employeestatusoptions: [
                {
                    value: "1",
                    text: "angestellt: Abrechnung laut Arbeitszeitmodell inkl. Feiertagen"
                },
                { value: "2", text: "freier Mitarbeiter: individuelle Stundenabrechnung" },
                { value: "0", text: "inaktiv: Stundeneintragung deaktiviert" }
            ]
        };
    },
    created() {
        if (this.employeedata.employee.meta.length == 0) {
            this.newEntry();
        }
    },
    methods: {
        newEntry() {
            this.counter += 1;
            let empty = {
                id: this.offset + this.counter,
                employee_id: this.employeedata.employee.id,
                datefrom: "",
                dateto: "",
                status: 1,
                quotamon: 0,
                quotatue: 0,
                quotawed: 0,
                quotathu: 0,
                quotafri: 0,
                quotasat: 0,
                quotasun: 0,
                description: ""
            };
            this.employeedata.employee.meta.push(empty);
        },
        deleteEntry: function(entryid) {
            let newentries = [];
            this.employeedata.employee.meta.forEach(element => {
                if (element.id != entryid) {
                    newentries.push(element);
                }
            });
            this.employeedata.employee.meta = newentries;
        }
    }
};
</script>
