<template>
    <base-layout fluid>
        <template v-slot:header-title>
            <h1>
                Mitarbeiter- und Benutzerverwaltung
                <br />
                <span class="font-weight-bold"> Neuen Mitarbeiter anlegen</span>
            </h1>
        </template>

        <template v-slot:page-sub-menu>
            <UsersSubMenu active="employees-create" />
        </template>

        <b-form @submit="onSubmit">
            <EmployeeMasterData :employeedata="newEmployee" :editmode="true" />

            <b-button type="submit" variant="primary">neuen Mitarbeiter anlegen</b-button>
        </b-form>
    </base-layout>
</template>
<script>
import UsersSubMenu from "../components/SubMenus/UsersSubMenu.vue";
import EmployeeMasterData from "../components/EmployeeMasterData.vue";
import { employeeService } from "../_services";

export default {
    components: {
        UsersSubMenu,
        EmployeeMasterData
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();
            employeeService
                .createOne(this.newEmployee.employee)
                .then(response => {
                    this.$store.dispatch("employees/getAll"); //@TODO: implement into delete/update/create service methods directly?
                    this.$root.toast("Der neue Mitarbeiter wurde erfolgreich angelegt.", "success");
                    this.$router.push({
                        name: "users.overview"
                    });
                })
                .catch(err => {
                    this.$root.toast(
                        "Es konnte kein neuer Mitarbeiter angelegt werden. Bitte überprüfen Sie Ihre Eingaben.",
                        "danger"
                    );
                });
        }
    },
    data() {
        return {
            newEmployee: {
                employee: {
                    firstname: "",
                    lastname: "",
                    initials: "",
                    notes: "",
                    basecolor: "#a7a7a7",
                    meta: []
                }
            }
        };
    }
};
</script>
