import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
        chartData: Object
    },
    data() {
        return {
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            stacked: true
                        }
                    ],
                    xAxes: [
                        {
                            stacked: true
                        }
                    ]
                }
            }
        };
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    }
};
