<template>
    <div class="card zweige-project-card shadow">
        <router-link
            :to="{ name: 'project.detail', params: { id: projectinfo.id } }"
            class="card-header"
        >
            <b-badge variant="dark">{{ $root.StatusFullTitle(projectinfo.status_id) }}</b-badge>
            <h2>{{ projectinfo.number }}</h2>
            <div class="zweige-project-info">
                <h3>{{ projectinfo.name }}</h3>
                <span v-if="projectinfo.locationcity">{{ projectinfo.locationcity }}</span>
            </div>
        </router-link>
        <div class="card-body">
            <div class="row no-gutters text-center">
                <div class="col">
                    <div v-if="projectinfo.linkasana !== ''" v-b-tooltip.hover title="Asana">
                        <a :href="projectinfo.linkasana" target="_blank">
                            <font-awesome-icon fixed-width :icon="['fas', 'tasks']" class="fa-2x" />
                        </a>
                    </div>
                </div>
                <div class="col">
                    <div v-if="projectinfo.linkavanova !== ''" v-b-tooltip.hover title="Avanova">
                        <a :href="projectinfo.linkavanova" target="_blank">
                            <font-awesome-icon
                                fixed-width
                                :icon="['fas', 'list-ul']"
                                class="fa-2x"
                            />
                        </a>
                    </div>
                </div>
                <div class="col">
                    <div v-if="projectinfo.linkbtb !== ''" v-b-tooltip.hover title="Bautagebuch">
                        <a :href="projectinfo.linkbtb" target="_blank">
                            <font-awesome-icon
                                fixed-width
                                :icon="['fas', 'clipboard-list']"
                                class="fa-2x"
                            />
                        </a>
                    </div>
                </div>
                <div class="col">
                    <!--@TODO: wie machen? v-if="$root.UserHasRight('canManageProjects')"-->
                    <div v-if="projectinfo.hasrecords" v-b-tooltip.hover title="Stundeneinträge">
                        <router-link
                            :to="{
                                name: 'project.recordoverview',
                                params: { id: projectinfo.id }
                            }"
                        >
                            <font-awesome-icon
                                fixed-width
                                :icon="['fas', 'clipboard']"
                                class="fa-2x"
                            />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        projectinfo: Object
    },
    components: {},
    data: function() {
        return {};
    },
    methods: {},
    computed: {}
};
</script>
