<template>
    <tr>
        <td>
            <ProjectSelector :id="entry.id" v-model="entry.project_id" />
            <p class="text-muted small mb-0" v-if="entry.project_id == COMPTIME_PROJECTID">
                Zeitausgleich wird eingetragen, um Abwesenheit an diesem Tag festzuhalten und wird
                automatisch mit 0,00 Stunden berechnet.
            </p>
        </td>
        <td>
            <b-form-input
                v-show="entry.project_id != COMPTIME_PROJECTID"
                type="time"
                class="rpepd-starttime-input"
                step="900"
                v-model="localstarttime"
                v-on:change="onChangeStarttime()"
                :state="validStartTime"
            />
        </td>
        <td>
            <b-form-input
                v-show="entry.project_id != COMPTIME_PROJECTID"
                type="time"
                class="rpepd-endtime-input"
                step="900"
                v-model="localendtime"
                v-on:change="onChangeEndtime()"
                :state="validEndTime"
            />
        </td>
        <td class="text-right">{{ duration | NumberTwoDigitsOrEmptyString }}</td>
        <td>
            <ResizeAuto>
                <template v-slot:default="{ resize }">
                    <b-form-textarea
                        ref="localdescription"
                        maxlength="200"
                        rows="2"
                        class="rpepd-description-input form-control"
                        @input="resize"
                        v-model="localdescription"
                        v-on:change="onChangeDescription()"
                        @focus="hasFocus = true"
                        @blur="hasFocus = false"
                    />
                </template>
            </ResizeAuto>
            <ServiceBadges
                v-if="hasFocus"
                :project_id="entry.project_id"
                :description="this.entry.description"
                v-on:set-badge="setBadge($event)"
                v-on:set-tag="setTag($event)"
            />
        </td>
        <td>
            <button type="button" class="btn btn-link btn-sm" v-on:click="onDeleteEntry()">
                <font-awesome-icon icon="trash-alt" />
            </button>
        </td>
    </tr>
</template>

<script>
import ProjectSelector from "../ProjectSelector.vue";
import ResizeAuto from "../Global/ResizeAuto.vue";
import ServiceBadges from "../ServiceBadges.vue";

export default {
    props: {
        entry: Object
    },
    components: {
        ProjectSelector,
        ResizeAuto,
        ServiceBadges
    },
    data: function() {
        return {
            localstarttime: this.entry.starttime,
            localendtime: this.entry.endtime,
            localdescription: this.entry.description,
            COMPTIME_PROJECTID: 79,
            hasFocus: false
        };
    },
    computed: {
        duration: function() {
            var etd = window.moment.duration(this.localendtime);
            var std = window.moment.duration(this.localstarttime);
            if (etd >= std) {
                var duration = etd.subtract(std);
                return duration / 3600000;
            } else {
                // display no duration
                return "";
            }
        },
        validStartTime: function() {
            var std = window.moment.duration(this.localstarttime);
            if (this.validStartEndTime === false) {
                return false;
            }
            if (
                this.localstarttime === null ||
                std.as("milliseconds") / 900000 == parseInt(std.as("milliseconds") / 900000)
            ) {
                return null;
            } else {
                return false;
            }
        },
        validEndTime: function() {
            var etd = window.moment.duration(this.localendtime);
            if (this.validStartEndTime === false) {
                return false;
            }
            if (
                this.localendtime === null ||
                etd.as("milliseconds") / 900000 == parseInt(etd.as("milliseconds") / 900000)
            ) {
                return null;
            } else {
                return false;
            }
        },
        validStartEndTime: function() {
            var etd = window.moment.duration(this.localendtime);
            var std = window.moment.duration(this.localstarttime);
            if (this.localendtime === null || this.localstarttime === null) {
                return null;
            } else if (etd >= std) {
                return null;
            } else {
                return false;
            }
        }
    },
    methods: {
        onDeleteEntry() {
            this.$emit("entry-deleted", event);
        },
        setProject(event) {
            if (event == this.COMPTIME_PROJECTID) {
                this.localstarttime = "00:00:00";
                this.onChangeStarttime();
                this.localendtime = "00:00:00";
                this.onChangeEndtime();
            }
            this.$emit("project-selected", event);
        },
        onChangeStarttime() {
            this.$emit("starttime-changed", this.localstarttime);
        },
        onChangeEndtime() {
            this.$emit("endtime-changed", this.localendtime);
        },
        onChangeDescription() {
            this.$emit("description-changed", this.localdescription);
        },
        setBadge(event) {
            this.localdescription += "" + event + ": ";
            this.onChangeDescription();
            this.$refs.localdescription.focus();
        },
        setTag(event) {
            this.localdescription += "" + event + ", ";
            this.onChangeDescription();
            this.$refs.localdescription.focus();
        }
    }
};
</script>
