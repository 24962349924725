<template>
    <div>
        <SidebarNav v-if="this.$root.isLoggedIn" />
        <div id="app">
            <div id="main-container">
                <div>
                    <header class="mt-3 mb-4">
                        <b-container :fluid="fluid">
                            <div class="d-none d-print-block mb-5">
                                <strong>2G Architekten</strong> | Dipl.-Ing. (FH) Architekt
                                Sebastian Grundler | Sedanstraße 12 | 92637 Weiden/OPf. |
                                info@2g-architekten.de | www.2g-architekten.de
                            </div>

                            <slot name="header-title"> </slot>
                            <p class="small text-muted d-none d-print-block">
                                Stand: {{ now | ReadableTimestamp }}
                            </p>
                        </b-container>
                    </header>

                    <div v-if="hasSubMenuSlot" class="mb-4 d-print-none">
                        <slot name="page-sub-menu"> </slot>
                    </div>
                    <div v-if="hasOptionsSlot" class="page-options d-print-none my-2">
                        <b-container :fluid="fluid">
                            <b-card no-body class="card-condensed">
                                <b-card-body>
                                    <b-button
                                        size="sm"
                                        variant="outline-secondary"
                                        class="float-right"
                                        @click="localVisibleOptions = !localVisibleOptions"
                                    >
                                        {{ ToggleOptionsText }} &nbsp;<font-awesome-icon
                                            :icon="ToggleOptionsIcon"
                                        />
                                    </b-button>

                                    <h6 class="mb-0">Optionen</h6>
                                </b-card-body>

                                <b-collapse
                                    id="collapse-options-content"
                                    :visible="localVisibleOptions"
                                >
                                    <b-card-body>
                                        <b-card-text>
                                            <slot name="options"> </slot>
                                        </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-container>
                    </div>

                    <main class="mt-4" id="the-page">
                        <b-container :fluid="fluid">
                            <div class="row justify-content-center">
                                <div class="col-md-12">
                                    <div>
                                        <slot></slot>
                                    </div>
                                </div>
                            </div>
                        </b-container>
                    </main>
                    <footer>
                        <b-container :fluid="fluid">
                            <div class="small text-muted">
                                <slot name="footer"></slot>
                            </div>
                            <StoreCheck />
                        </b-container>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarNav from "./SidebarNav.vue";

export default {
    props: {
        fluid: Boolean,
        visibleOptions: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        SidebarNav
    },
    data() {
        return {
            now: window.moment(),
            localVisibleOptions: this.visibleOptions
        };
    },
    computed: {
        hasOptionsSlot() {
            return !!this.$slots["options"];
        },
        hasSubMenuSlot() {
            return !!this.$slots["page-sub-menu"];
        },
        ToggleOptionsIcon() {
            if (this.localVisibleOptions == false) {
                return "chevron-down";
            } else {
                return "chevron-up";
            }
        },
        ToggleOptionsText() {
            if (this.localVisibleOptions == false) {
                return "einblenden";
            } else {
                return "ausblenden";
            }
        }
    }
};
</script>
