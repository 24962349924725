<template>
    <tr>
        <td style="width:250px">
            <h3><EmployeeName :id="employee.id" :dot="false" /></h3>
            <EmployeeLinks
                class="float-none mt-2"
                :employee_id="employee.id"
                :showtimesheetlinks="true"
            />
        </td>
        <td>
            <p class="display-4">
                <small
                    :class="getDotColor(OvertimeFromLastMonth, 50)"
                    v-if="$root.EmployeeIsEmployed(employee.id)"
                >
                    <font-awesome-icon icon="circle" />
                </small>
                {{ OvertimeFromLastMonth | NumberTwoDigitsPlusMinusFirstHalf
                }}<sup class="duration-sup">
                    {{ OvertimeFromLastMonth | NumberTwoDigitsPlusMinusSecondHalf }}</sup
                >
            </p>
        </td>
        <td>
            <p class="display-4">
                <small
                    :class="getDotColor(OvertimeUntilYesterday, 20)"
                    v-if="$root.EmployeeIsEmployed(employee.id)"
                >
                    <font-awesome-icon icon="circle" />
                </small>
                {{ OvertimeUntilYesterday | NumberTwoDigitsPlusMinusFirstHalf
                }}<sup class="duration-sup">
                    {{ OvertimeUntilYesterday | NumberTwoDigitsPlusMinusSecondHalf }}</sup
                >
            </p>
        </td>
        <td class="text-right">
            <p class="larger" v-if="$root.EmployeeIsEmployed(employee.id)">
                {{ vacation.available | NumberTwoDigits }}
            </p>
            <small class="text-sm text-muted" v-if="vacation.remaining > vacation.taken">
                <font-awesome-icon icon="exclamation-triangle" class="text-danger" />
                {{ (vacation.remaining - vacation.taken) | NumberTwoDigits }} Tage nicht <br />
                genommener Alturlaub
            </small>
        </td>
        <td class="text-right">
            <p class="larger" v-if="$root.EmployeeIsEmployed(employee.id)">
                {{ vacation.taken | NumberTwoDigits }}
            </p>
        </td>
        <td class="text-right">
            <p class="larger" v-if="$root.EmployeeIsEmployed(employee.id)">
                {{ vacation.planned | NumberTwoDigits }}
            </p>
        </td>
        <td class="text-right">
            <p class="larger" v-if="$root.EmployeeIsEmployed(employee.id)">
                {{ VacationUnplanned | NumberTwoDigits }}
            </p>
        </td>
        <td style="width:200px">
            <b-progress
                class="mt-2"
                :max="vacation.available"
                v-if="$root.EmployeeIsEmployed(employee.id)"
            >
                <b-progress-bar :value="vacation.taken" variant="success"></b-progress-bar>
                <b-progress-bar :value="vacation.planned" variant="info"></b-progress-bar>
                <b-progress-bar :value="VacationUnplanned" variant="danger"></b-progress-bar>
            </b-progress>
        </td>
    </tr>
</template>

<script>
import EmployeeLinks from "../components/EmployeeLinks.vue";

export default {
    data() {
        return {
            currentperiod: window.moment().format("YYYY-MM"),
            currentyear: window.moment().format("YYYY")
        };
    },
    props: {
        employee: {
            type: Object,
            required: true
        },
        meta: {
            type: Array,
            required: true
        },
        monthlysums: {
            type: Array,
            required: true
        },
        currentmonth: {
            type: Object,
            required: true
        },
        vacation: {
            type: Object,
            required: false
        }
    },
    mounted() {},
    computed: {
        VacationUnplanned() {
            return this.vacation.available - this.vacation.taken - this.vacation.planned;
        },
        QuotaUntilYesterday() {
            let current = window.moment().startOf("month");
            let today = window
                .moment()
                .startOf("day")
                .format("YYYY-MM-DD");
            let quota = 0;
            while (current.format("YYYY-MM-DD") != today) {
                quota += this.getQuotaOfDay(current);
                current = current.add(1, "days");
            }
            return quota;
        },
        OvertimeFromLastMonth() {
            let sum = 0;
            this.monthlysums.forEach(element => {
                if (element.period < this.currentperiod) {
                    sum = sum + element.haben_sum - element.soll_sum;
                }
            });
            return sum;
        },
        RecordsUntilYesterday() {
            let yesterday = window
                .moment()
                .subtract(1, "day")
                .format("YYYY-MM-DD");
            let sum = 0;
            this.currentmonth.records.forEach(element => {
                if (element.date <= yesterday) {
                    sum +=
                        window.moment
                            .duration(element.endtime)
                            .subtract(window.moment.duration(element.starttime)) / 3600000;
                }
            });
            this.currentmonth.publicholidays.forEach(element => {
                if (element.date <= yesterday) {
                    sum += element.recordvalue_computed;
                }
            });
            this.currentmonth.sickdays.forEach(element => {
                if (element.date <= yesterday) {
                    sum += element.recordvalue_computed;
                }
            });
            this.currentmonth.vacationdays.forEach(element => {
                if (element.date <= yesterday) {
                    sum += element.recordvalue_computed;
                }
            });
            return sum;
        },
        OvertimeUntilYesterday() {
            return this.RecordsUntilYesterday - this.QuotaUntilYesterday;
        }
    },
    methods: {
        getQuotaOfDay(day) {
            let date = day.format("YYYY-MM-DD");
            for (let i = 0; i < this.meta.length; i++) {
                let row = this.meta[i];
                if (row.datefrom > date) {
                    // nothing found, because $date is before any metas
                    return 0; //@TODO throw error?!
                } else if (row.datefrom <= date && (row.dateto >= date || row.dateto === null)) {
                    // correct one found: save correct weekday-quota
                    let weekdayOfDate = parseInt(day.format("E"));
                    let ret = 0;
                    if (weekdayOfDate == 1) {
                        ret = row.quotamon;
                    } else if (weekdayOfDate == 2) {
                        ret = row.quotatue;
                    } else if (weekdayOfDate == 3) {
                        ret = row.quotawed;
                    } else if (weekdayOfDate == 4) {
                        ret = row.quotathu;
                    } else if (weekdayOfDate == 5) {
                        ret = row.quotafri;
                    } else if (weekdayOfDate == 6) {
                        ret = row.quotasat;
                    } else if (weekdayOfDate == 7) {
                        ret = row.quotasun;
                    }
                    return ret;
                } else {
                    // maybe next one?
                }
            }
        },
        getDotColor(value, threshold) {
            if (value > threshold || value < -threshold) {
                return "text-danger";
            } else {
                return "text-success";
            }
        }
    },
    components: {
        EmployeeLinks
    }
};
</script>

<style scoped>
.larger {
    font-size: 130%;
    margin: 0;
}
</style>
