<template>
    <table class="table">
        <tbody>
            <tr>
                <td>
                    Urlaubstage aus vorherigen Jahren
                    <b-badge variant="warning" v-if="year > currentyear">vorläufig</b-badge>
                </td>
                <td class="text-right">
                    {{ employeevacationdays.availablevacationdays.yearbefore | DayFractions }}
                </td>
            </tr>
            <tr>
                <td>
                    Urlaubstage für das Jahr {{ year }}
                    <b-badge variant="warning" v-if="year > currentyear">vorläufig</b-badge>
                </td>
                <td class="text-right">
                    {{ employeevacationdays.availablevacationdays.currentyear | DayFractions }}
                </td>
            </tr>
            <tr>
                <td>
                    Gesamtbudget
                    <b-badge variant="warning" v-if="year > currentyear">vorläufig</b-badge>
                </td>
                <td class="text-right">
                    {{
                        (employeevacationdays.availablevacationdays.yearbefore +
                            employeevacationdays.availablevacationdays.currentyear)
                            | DayFractions
                    }}
                </td>
            </tr>
            <tr v-if="year <= currentyear">
                <td>
                    bereits genommener Urlaub
                    <br />
                    <small class="text-muted" v-if="year == currentyear"
                        >von 01.01.{{ year }} bis gestern</small
                    >
                    <small class="text-muted" v-else
                        >von 01.01.{{ year }} bis 31.12.{{ year }}</small
                    >
                </td>
                <td class="text-right">{{ vacationdaysAlreadyTaken() | DayFractions }}</td>
            </tr>
            <tr v-if="year >= currentyear">
                <td>
                    bisher geplanter Urlaub
                    <br />
                    <small class="text-muted" v-if="year == currentyear"
                        >von heute bis 31.12.{{ year }}</small
                    >
                    <small class="text-muted" v-else
                        >von 01.01.{{ year }} bis 31.12.{{ year }}</small
                    >
                </td>
                <td class="text-right">{{ vacationdaysAlreadyPlanned() | DayFractions }}</td>
            </tr>
            <tr>
                <td>
                    übriges Urlaubsbudget
                    <br />
                    <small class="text-muted" v-if="year >= currentyear"
                        >bis 31.12.{{ year }}</small
                    >
                    <small class="text-muted" v-else
                        >übernommen für {{ year + 1 }}&nbsp;<font-awesome-icon icon="check-circle"
                    /></small>
                </td>
                <td class="text-right">{{ vacationdaysLeft() | DayFractions }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    data() {
        return {
            today: window.moment().format("YYYY-MM-DD"),
            currentyear: window.moment().format("YYYY")
        };
    },
    props: {
        employeevacationdays: {
            type: Object,
            required: true
        }
    },
    computed: {
        year() {
            if (this.employeevacationdays.year == null) {
                return parseInt(window.moment().format("YYYY"));
            } else {
                return parseInt(
                    window.moment(this.employeevacationdays.year, "YYYY").format("YYYY")
                );
            }
        },
        firstdayofyear() {
            return this.year + "-01-01";
        }
    },
    methods: {
        vacationdaysAlreadyTaken() {
            let sum = 0;
            this.employeevacationdays.vacationdays.forEach(vacationday => {
                if (vacationday.date >= this.firstdayofyear && vacationday.date < this.today) {
                    sum += vacationday.factor;
                }
            });
            return sum;
        },
        vacationdaysAlreadyPlanned() {
            let sum = 0;
            this.employeevacationdays.vacationdays.forEach(vacationday => {
                if (vacationday.date >= this.today) {
                    sum += vacationday.factor;
                }
            });
            return sum;
        },
        vacationdaysLeft() {
            return (
                this.employeevacationdays.availablevacationdays.yearbefore +
                this.employeevacationdays.availablevacationdays.currentyear -
                this.vacationdaysAlreadyTaken() -
                this.vacationdaysAlreadyPlanned()
            );
        }
    }
};
</script>
