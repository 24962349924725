// import config from 'config';
import { authHeader } from "../_helpers";
import axios from "axios";

export const projectService = {
    getAll,
    findOne,
    updateOne,
    deleteOne,
    createOne,
    getAllRecords,
    getAllAggregates,
    getWarranties,
    getWarrantiesOfProject,
    createWarranty,
    updateWarranty,
    deleteWarranty,
    createInAvanova,
    createInAsana
    // getAllDriverslogentries,
    // getAllDriverslogentriesByCar,
    // saveAllDriverslogentriesByCar
};

const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: authHeader()
});

function getAll() {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(process.env.VUE_APP_API_URL + `projects`, requestOptions).then(handleResponse);
}

function findOne(id) {
    return client.get(`projects/${id}`);
}

function updateOne(id, data) {
    return client.put(`projects/${id}`, data);
}

function deleteOne(id) {
    return client.delete(`projects/${id}`);
}

function createOne(data) {
    return client.post("projects", data);
}

function getAllRecords(project_id) {
    return client.get(`projects/${project_id}/records`);
}

function getAllAggregates(project_id) {
    return client.get(`projects/${project_id}/aggregates`);
}

function getWarranties() {
    return client.get(`warranties`);
}

function getWarrantiesOfProject(project_id) {
    return client.get(`projects/${project_id}/warranties`);
}

function createWarranty(data) {
    return client.post(`warranties`, data);
}

function updateWarranty(id, data) {
    return client.put(`warranties/${id}`, data);
}

function deleteWarranty(id) {
    return client.delete(`warranties/${id}`);
}

function createInAvanova(project_id) {
    return client.post(`projects/${project_id}/avanova`);
}

function createInAsana(project_id) {
    return client.post(`projects/${project_id}/asana`);
}

// function getAllDriverslogentries() {
//     return client.get(`driverslogentries`);
// }
// function getAllDriverslogentriesByCar(id) {
//     return client.get(`driverslogentries/${id}`);
// }

// function saveAllDriverslogentriesByCar(id, data) {
//     return client.put(`driverslogentries/${id}`, data);
// }
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
