<template>
    <div>
        <b-row>
            <b-col>
                <Loading v-if="loading || employee_id == 0" />

                <div v-else-if="employeeInactive" class="text-center">
                    <font-awesome-icon icon="times-circle" size="3x" />
                    <p class="font-size-lg">
                        Für den gewählten Tag können keine Stunden eingetragen oder geändert werden,
                        da <EmployeeName :id="recorddata.employee.id" :dot="false" />inaktiv ist.
                    </p>
                </div>
                <div v-else>
                    <b-button-group size="sm" class="mb-3 float-right">
                        <b-button variant="outline-secondary" v-on:click="newBsTime">
                            <font-awesome-icon icon="school" />&nbsp;Berufsschule
                        </b-button>
                        <b-button variant="outline-secondary" v-on:click="newCompTime">
                            <font-awesome-icon icon="exchange-alt" />&nbsp;Zeitausgleich
                        </b-button>
                        <b-button variant="outline-secondary" v-on:click="newEntry">
                            <font-awesome-icon icon="plus-square" />&nbsp;Neue Projektbuchung
                        </b-button>
                    </b-button-group>
                    <div class="table-responsive">
                        <table class="table zweige-rpepd-record-table">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 35%">Projekt</th>
                                    <th scope="col" style="width: 10%">von</th>
                                    <th scope="col" style="width: 10%">bis</th>
                                    <th scope="col" style="width: 5%" class="text-right">
                                        <font-awesome-icon icon="stopwatch" />
                                    </th>
                                    <th scope="col" style="width: 35%">Bemerkung</th>
                                    <th scope="col" style="width: 5%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <RpepdNoTableEntry v-if="numberOfEntries == 0"></RpepdNoTableEntry>
                                <RpepdRecordTableEntry
                                    v-for="entry in this.recorddata.records"
                                    v-bind:key="entry.id"
                                    :entry="entry"
                                    v-on:project-selected="entry.project_id = $event"
                                    v-on:entry-deleted="deleteEntry(entry.id)"
                                    v-on:starttime-changed="entry.starttime = $event"
                                    v-on:endtime-changed="entry.endtime = $event"
                                    v-on:description-changed="entry.description = $event"
                                ></RpepdRecordTableEntry>
                                <RpepdOtherTableEntry
                                    type="publicholiday"
                                    v-for="entry in this.recorddata.publicholidays"
                                    v-bind:key="entry.id"
                                    :entry="entry"
                                ></RpepdOtherTableEntry>
                                <RpepdOtherTableEntry
                                    type="sickday"
                                    v-for="entry in this.recorddata.sickdays"
                                    v-bind:key="entry.id"
                                    :entry="entry"
                                ></RpepdOtherTableEntry>
                                <RpepdOtherTableEntry
                                    type="vacationday"
                                    v-for="entry in this.recorddata.vacationdays"
                                    v-bind:key="entry.id"
                                    :entry="entry"
                                ></RpepdOtherTableEntry>
                            </tbody>
                            <tfoot>
                                <tr v-if="quota">
                                    <th colspan="6" class="p-0">
                                        <b-progress class="quota-progress" :max="quota">
                                            <b-progress-bar
                                                :value="recordSum"
                                                variant="success"
                                            ></b-progress-bar>
                                        </b-progress>
                                    </th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th colspan="2">
                                        <span v-if="hasOverlappingTimes && !beforeBreaktimeCheck">
                                            <span class="text-danger">
                                                <font-awesome-icon icon="exclamation-circle" />
                                            </span>
                                            <small class="text-muted"
                                                >&nbsp;Es wurden überlappende Zeiten
                                                eingetragen</small
                                            >
                                        </span>
                                        <span
                                            v-else-if="
                                                hasRecords &&
                                                    !beforeBreaktimeCheck &&
                                                    breaktimeSum >= 0
                                            "
                                        >
                                            <span :class="breaktimeDotColor">
                                                <font-awesome-icon icon="circle" />
                                            </span>
                                            <small class="text-muted">
                                                {{
                                                    breaktimeSum | MinutesOrHoursBreaktime
                                                }}&nbsp;Pause
                                            </small>
                                        </span>
                                    </th>
                                    <th class="text-right">
                                        {{ recordSum | NumberTwoDigits }}
                                    </th>
                                    <th colspan="2">
                                        <RpepdOvertimeInfo :quota="quota" :recordsum="recordSum" />
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <RpepdFastLane v-on:new-fastlane-entry="addFastLaneEntry($event)" />
                </div>
            </b-col>
        </b-row>
        <b-navbar
            toggleable="lg"
            class="zweige-fixed-bottom-container"
            fixed="bottom"
            v-if="
                recorddata.employee === undefined ||
                    recorddata.employee.dateinfo.statustitle != 'inactive'
            "
        >
            <div class="ml-auto">
                <b-button-group class="mt-4 mb-4">
                    <b-button variant="outline-primary" v-on:click="resetRecordData()"
                        >zurücksetzen</b-button
                    >
                    <b-button variant="primary" v-on:click="updateRecordData()">
                        <font-awesome-icon icon="save" />&nbsp;Einträge speichern
                    </b-button>
                </b-button-group>
            </div>
        </b-navbar>
    </div>
</template>

<script>
import RpepdOvertimeInfo from "./RpepdOvertimeInfo.vue";
import RpepdNoTableEntry from "./RpepdNoTableEntry.vue";
import RpepdRecordTableEntry from "./RpepdRecordTableEntry.vue";
import RpepdOtherTableEntry from "./RpepdOtherTableEntry.vue";
import RpepdFastLane from "./RpepdFastLane.vue";
import { employeeService } from "../../_services";

export default {
    props: {
        date: String,
        employee_id: Number
    },

    data: function() {
        return {
            counter: 0,
            loading: false,
            recorddata: {},
            offset: 9000000,
            COMPTIME_PROJECTID: 79,
            BSTIME_PROJECTID: 89
        };
    },
    components: {
        RpepdRecordTableEntry,
        RpepdOtherTableEntry,
        RpepdNoTableEntry,
        RpepdOvertimeInfo,
        RpepdFastLane
    },
    created() {
        if (this.employee_id > 0) {
            this.fetchRecordData();
        }
    },
    computed: {
        RecordsWithCompleteTimes() {
            var ret = [];
            for (var i = 0; i < this.recorddata.records.length; i++) {
                if (
                    this.recorddata.records[i].starttime != null &&
                    this.recorddata.records[i].endtime != null
                ) {
                    ret.push(this.recorddata.records[i]);
                }
            }
            return ret;
        },
        hasOverlappingTimes() {
            var sorted = this.RecordsWithCompleteTimes;

            sorted.sort((a, b) =>
                window.moment.duration(a.endtime) > window.moment.duration(b.endtime) ? 1 : -1
            );
            sorted.sort((a, b) =>
                window.moment.duration(a.starttime) > window.moment.duration(b.starttime) ? 1 : -1
            );
            for (var i = 1; i < sorted.length; i++) {
                if (
                    window.moment.duration(sorted[i - 1].endtime) >
                    window.moment.duration(sorted[i].starttime)
                ) {
                    return true;
                }
            }
            return false;
        },
        hasRecords() {
            return (
                this.RecordsWithCompleteTimes !== undefined &&
                this.RecordsWithCompleteTimes.length > 0
            );
        },
        breaktimeDotColor() {
            if (this.notEnoughBreaktime) {
                return "text-danger";
            } else {
                return "text-success";
            }
        },
        numberOfEntries() {
            var ret =
                this.recorddata.records.length +
                this.recorddata.publicholidays.length +
                this.recorddata.sickdays.length +
                this.recorddata.vacationdays.length;
            return ret;
        },
        breaktimeSum() {
            var min = 24 * 60 * 60 * 1000; // = 24 h in milliseconds
            var max = 0;
            this.recorddata.records.forEach(element => {
                if (element.starttime != null && element.endtime != null) {
                    var std = window.moment.duration(element.starttime);
                    var etd = window.moment.duration(element.endtime);
                    if (min > std) {
                        min = std;
                    }
                    if (max < etd) {
                        max = etd;
                    }
                }
            });
            var diff = (max - min) / 60 / 60 / 1000; // time of presence
            return diff - this.rawRecordSum;
        },
        beforeBreaktimeCheck() {
            return this.date < "2020-02-01";
        },
        notEnoughBreaktime() {
            if (this.beforeBreaktimeCheck) {
                return false;
            }
            if (!this.hasRecords) {
                return false;
            }
            if (this.rawRecordSum > 6 && this.breaktimeSum < 0.5) {
                return true;
            } else if (this.rawRecordSum > 9 && this.breaktimeSum < 0.75) {
                return true;
            } else {
                return false;
            }
        },
        recordSum() {
            var sum = 0;
            this.recorddata.records.forEach(element => {
                sum += this.computeDuration(element.starttime, element.endtime);
            });
            this.recorddata.sickdays.forEach(element => {
                sum += parseFloat(element.recordvalue_computed);
            });
            this.recorddata.publicholidays.forEach(element => {
                sum += parseFloat(element.recordvalue_computed);
            });
            this.recorddata.vacationdays.forEach(element => {
                sum += parseFloat(element.recordvalue_computed);
            });
            return sum;
        },
        rawRecordSum() {
            // necessary if vd, phd or sd at the same day as normal records
            var sum = 0;
            this.recorddata.records.forEach(element => {
                sum += this.computeDuration(element.starttime, element.endtime);
            });
            return sum;
        },
        quota() {
            return this.recorddata.employee.dateinfo.quota;
        },
        employeeInactive() {
            return (
                this.recorddata.employee !== undefined &&
                this.recorddata.employee.dateinfo.statustitle == "inactive"
            );
        }
    },
    methods: {
        addFastLaneEntry(event) {
            this.counter += 1;
            this.recorddata.records.push({
                id: this.offset + this.counter,
                employee_id: this.employee_id,
                project_id: event.project.id,
                date: this.date,
                starttime: event.starttime,
                endtime: event.endtime,
                description: event.description
            });
        },
        newEntry: function() {
            this.counter += 1;
            var empty = {
                id: this.offset + this.counter,
                employee_id: this.employee_id,
                project_id: 0,
                date: this.date,
                starttime: null,
                endtime: null,
                description: ""
            };
            this.recorddata.records.push(empty);
        },
        newCompTime: function() {
            this.counter += 1;
            var comp = {
                id: this.offset + this.counter,
                employee_id: this.employee_id,
                project_id: this.COMPTIME_PROJECTID,
                date: this.date,
                starttime: "00:00:00",
                endtime: "00:00:00",
                description: ""
            };
            this.recorddata.records.push(comp);
        },
        newBsTime: function() {
            this.counter += 1;
            var bs1 = {
                id: this.offset + this.counter,
                employee_id: this.employee_id,
                project_id: this.BSTIME_PROJECTID,
                date: this.date,
                starttime: "08:00:00",
                endtime: "12:00:00",
                description: ""
            };
            this.recorddata.records.push(bs1);
            this.counter += 1;
            var bs2 = {
                id: this.offset + this.counter,
                employee_id: this.employee_id,
                project_id: this.BSTIME_PROJECTID,
                date: this.date,
                starttime: "13:00:00",
                endtime: "17:00:00",
                description: ""
            };
            this.recorddata.records.push(bs2);
        },
        deleteEntry: function(entryid) {
            let newentries = [];
            this.recorddata.records.forEach(element => {
                if (element.id != entryid) {
                    newentries.push(element);
                } else if (element.id < this.offset) {
                    this.recorddata.delete_records.push(element);
                }
            });
            this.recorddata.records = newentries;
        },
        fetchRecordData() {
            this.loading = true;
            employeeService
                .getRecordsPerEmployeePerDay(this.employee_id, this.date)
                .then(response => {
                    this.loading = false;
                    this.recorddata = response.data;
                    this.recorddata.delete_records = [];
                    if (window.moment().format("YYYY-MM-DD") == this.date) {
                        this.newEntry();
                    }
                });
        },
        updateRecordData() {
            if (this.hasOverlappingTimes && !this.beforeBreaktimeCheck) {
                this.$root.toastWithTitle(
                    "Es wurden überlappende Zeiten eingetragen",
                    "Mehrere Einträge überlapppen sich. Die aktuellen Einträge wurden noch nicht gespeichert.",
                    "danger"
                );

                return;
            }
            if (this.notEnoughBreaktime) {
                this.$root.toastWithTitle(
                    "Bitte achten Sie auf ausreichende Pausenzeiten",
                    "Bei mehr als 6 Arbeitsstunden muss eine Pause von mindestens 30 Minuten eingelegt werden; Bei mehr als 9 Arbeitsstunden ist eine Pause von mindestens 45 Minuten vorgeschrieben. Die aktuellen Einträge wurden noch nicht gespeichert.",
                    "danger"
                );
                return;
            }
            this.loading = true;
            employeeService
                .updateRecordsPerEmployeePerDay(this.employee_id, this.date, this.recorddata)
                .then(response => {
                    this.$root.toastWithTitle(
                        "Erfolgreich gespeichert",
                        "Alle Arbeitszeit-Einträge wurden erfolgreich gespeichert.",
                        "success"
                    );
                    this.loading = false;
                    this.recorddata = response.data;
                    this.recorddata.delete_records = [];
                    if (window.moment().format("YYYY-MM-DD") == this.date) {
                        this.newEntry();
                    }
                })
                .catch(err => {
                    this.$root.toastWithTitle(
                        "Fehler beim Speichern",
                        "Nicht alle Änderungen konnten übernommen werden. Bitte überprüfen Sie Ihre Eingaben.",
                        "danger"
                    );
                    this.loading = false;
                });
        },
        resetRecordData() {
            this.fetchRecordData();
        },

        computeDuration: function(starttime, endtime) {
            var etd = window.moment.duration(endtime);
            var std = window.moment.duration(starttime);
            if (etd >= std) {
                var duration = etd.subtract(std);
                return duration / 3600000;
            } else {
                return 0;
            }
        }
    },
    watch: {
        date: function(newVal, oldVal) {
            this.fetchRecordData();
            this.$router.push({
                name: "my.records.withdateandid",
                params: { date: newVal, id: this.employee_id }
            });
        },
        employee_id: function(newVal, oldVal) {
            this.fetchRecordData();
            this.$router.push({
                name: "my.records.withdateandid",
                params: { date: this.date, id: newVal }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.quota-progress {
    border-radius: 0;
    height: 0.2rem;
}
</style>
